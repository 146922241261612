import React, {useContext, useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import GlobalTextField from "../../../../GlobalesCredenciales/GlobalTextField";
import SubmitButton from "../Components/SubmitButton";
import RadiosEmpleados from "../Components/RadiosEmpleados";
import {handleChange, handleSubmit} from '../../../../../Controllers/FormulariosController'
import {isInputNumber} from "../../../../../helpers/Util";
import {useAlert} from "react-alert";
import globalContext from "../../../../../../../Context/GlobalContext/globalContext";
import {onBlurHandler} from "../../../../../Controllers/ModificarController";
import InputFile from "../../../../GlobalesCredenciales/InputFile";


const stateInicial = {
    emp_NumeroEmpleado: '',
    emp_Nombre: '',
    emp_ApellidoPaterno: '',
    emp_ApellidoMaterno: '',
    emp_Adscripcion: '',
    emp_Puesto: '',
    emp_Tipo: '2',
    emp_Correo: '',
    emp_Telefono: '',
    emp_CalleNumero: '',
    emp_Colonia: '',
    emp_PoblacionCodigoPostal: '',
    emp_Foto: null,
    emp_Firma: null,
};

const Empleados = () => {

    const alert = useAlert();
    const [mensaje, agregarMensaje] = useState('');
    const [errores, agregarErrores] = useState({});
    const [empleado, nuevoEmpleado] = useState(stateInicial);
    const [modificando, setModificando] = useState(false);
    const [poblacionCodigoPostal, setPoblacionCodigoPostal] = useState(
        {poblacion: '', codigoPostal: ''}
    )

    const context = useContext(globalContext)
    const {cliente} = context;
    const clienteID = cliente['cli_ID'];

    useEffect(() => {
        if (mensaje !== '') {
            alert.success(mensaje);
        }
    }, [mensaje]);

    return (
        <Grid container
              display='flex'
              justify='flex-start'
              component='div'
              direction='column'
        >
            <Grid item>
                <form
                    onSubmit={(e) =>
                        handleSubmit(e, empleado, nuevoEmpleado, clienteID, 'emp',
                            stateInicial, agregarErrores, agregarMensaje, modificando,setModificando, poblacionCodigoPostal, setPoblacionCodigoPostal)}>
                    <Grid
                        container
                        display='flex'
                        direction="row"
                        justify="center"
                        alignItems="flex-start"
                        spacing={2}
                    >

                        <Grid item md={3} sm={12}>
                            <GlobalTextField
                                id={'numID'}
                                label={'Número de empleado'}
                                icono={'id-card'}
                                name={'emp_NumeroEmpleado'}
                                value={empleado.emp_NumeroEmpleado}
                                error={!!errores.emp_NumeroEmpleado}
                                helperText={errores.emp_NumeroEmpleado}
                                disabled={modificando}
                                long={4}
                                onBlur={e =>
                                    onBlurHandler(e, 'emp', nuevoEmpleado, empleado.emp_NumeroEmpleado, stateInicial, setModificando, setPoblacionCodigoPostal , clienteID,alert)}
                                onChange={(e) => {
                                    isInputNumber(e);
                                    handleChange(e, empleado, nuevoEmpleado)
                                }}/>
                        </Grid>
                        <Grid item md={4} sm={12}>
                            <GlobalTextField
                                id={'adscripción'}
                                label={'Adscripción'}
                                icono={'suitcase'}
                                name={'emp_Adscripcion'}
                                value={empleado.emp_Adscripcion}
                                error={!!errores.emp_Adscripcion}
                                helperText={errores.emp_Adscripcion}
                                long={100}
                                onChange={(e) => handleChange(e, empleado, nuevoEmpleado)}/>
                        </Grid>
                        <Grid item md={5} sm={12}>
                            <GlobalTextField
                                id={'puesto'}
                                label={'Puesto'}
                                name={'emp_Puesto'}
                                value={empleado.emp_Puesto}
                                error={!!errores.emp_Puesto}
                                helperText={errores.emp_Puesto}
                                long={100}
                                onChange={(e) => handleChange(e, empleado, nuevoEmpleado)}/>
                        </Grid>
                        <Grid item xs={12}>
                            <RadiosEmpleados
                                empleado={empleado}
                                nuevoEmpleado={nuevoEmpleado}
                                events={{handleChange}}
                            />
                        </Grid>

                        {/*Parte del formulario donde se vuelve general */}

                        <Grid item md={6} sm={12}>
                            <GlobalTextField
                                id={'nombre'}
                                label={'Nombre'}
                                icono={'user'}
                                name={'emp_Nombre'}
                                value={empleado.emp_Nombre}
                                error={!!errores.emp_Nombre}
                                helperText={errores.emp_Nombre}
                                long={100}
                                onChange={(e) => handleChange(e, empleado, nuevoEmpleado)}
                            />
                        </Grid>
                        <Grid item md={3} sm={12}>
                            <GlobalTextField
                                id={'apellidoPaterno'}
                                label={'Apellido paterno'}
                                name={'emp_ApellidoPaterno'}
                                value={empleado.emp_ApellidoPaterno}
                                error={!!errores.emp_ApellidoPaterno}
                                helperText={errores.emp_ApellidoPaterno}
                                long={50}
                                onChange={(e) => handleChange(e, empleado, nuevoEmpleado)}/>
                        </Grid>
                        <Grid item md={3} sm={12}>
                            <GlobalTextField
                                id={'apellidoMaterno'}
                                label={'Apellido materno'}
                                name={'emp_ApellidoMaterno'}
                                value={empleado.emp_ApellidoMaterno}
                                error={!!errores.emp_ApellidoMaterno}
                                helperText={errores.emp_ApellidoMaterno}
                                long={50}
                                onChange={(e) => handleChange(e, empleado, nuevoEmpleado)}/>
                        </Grid>

                        <Grid item md={6} sm={12}>
                            <GlobalTextField
                                id={'correo'}
                                label={'Correo'}
                                icono={'envelope'}
                                tipo={'email'}
                                name={'emp_Correo'}
                                value={empleado.emp_Correo}
                                error={!!errores.emp_Correo}
                                helperText={errores.emp_Correo}
                                long={100}
                                onChange={(e) => handleChange(e, empleado, nuevoEmpleado)}/>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <GlobalTextField
                                id={'telefono'}
                                label={'Teléfono'}
                                icono={'phone'}
                                tipo={'tel'}
                                name={'emp_Telefono'}
                                value={empleado.emp_Telefono}
                                error={!!errores.emp_Telefono}
                                helperText={errores.emp_Telefono}
                                long={10}
                                onChange={(e) => {
                                    isInputNumber(e);
                                    handleChange(e, empleado, nuevoEmpleado)
                                }}/>
                        </Grid>


                        <Grid item md={5} sm={12}>
                            <GlobalTextField
                                id={'calle'}
                                label={'Calle y número'}
                                icono={'home'}
                                name={'emp_CalleNumero'}
                                value={empleado.emp_CalleNumero}
                                error={!!errores.emp_CalleNumero}
                                helperText={errores.emp_CalleNumero}
                                long={50}
                                onChange={(e) => handleChange(e, empleado, nuevoEmpleado)}/>
                        </Grid>
                        <Grid item md={3} sm={12}>
                            <GlobalTextField
                                id={'colonia'}
                                label={'Colonia'}
                                tipo={'text'}
                                name={'emp_Colonia'}
                                value={empleado.emp_Colonia}
                                error={!!errores.emp_Colonia}
                                helperText={errores.emp_Colonia}
                                long={50}
                                onChange={(e) => handleChange(e, empleado, nuevoEmpleado)}/>
                        </Grid>
                        <Grid item md={2} sm={12}>
                            <GlobalTextField
                                id={'poblacion'}
                                label={'Población'}
                                tipo={'text'}
                                name={'poblacion'}
                                error={!!errores.emp_PoblacionCodigoPostal}
                                helperText={errores.emp_PoblacionCodigoPostal}
                                value={poblacionCodigoPostal.poblacion}
                                long={50}
                                onChange={(e) => handleChange(e, poblacionCodigoPostal, setPoblacionCodigoPostal)}/>
                        </Grid>
                        <Grid item md={2} sm={12}>
                            <GlobalTextField
                                id={'postal'}
                                label={'Código postal'}
                                name={'codigoPostal'}
                                error={!!errores.emp_PoblacionCodigoPostal}
                                helperText={errores.emp_PoblacionCodigoPostal}
                                value={poblacionCodigoPostal.codigoPostal}
                                long={5}
                                onChange={(e) => {
                                    isInputNumber(e);
                                    handleChange(e, poblacionCodigoPostal, setPoblacionCodigoPostal)
                                }}/>
                        </Grid>

                        {/* Archivos */}
                        <Grid item md={6} sm={12}>
                            <InputFile
                                id={'foto'}
                                icono={'camera'}
                                name={'emp_Foto'}
                                objeto={empleado}
                                setObjeto={nuevoEmpleado}
                                accept={'image/jpeg'}
                                error={!!errores.est_Foto}
                                helperText={errores.est_Foto}
                            />
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <InputFile
                                id={'firma'}
                                icono={'feather'}
                                name={'emp_Firma'}
                                objeto={empleado}
                                setObjeto={nuevoEmpleado}
                                accept={'image/jpeg'}
                                error={!!errores.est_Foto}
                                helperText={errores.est_Foto}
                            />
                        </Grid>

                        <Grid item>
                            <SubmitButton/>
                        </Grid>


                    </Grid>
                </form>
            </Grid>
        </Grid>
    );
};

export default Empleados;