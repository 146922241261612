import React, { useState, useEffect } from "react";
import {ThemeProvider } from "@material-ui/styles";
import { FormControlLabel, TableHead, Button, TextField, TableContainer, Table, 
    Grid, TableBody, IconButton, Tooltip} from "@material-ui/core";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CheckIcon from '@material-ui/icons/Check';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import BlockIcon from '@material-ui/icons/Block';
import SaveIcon from '@material-ui/icons/Save';
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red"
import ListaResidentes from "../Residentes/ListaResidentes";
import StyledTableCell from "../../../Credenciales/Components/GlobalesCredenciales/StyledTableCell";
import StyledRow from "../../../Credenciales/Components/GlobalesCredenciales/StyledRow";
import ListaVisitantes from "../Residentes/ListaVisitantes";
import { modificarResidente } from "../../Controllers/PortalController";
import { handleChange } from "../../../Credenciales/Components/GlobalesCredenciales/GlobalTextField";
import "./InterfazUsuario.scss";
import {activarCasaParaRenta,getRentadores,verificarRentador, eliminarRentador} from "../../Controllers/ResidentesController";
import { theme } from "../Theme";
import {StyledLinearProgress, ConfirmDialog} from "../AccesoComponents";
import {RedSwitch} from '../AccesoComponents'


const InterfazUsuario = ({
    presidente,
    residente,
    setResidente,
    listaResidentes,
    setListaResidentes,
    listaVisitantes,
    setListaVisitantes,
    setMensaje,
    UserID,
}) => {
    
    const [rentadores, setRentadores] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [idRentador, setIdRentador] = useState(null);

    useEffect(() => { 
        if(presidente) return;
        getRentadores(UserID, setRentadores);
    }, []);

    const handleChangeSwitch = (e) => {
        setResidente({ ...residente, ["EnRenta"]: e.target.checked });
    };

    const handleClose = () => {
        setDialogOpen(false);
        setIdRentador(null);
    }

    const openDialog = (rentador) => {
        setDialogOpen(true);
        setIdRentador(rentador);
    }

    const mostrarTabla = () => {

        if(rentadores === null)
            return <StyledLinearProgress/>
        else if(rentadores.length === 0)
            return <h3>No hay datos de rentadores</h3>
        else{
            return (
                <TableContainer>
                    <Table stickyHeader aria-label={"Rentadores"}>
                        <TableHead>
                            <StyledRow>
                                <StyledTableCell>Nombre</StyledTableCell>
                                <StyledTableCell align="center">Apellidos</StyledTableCell>
                                <StyledTableCell align="center">Correo</StyledTableCell>
                                <StyledTableCell align="center">Teléfono</StyledTableCell>
                                <StyledTableCell align="center">Fecha de Registro</StyledTableCell>
                                <StyledTableCell align="center">Verificado</StyledTableCell>
                                <StyledTableCell align="center">Activo</StyledTableCell>                             
                            </StyledRow>
                        </TableHead>
                        <TableBody>
                            {rentadores.map((row, index) => (
                                 <Fila key={index} row={row} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )
        }

    }

    const Fila = ({row}) => {

        return (
            <StyledRow>
                <StyledTableCell align="center">{row['UsNombre']}</StyledTableCell>
                <StyledTableCell align="center">{`${row['UsApPat']} ${row['UsApMat']}`}</StyledTableCell>
                <StyledTableCell align="center">{row['UsCorreo']}</StyledTableCell>
                <StyledTableCell align="center">{row['UsTelefono']}</StyledTableCell>
                <StyledTableCell align="center">{row['UsFechaRegistro']}</StyledTableCell>
                <StyledTableCell align="center">
                {
                        row['UsVerficado'] !== 'Sin verificar' ?
                            <Tooltip title="Rentador verificado">
                                <VerifiedUserIcon style={{color: green[500]}}/>
                            </Tooltip>
                            :
                            <Tooltip title="Verificar rentador">
                                <IconButton
                                    style={{padding: 0, margin: 0}}
                                    onClick={() => verificarRentador(row['UsID'], setRentadores, setMensaje, UserID)}>
                                    <CheckIcon/>
                                </IconButton>
                            </Tooltip>
                    }
                </StyledTableCell>
                <StyledTableCell align="center">
                    {
                        row['UsEstatus'] === 'Activo' ?
                        <Tooltip title='Eliminar rentador permanentemente'>
                            <IconButton
                                style={{padding: 0, margin: 0}}
                                onClick={() => openDialog(row['UsID'])}
                            >
                                <DeleteForeverIcon style={{color: red[500]}}/>
                            </IconButton>
                        </Tooltip>
                        :
                        <Tooltip title={row['UsEstatus']}>
                            <BlockIcon/>
                        </Tooltip>
                    }
                </StyledTableCell>
            </StyledRow>
        )
    }

    return (
        <div>
            <ThemeProvider theme={theme}>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    spacing={2}
                    className="glass"
                >
                    <Grid item xs={12}>
                        <h3>Datos del residente</h3>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justify="flex-start" spacing={2}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    label={"Nombre"}
                                    name={"UsNombre"}
                                    value={residente["UsNombre"]}
                                    onChange={(e) =>
                                        handleChange(e, residente, setResidente)
                                    }
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    label={"Apellido Paterno"}
                                    name={"UsApPat"}
                                    value={residente["UsApPat"]}
                                    onChange={(e) =>
                                        handleChange(e, residente, setResidente)
                                    }
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    label={"Apellido Materno"}
                                    name={"UsApMat"}
                                    value={residente["UsApMat"]}
                                    onChange={(e) =>
                                        handleChange(e, residente, setResidente)
                                    }
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    disabled
                                    label={"Correo"}
                                    value={residente["UsCorreo"]}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label={"Fecha de nacimiento"}
                                    value={residente["UsFechaNac"]}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label={"Teléfono"}
                                    name={"UsTelefono"}
                                    value={residente["UsTelefono"]}
                                    onChange={(e) =>
                                        handleChange(e, residente, setResidente)
                                    }
                                    fullWidth
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    disabled
                                    label={"Número de casa"}
                                    value={residente["UsCasa"]}
                                />
                            </Grid>
                            {
                                !(presidente ||residente['EsRentador'] ) && (
                                    <Grid item>
                                        <FormControlLabel
                                            control={
                                                <RedSwitch
                                                    checked={residente["EnRenta"]}
                                                    onChange={(e) => {
                                                        handleChangeSwitch(e);
                                                        activarCasaParaRenta(e, UserID, setMensaje, setResidente, residente);
                                                    }}
                                                    name="EnRenta"
                                                />
                                            }
                                            label="Casa en renta"
                                        />
                                    </Grid>
                                )
                            }
                            
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <ListaResidentes
                            listaResidentes={listaResidentes}
                            setListaResidentes={setListaResidentes}
                            setMensaje={setMensaje}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ListaVisitantes
                            listaVisitantes={listaVisitantes}
                            setListaVisitantes={setListaVisitantes}
                            setMensaje={setMensaje}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            endIcon={<SaveIcon />}
                            onClick={() =>
                                modificarResidente(
                                    UserID,
                                    residente,
                                    setMensaje,
                                    listaVisitantes,
                                    listaResidentes
                                )
                            }
                            variant={"contained"}
                        >
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
                {residente["EnRenta"] && (
                    <Grid container className="glass">
                        <Grid item xs={12}>
                            <h3>Datos de rentadores</h3>
                        </Grid>
                        <Grid item xs={12}>
                            {mostrarTabla()}
                        </Grid>
                    </Grid>
                )}
                <ConfirmDialog
                    dialogTitle="¿Está seguro de eliminar este rentador?"
                    dialogContentText="Esta acción eliminará permanentemente al usuario rentador de
                    esta casa, dejándolo sin permisos y acceso a la privada"
                    handleClose={() => handleClose()}
                    dialogOpen={dialogOpen}
                    confirmAction={() => eliminarRentador(idRentador, setMensaje, setRentadores, UserID)}
                /> 
            </ThemeProvider>
        </div>
    );
};

export default InterfazUsuario;
