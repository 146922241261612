import { Grid } from '@material-ui/core'
import React, {useState, useEffect, useContext} from 'react'
import { useAlert } from 'react-alert'
import clienteAxios from '../../../../config/axios'
import globalContext from '../../../../Context/GlobalContext/globalContext'
import { getTicketsTecnico } from '../../Controllers/TecnicosController'
import {StyledLinearProgress} from '../AccesoComponents'
import './Tecnicos.scss'
import TicketsTecnicos from './TicketsTecnicos'

const Tecnicos = (props) => {

    const context = useContext(globalContext);
    const {session, usuario} = context;
    const [tickets, setTickets] = useState(null)
    const alert = useAlert();

    useEffect(() => {
        if(session) {
            alert.success(`Bienvenido ${usuario.UsNombre}`)
            return
        }
        
        props.history.push('/')
        
    }, [session])

    useEffect(() => {
        getTicketsTecnico(setTickets);
    }, [])

    return (

        <>
            <h1 className='titulo-tecnicos'>Tickets asignados</h1>
            {/* <Navbar nombreSistema='Técnicos' /> */}
            
                <Grid container direction='column' spacing={2} className='wrapper-tecnicos'>
                    <Grid item xs={12}>
                        
                    </Grid>
                    <Grid item  xs={12}>
                        <Grid container wrap='wrap' justify='flex-start'>
                            {
                                tickets === null ?
                                <StyledLinearProgress/>
                                :
                                tickets.map((item, index) => (
                                    <TicketsTecnicos key={index} info={item} />
                                ))
                            }
                        </Grid>
                    </Grid>
                </Grid>
        
        </>
    )
}

export default Tecnicos
