import React, {Fragment} from 'react';
import '../../../../assets/styles/SideBar.scss'
import ListaFunciones from "./ListaFunciones";
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import myTheme from "../../helpers/Theme";


const useStyles = makeStyles({
    buttonSb: {
        fontSize: '2.5rem',
        color: myTheme.palette.secondary.light,

    },
});

const SideBar = ({children}) => {

    const classes = useStyles();


    const abrir = () => {
        document.getElementById("mySidenav").classList.toggle("active");
        document.getElementById("boton").classList.toggle("botonEscondido");
    };

    const cerrar = () => {
        document.getElementById("mySidenav").classList.toggle("active");
        document.getElementById("boton").classList.toggle("botonEscondido");
    };

    return (
        <Fragment>
            <div id="mySidenav" className="sideNav active">
                <div className="d-flex justify-content-center">
                    {/*Boton Sidebar*/}
                    <IconButton id='botonHuella' onClick={cerrar}>
                        <FingerprintIcon className={classes.buttonSb}/>
                    </IconButton>
                </div>

                {/*--Funciones-->*/}
                <Grid container>
                    {/* <ListaFunciones/> */}
                    {children}
                </Grid>
            </div>


            {/*Boton Escondido*/}
            <IconButton id='boton' className={classes.buttonSb} onClick={abrir}>
                <FingerprintIcon style={{fontSize: '2.5rem'}}/>
            </IconButton>

        </Fragment>


    );
};

export default SideBar;