import React from "react";
import {
    Button,
    withStyles,
    LinearProgress,
    Fade,
    Switch,
    Grid,
    makeStyles,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Select, FormControl, InputLabel, Input, MenuItem,
} from "@material-ui/core";
import PropTypes from "prop-types";
import SortIcon from "@material-ui/icons/Sort";
import Theme from "./Theme";

export const RedSwitch = withStyles({
    switchBase: {
        color: "#CE6D6D",
        "&$checked": {
            color: "#C42E2E",
        },
        "&$checked + $track": {
            backgroundColor: "#C42E2E",
        },
    },
    checked: {},
    track: {},
})(Switch);

// -----------------------------------------------------------------------------------------------------------------

const PrivadasBoton = withStyles({
    root:{
        color: Theme.primary,
        padding: 5,
        margin: '0.4rem',
        borderColor: Theme.primary,
        width: 'auto',
    },
    outlinedPrimary: {
        borderColor: Theme.primary,
    }
})(Button)

export const ButtonPrivada = ({name, setFiltro, setOpen}) => {
    return (
        <PrivadasBoton onClick={() => {setFiltro(name); setOpen(false)}} variant={'outlined'}>
            {name}
        </PrivadasBoton>
    );
};


// -----------------------------------------------------------------------------------------------------------------

const StyledIconButton = withStyles({
    root: {
        color: Theme.primary,
    },
})(Button);
 
 export const FilterButton = ({onClick}) => {
     return (
         <StyledIconButton onClick={onClick} startIcon={<SortIcon/>}>
             Filtrar
         </StyledIconButton>
     );
 };

// -----------------------------------------------------------------------------------------------------------------

 const CustomLinearProgress = withStyles({
    root: {
        borderRadius: '5%',
    },
    colorPrimary: {
        backgroundColor: Theme.primary,

    },
    bar: {
        borderRadius: '5%',
        backgroundColor: Theme.secondary,
    }
})(LinearProgress);

export const StyledLinearProgress = () => <CustomLinearProgress/>

// -----------------------------------------------------------------------------------------------------------------

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade ref={ref} {...props} />;
});

export const DialogPrivadas = ({open, contenido, setFiltro, setOpen}) => {

    const buttonsIterator = () => {
        let botones = [];
        botones.push(
            <ButtonPrivada
                key={"9999"}
                name={"Sin filtro"}
                setFiltro={setFiltro}
                setOpen={setOpen}
            />
        );
        contenido.forEach((item, index) =>
            botones.push(
                <ButtonPrivada
                    key={index}
                    name={item.pri_Descripcion}
                    setFiltro={setFiltro}
                    setOpen={setOpen}
                />
            )
        );
        return botones;
    };

    return (
        <Dialog
            style={{marginLeft: '12rem'}}
            open={open}
            onClose={() => setOpen(false)}
            TransitionComponent={Transition}
        >
            <DialogTitle>
                Privadas
            </DialogTitle>
            <DialogContent>
                {buttonsIterator()}
            </DialogContent>

        </Dialog>
    );
};

DialogPrivadas.propTypes = {
    contenido: PropTypes.array.isRequired,
    setFiltro: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
}


// -----------------------------------------------------------------------------------------------------------------

const useStylesContenedor = makeStyles({
    root: {
        padding: '0 2rem',
        minHeigth: '60vh'
    }
});

export const Contenedor = ({children}) => {
    const classes = useStylesContenedor();

    return (
        <Grid
            container 
            justify='center'
            className={classes.root}
        >
            {children}
        </Grid>
    )
}

// -----------------------------------------------------------------------------------------------------------------




export const ConfirmDialog = ({
    dialogOpen,
    handleClose,
    dialogTitle,
    dialogContentText,
    confirmAction,
}) => {
    return (
        <Dialog
            open={dialogOpen}
            onClose={handleClose}
            aria-labelledby="confirmar-dialog"
        >
            <DialogTitle id="confirmar-dialog">
                {dialogTitle}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {dialogContentText}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                    Cancelar
                </Button>
                <Button onClick={confirmAction}>
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog>
    );
};


ConfirmDialog.propTypes = {
    dialogTitle: PropTypes.string,
    dialogContentText: PropTypes.string,
    dialogOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    confirmAction: PropTypes.func.isRequired,
}

// -----------------------------------------------------------------------------------------------------------------

const dialogSelectStyles = makeStyles((theme) => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 250,
    },
  }));

export const DialogSelect = ({
    dialogOpen,
    handleClose,
    dialogTitle,
    dialogContentText,
    confirmAction,
    selectContent,
    setSelected,
    selected,
    inputLabel
}) => {

    const classes = dialogSelectStyles();

    function handleChange (e) {
        setSelected(e.target.value || '')
    }

    return (
        <Dialog
            open={dialogOpen}
            onClose={handleClose}
            aria-labelledby="confirmar-dialog"
        >
            <DialogTitle id="confirmar-dialog">{dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{dialogContentText}</DialogContentText>
                <form className={classes.container}>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="demo-dialog-select-label">
                            {inputLabel}
                        </InputLabel>
                        <Select
                            labelId="dialog-select-label"
                            id="dialog-select"
                            value={selected}
                            onChange={(e) => handleChange(e)}
                            input={<Input />}
                        >
                            {
                                selectContent !== null && 
                                    selectContent.map((item) => <MenuItem value={item['Us_ID']} key={item['Us_ID']}>{`${item['UsNombre']} ${item['UsApPat']}`}</MenuItem>)
                                
                            }
                        </Select>
                    </FormControl>
                </form>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                    Cancelar
                </Button>
                <Button onClick={confirmAction}>Confirmar</Button>
            </DialogActions>
        </Dialog>
    );
};