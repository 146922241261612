import React from 'react';
import logo from "../../../../assets/images/sensor-white.png";

const divStyle = {
  height: "100%"
};

const imgStyle = {
    height: "10vh",
    padding: "1vh"};


const LogoSensor = () => {
    return (
        <div style={divStyle}>
            <img src={logo} style={imgStyle} alt="Logo"/>
        </div>
    );
};

export default LogoSensor;