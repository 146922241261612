import React from 'react';
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import myTheme from "../../helpers/Theme";

const useStyles = makeStyles({
    boton: {
        height: '5rem',
        width: '100%',
        color: myTheme.palette.secondary.main,
        fontSize: '0.6rem',
        padding: 0,
    },
    icon: {
        color: myTheme.palette.secondary.main,
        fontSize: '1.5rem',
    },
    text: {
        marginTop: '0.3rem'
    }

});

const BotonRegresar = ({icono,nombre,handleClick}) => {

    const classes = useStyles();

    return (
        <Button className={classes.boton} aria-controls="simple-menu" aria-haspopup="true"
                onClick={handleClick}>
            <Grid container alignItems='center' direction='column'>
                <Icon className={`fa fa-${icono}`}/>
                <span className={classes.text}>{nombre}</span>
            </Grid>
        </Button>
    );
};

export default BotonRegresar;