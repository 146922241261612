import React from "react";
import UsuariosForm from "./UsuariosForm";

const UsuariosCreate = () =>{
    
    return(
      <div className={'container-flex'}>
          <div className={'row'}>
              <div className={'offset-1 col-3'}>
                  <h1 style={{fontFamily : 'impact',fontSize : '3.0rem', color : 'grey'}}>DAR DE ALTA</h1>
              </div>
          </div>
          <div className='row' style={{fontFamily : 'impact'}}>
              <UsuariosForm/>
          </div>
      </div>
    );
}

export default UsuariosCreate;