import React, {useEffect} from 'react';
import clienteAxios from "../../../../../config/axios";
import {CircularProgress} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import ListosTabla from "./ListosTabla";

const Listos = ({listos, setListos}) => {

    useEffect(() => {
        const fetchData = async () => {
            const resultado = await clienteAxios.get('api/credenciales/loteslistos');
            setListos(resultado.data);
        }
        fetchData().then()
    }, []);

    const mostrarTabla = () => {
        if(listos === null){
            return <CircularProgress color={"secondary"}/>
        }
        else if(listos.length === 0){
            return <h3>No hay datos disponibles para mostrar</h3>
        }
        else{
            return <ListosTabla listos={listos} setListos={setListos}/>
        }
    }

    return (
        <Grid container  justify={'center'} style={{minHeight: '60vh'}}>
            {
                mostrarTabla()
            }
        </Grid>
    );
};



export default Listos;