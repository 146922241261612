import React ,{useState} from "react";
import SideNav, {Nav, NavItem, NavText, NavIcon} from '@trendmicro/react-sidenav';
import sensor from '../../../assets/images/sensor-white.png';
import huella from '../../../assets/images/huella.png';

import SideBarItem from "./SideBarItem";

const SideBar = ({regresarPanel}) =>{
    const [expandido, expanderNav] = useState(false);
    const toggle = () =>
    {
        expanderNav(!expandido);
    };
    const handleToggle = () =>{};
    const regresar = () =>{
        regresarPanel();
    };
    return(
        <SideNav
            expanded={expandido}
            onToggle={handleToggle}
            style={{fontFamily : 'impact',position : 'fixed',boxShadow : ''}}
        >
            <Nav defaultSelected="home" style={{paddingTop : '2vh'}}>
                <NavItem navitemStyle={{height : '11vh',marginBottom : '2vh'}}>
                    <img
                        className={expandido?'img-fluid ml-3':'img-fluid'}
                        src={expandido?sensor:huella}
                        style={{ height : "10vh", marginTop : '1vh'}}
                        type='button'
                        onClick={toggle}
                     alt={'logo'}/>
                </NavItem>
                {expandido?
                <NavItem navitemStyle={{fontSize : '1.4rem'}}>
                    <NavText style={{marginLeft : '10%'}}>Usuarios</NavText>
                </NavItem>
                :''}
            <SideBarItem
                nombre='VerRelays'
                icono='fas fa-users'
                url={'/usuarios'}
            />
            <SideBarItem
                nombre='Dar de alta'
                icono='far fa-address-card'
                url={'/usuarios/alta'}
            />
            <SideBarItem
                nombre='Otorgar permisos'
                icono='fas fa-user-shield'
                url={'/usuarios/permisos'}
            />
            <NavItem onClick={regresar} eventKey={'Regresar'} style={{marginTop:'30vh'}}>
                <NavIcon>
                    <i className={'fas fa-sign-out-alt'} style={{fontSize: '1.75em'}} />
                </NavIcon>
                    <NavText style={{ fontSize : '1.2rem'}}>
                        Regresar
                    </NavText>
            </NavItem>
            </Nav>
        </SideNav>

    );
};

export default SideBar;