import React from 'react';
import StyledTableCell from "../../GlobalesCredenciales/StyledTableCell";
import Icon from "@material-ui/core/Icon";
import myTheme from "../../../helpers/Theme";
import StyledRow from "../../GlobalesCredenciales/StyledRow";
import DialogCodigos from "./DialogCodigos";

const FilaCodigos = ({row, states}) => {

    return (
        <StyledRow key={row.ID}>
            <StyledTableCell scope="row">{row.ID}</StyledTableCell>
            <StyledTableCell align="center">{row['Solicitud']}</StyledTableCell>
            <StyledTableCell align="center">{row['Cantidad']}</StyledTableCell>
            <StyledTableCell align="center">{row['Fecha']}</StyledTableCell>
            <StyledTableCell align='center'>
                {row['Especial'] === 1 ?
                    <Icon className='fa fa-star' style={{color: myTheme.palette.secondary.dark}}/>
                    :
                    null
                }
            </StyledTableCell>
            <StyledTableCell align="center" style={{padding: 0, margin: 0}}>
                <DialogCodigos row={row} states={states}/>
            </StyledTableCell>
        </StyledRow>
    );
};

export default FilaCodigos;