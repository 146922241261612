import React from 'react';
import StyledTabs from '../../../../GlobalesCredenciales/StyledTabs'
import Estudiantes from "../Tipos/Estudiantes";
import Docentes from "../Tipos/Docentes";
import Empleados from "../Tipos/Empleados";
import StyledTab from "../../../../GlobalesCredenciales/StyledTab";
import TabPanel from "../../../../GlobalesCredenciales/TabPanel";

const useStyles = () => ({
    root: {
        flexGrow: 1,
    },
});

const RegistrarUADEO = () => {

    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <div>
                <StyledTabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    <StyledTab label={'Estudiantes'} />
                    <StyledTab label={'Docentes'} />
                    <StyledTab label={'Empleados'}/>
                </StyledTabs>
            </div>

            <TabPanel value={value} index={0}>
                <Estudiantes/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Docentes/>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Empleados/>
            </TabPanel>
        </div>
    );
}

export default RegistrarUADEO
