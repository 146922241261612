import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Accordion from "@material-ui/core/Accordion";
import {ExpandMore} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

const Acordeon = ({titulo, info}) => {

    const classes = useStyles();


    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMore/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography className={classes.heading}>{titulo}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                    <div>
                        {
                            info.length !== 0?
                                info.map((item, index) => {return <p key={index} style={{color:'red'}}>{item}</p>})
                                :
                                <p>No hay nada para mostrar</p>
                        }
                    </div>
            </AccordionDetails>
        </Accordion>
    );
};

export default Acordeon;