import React from 'react';
import {Select} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import HelpIcon from "@material-ui/icons/Help";
import IconButton from "@material-ui/core/IconButton";
import {handleChange} from "../../GlobalesCredenciales/GlobalTextField";
import InputLabel from "@material-ui/core/InputLabel";
import CustomTooltip from "../../GlobalesCredenciales/CustomTooltip";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
    formControl: {
        minWidth: '100%'
    }
})

const ComboCriterio = ({solicitud, nuevaSolicitud, criterios}) => {

    const classes = useStyles();

    const tooltipText = 'Los criterios son una herramienta para dar un mayor orden\n' +
        'a los lotes y así generar entregas más ordenadas';


    return (
        <Grid container direction={'row'} alignItems={'center'}>
            <Grid item xs>
                <FormControl className={classes.formControl}>
                    <InputLabel id={'crit'}>Generación por criterio</InputLabel>
                    <Select
                        labelId="criterios"
                        id="criterio"
                        name={'lot_Criterio'}
                        value={solicitud['lot_Criterio']}
                        onChange={e => handleChange(e, solicitud, nuevaSolicitud)}
                    >
                        {
                            criterios.map(crit =>
                                <MenuItem value={crit['cri_ID']} key={crit['cri_ID']}>
                                    {crit['cri_Descripcion']}
                                </MenuItem>
                            )
                        }

                    </Select>
                </FormControl>
            </Grid>
            <Grid item>
                <CustomTooltip title={tooltipText}>
                    <IconButton style={{paddingLeft: '10px'}}>
                        <HelpIcon/>
                    </IconButton>
                </CustomTooltip>
            </Grid>
        </Grid>
    );
};

export default ComboCriterio;