import React, {useEffect, useState} from 'react';
import {subirFotosPendientes} from "../../Controllers/PendientesController";
import Grid from "@material-ui/core/Grid";
import InputFile from "../GlobalesCredenciales/InputFile";
import BotonRojo from "../GlobalesCredenciales/BotonRojo";
import {useAlert} from "react-alert";

const stateInicial = {
    FyF: null
}

const Formulario = () => {

    const alert = useAlert();
    const [archivo, setArchivo] = useState(stateInicial);
    const [mensaje, setMensaje] = useState('');

    useEffect(()=> {
        if(mensaje === '') return;
        if(mensaje.error)
            alert.error(mensaje.msg);
        else
            alert.success(mensaje.msg);
    }, [mensaje]);

    return (
        <Grid item>
            <form onSubmit={e => subirFotosPendientes(e, archivo, setMensaje, stateInicial, setArchivo)}>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    spacing={2}
                >
                    <Grid item xs={12} sm={6}>
                        <InputFile
                            id={'FyF'}
                            name={'FyF'}
                            icono={'file-archive'}
                            accept={'application/x-zip-compressed'}
                            objeto={archivo}
                            setObjeto={setArchivo}
                        />
                    </Grid>

                    <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                        <BotonRojo text={'Registrar'}/>
                    </Grid>
                </Grid>
            </form>
        </Grid>
    );
};

export default Formulario;