import React from 'react';
import StyledTabs from "../../GlobalesCredenciales/StyledTabs";
import StyledTab from "../../GlobalesCredenciales/StyledTab";
import TabPanel from "../../GlobalesCredenciales/TabPanel";
import Locales from "./Local/Locales";
import Externas from "./Externas/Externas";


const useStyles = () => ({
    root: {
        flexGrow: 1,
    },
});

const TabPendientes = () => {

    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <div>
                <StyledTabs value={value} onChange={handleChange} aria-label="Tabs para entregas">
                    <StyledTab label={'Locales'}/>
                    <StyledTab label={'Externas'}/>
                </StyledTabs>
            </div>

            <TabPanel value={value} index={0}>
                <Locales/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Externas/>
            </TabPanel>
        </div>
    );
};

export default TabPendientes;