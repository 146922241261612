import React from 'react';
import Grid from "@material-ui/core/Grid";
import CodigosTabla from "./CodigosTabla";

const CodigoInterno = ({states}) => {

    const {codigos} = states;

    const mostrarTabla = () => {
        if(codigos.length === 0)
            return <h3>No hay lotes para poner código interno</h3>
        else
            return <CodigosTabla states={states} />
    }

    return (
        <Grid container justify={'center'} style={{minHeight: 'auto'}}>
            {mostrarTabla()}
        </Grid>
    );
};

export default CodigoInterno;