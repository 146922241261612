import React, { useContext, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { obtenerDatos } from "../../Controllers/PortalController";
import { useAlert } from "react-alert";
import globalContext from "../../../../Context/GlobalContext/globalContext";
import styled from "styled-components";
import {StyledLinearProgress} from "../AccesoComponents";
import InterfazUsuario from "./InterfazUsuario";
import Logo from "../../../../assets/images/sensor-white.png";
import Presidente from "../Presidente/Presidente";
import Circulos from "../../../Circulos/Circulos";
import '../Residentes/RegistrarResidentes.scss'

const Alerta = styled.span`
    font-weight: lighter;
    font-size: 18px;
    color: #C42E2E;
    font-weigth: 600;
    text-align: center;
    letter-spacing: 1px;
    margin: 1rem;
`;

const LogoSensor = {
    filter: "invert(100%)",
    webkitFilter: "invert(100%)",
    height: "3rem",
    top: "0.5rem",
    left: "0.5rem",
    zIndex: "100",
    position: "fixed",
    transition: "top 0.3s",
};

const initialState = {
    UsNombre: "",
    UsApPat: "",
    UsApMat: "",
    UsCorreo: "",
    UsFechaNac: "",
    UsCasa: "",
    UsTelefono: "",
};

const PortalUsuario = (props) => {
    const alert = useAlert();
    const globalContex = useContext(globalContext);
    const { session, usuario } = globalContex;
    const [privada, setPrivada] = useState(null);
    const [idPrivada, setIdPrivada] = useState("");
    const [loading, isLoading] = useState(true);
    const [mensaje, setMensaje] = useState("");
    const [alerta, setAlerta] = useState(undefined);
    const [residente, setResidente] = useState(initialState);
    const [listaResidentes, setListaResidentes] = useState([]);
    const [listaVisitantes, setListaVisitantes] = useState([]);

    //Funcion para esconder el logo cuando se hace scroll
    let prevScrollpos = window.pageYOffset;
    window.onscroll = function () {
        let currentScrollPos = window.pageYOffset;
        if (prevScrollpos > currentScrollPos) {
            document.getElementById("sensor-logo").style.top = "0";
        } else {
            document.getElementById("sensor-logo").style.top = "-50px";
        }
        prevScrollpos = currentScrollPos;
    };

    const states = {
        setPrivada,
        isLoading,
        setMensaje,
        setIdPrivada,
        setResidente,
        setListaResidentes,
        setListaVisitantes,
        setAlerta,
    };

    const statesPresidente = {
        idPrivada,
        residente,
        setResidente,
        listaResidentes,
        setListaResidentes,
        listaVisitantes,
        setListaVisitantes,
        setMensaje,
    };

    useEffect(() => {
        if (session) {
            alert.success("Bienvenido " + usuario.UsNombre + "!");
            return;
        }
        props.history.push("/");
    }, [session]);

    useEffect(() => {
        if (mensaje === "") return;
        if (!mensaje.error) alert.success(mensaje.msg);
        else alert.error(mensaje.msg);
    }, [mensaje]);

    useEffect(() => {
        try {
            obtenerDatos(usuario["Us_ID"], states).then();
        } catch (error) {
            props.history.push("/");
        }
    }, []);

    const renderComponents = () => {
        if (loading) {
            return null;
        } else if (alerta !== "") {
            return <Alerta>{alerta}</Alerta>;
        } else if (residente["EsPresidente"]) {
            return (
                <Presidente states={statesPresidente} UserID={usuario.Us_ID} />
            );
        } else if (alerta !== undefined) {
            return (
                <InterfazUsuario
                    residente={residente}
                    setResidente={setResidente}
                    listaResidentes={listaResidentes}
                    setListaResidentes={setListaResidentes}
                    listaVisitantes={listaVisitantes}
                    setListaVisitantes={setListaVisitantes}
                    setMensaje={setMensaje}
                    UserID={usuario.Us_ID}
                />
            );
        }
    };

    return (
        <div>
            {loading ? <StyledLinearProgress color={"primary"} /> : null}
            <img
                id={"sensor-logo"}
                src={Logo}
                style={LogoSensor}
                alt={"logo-sensor"}
            />
            <Grid container justify={"center"} alignItems={"center"}>
                <Grid item xs={12}>
                    {loading ? null : (
                        <h2 className="nombre-privada">
                            {privada}
                        </h2>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <Grid
                        container
                        justify={"center"}
                        alignItems={"stretch"}
                        style={{ height: "100%" }}
                    >
                        {renderComponents()}
                    </Grid>
                </Grid>
            </Grid>

            <Circulos/>
        </div>
    );
};

export default PortalUsuario;
