import React, {Fragment, useContext, useState} from 'react';
import Box from "@material-ui/core/Box";
import myTheme from "../../helpers/Theme";
import CustomMenu from "./CustomMenu";
import CustomMenuItem from "./CustomMenuItem";
import {Link} from "react-router-dom";
import Boton from "./Boton";
import globalContext from "../../../../Context/GlobalContext/globalContext";

const FuncionClientes = ({icono, nombre, url}) => {


    const [anchor, setAnchor] = useState(null);
    const context = useContext(globalContext);
    const {clientes, seleccionarCliente} = context;
    const handleClick = event => setAnchor(event.currentTarget);
    const handleClose = () => setAnchor(null);

    const item = (cliente) => {
        const id = cliente['cli_ID'];
        const nombre = cliente['cli_Nombre']
        return (
            <CustomMenuItem key={id}>
                <Link
                    to={url + nombre}
                    style={{color: myTheme.palette.secondary.main, textDecoration: 'none'}}
                    onClick={() => {
                        handleClose();
                        seleccionarCliente(cliente)
                    }}
                >
                    {nombre.toUpperCase()}
                </Link>
            </CustomMenuItem>
        )
    }

    const ListaClientes = ({clientes}) => {
        if (clientes === undefined || clientes === null)
            return <div> </div>;
        return (
            <Fragment>
                {clientes.map((cliente) => item(cliente))}
            </Fragment>
        )
    }
    
    return (
        <li>
            <Box component='div'>
                <Boton
                    icono={icono}
                    nombre={nombre}
                    handleClick={handleClick}
                />
                <Fragment>
                    <CustomMenu
                        id='menuFuncion'
                        anchorEl={anchor}
                        keepMounted
                        open={Boolean(anchor)}
                        onClose={handleClose}
                    >
                        <ListaClientes clientes={clientes}/>

                        <CustomMenuItem key={'cerrar'}>
                            <Link
                                to={url + 'cerrar'}
                                style={{color: myTheme.palette.secondary.main, textDecoration: 'none'}}
                                onClick={handleClose}
                            >
                                {'cerrar'.toUpperCase()}
                            </Link>
                        </CustomMenuItem>
                    </CustomMenu>
                </Fragment>
            </Box>
        </li>
    );
};

export default FuncionClientes;