import React, {useReducer} from "react";

import globalContext from "./globalContext";
import globalReducer from "./globalReducer";
import {
    INICIAR_SESION,
    INICIO_FALLIDO,
    OBTENER_USUARIO,
    PERMISO_DENEGADO,
    PERMISO_OTORGADO,
    REGRESAR_PANEL,
    CERRAR_SESION,
    OBTENER_CLIENTES,
    SELECCIONAR_CLIENTE,
    AREA_TRABAJO, LOTES,
} from "../Types";
import clienteAxios from "../../config/axios";
import tokenAuth from "../../config/token";

const GlobalState = props =>
{
    const initialState =
    {
        token   : localStorage.getItem('token'),
        session : false,
        permiso : false,
        sistema : '',
        mensaje : null,
        usuario : null,
        clientes: null,
        cliente : null,
        area    : true,
        lotes   : null,
        imprimir: 0,
        laminar : 0,
    };

    const [state,dispatch] = useReducer(globalReducer,initialState);

    const iniciarSesion = async user =>
    {
        dispatch({type : 'default'});
        //Validar campos vacíos
        if(user.email==='' && user.password ==='')
            return dispatch({
                type: INICIO_FALLIDO,
                payload: 'Ambos campos son requeridos.'
            });
      
            //Inicio exitoso, se guarda el token y session cambia a true
            delete clienteAxios.defaults.headers.common["Authorization"];
            clienteAxios.post('api/login', user)
            .then((response) => {
                dispatch({
                    type: INICIAR_SESION,
                    payload: response.data
                });
                //Visita a la api para obtener Datos del usuario
                getUsuario();
            })
             //Inicio Fallido, cambia el mensaje de error
            .catch(error => {
                    dispatch({
                        type: INICIO_FALLIDO,
                        payload: error.response.data.error
                    });
                }
                );
    };
    const getUsuario = async () =>
    {
        const token = localStorage.getItem('token');
        //Se asigna el token por default
        if(token)
            tokenAuth(token);
        //Visita a la api
         await clienteAxios.get('api/user').
         then(response =>{
            dispatch({
                type : OBTENER_USUARIO,
                payload: response.data
            });
        }).catch(error =>{});

    };


    const checarPermiso = async (sistema,usuario) =>
    {
        clienteAxios.get(`api/sistemas/${sistema}/${usuario}`)
        .then(() =>{
            dispatch({
                type : PERMISO_OTORGADO,
                payload : sistema
            })
        }).catch( () =>{
            dispatch({
                type : PERMISO_DENEGADO,
            })
        });
    };

    const regresarPanel = () =>
    {
        dispatch({
            type : REGRESAR_PANEL
        });
    };
    const cerrarSesion = async (redireccionar) =>
    {
        clienteAxios.get('api/logout').
        then(()=>
                dispatch({
                type : CERRAR_SESION
            }))

        redireccionar();
    };

    const obtenerClientes = async () => {
        clienteAxios.get('api/credenciales/clientes')
            .then(response => {
               dispatch({
                   type: OBTENER_CLIENTES,
                   payload: response.data
               })
            })
            .catch()
    }

    const seleccionarCliente = (cliente) => {
        dispatch({
            type: SELECCIONAR_CLIENTE,
            payload: cliente
        })
    }

    const activarAreaTrabajo = flag => {
        dispatch({
            type: AREA_TRABAJO,
            payload: flag
        })
    }

    const setLotes = lotes => {
        dispatch({
            type: LOTES,
            payload: lotes
        })
    }



    return(
        <globalContext.Provider
            value={{
                usuario : state.usuario,
                session : state.session,
                permiso : state.permiso,
                sistema : state.sistema,
                mensaje : state.mensaje,
                clientes: state.clientes,
                cliente : state.cliente,
                area    : state.area,
                lotes   : state.lotes,
                activarAreaTrabajo,
                iniciarSesion,
                checarPermiso,
                regresarPanel,
                cerrarSesion,
                obtenerClientes,
                seleccionarCliente,
                setLotes,
            }}
        >
            {props.children}
        </globalContext.Provider>
    );
};

export default GlobalState;