import React from 'react';
import Grid from "@material-ui/core/Grid";
import RedButton from "../../GlobalesCredenciales/RedButton";
import {useHistory} from 'react-router-dom';
import ListosTabla from "./ListosTabla";

const Listos = ({states}) => {

    const {listos, salir} = states;
    const history = useHistory();

    const Redireccionar = () => history.push('/credenciales/lotes');

    const mostrarTabla = () => {
        if(listos.length === 0)
            return <h3>No hay lotes listos</h3>
        else
            return <ListosTabla states={states} />
    }

    return (
        <Grid container justify={'center'} style={{minHeight: 'auto'}}>
            <Grid item>
                {mostrarTabla()}
            </Grid>
            <Grid item xs={12}>
                <RedButton text={'Salir'} icono={'door-open'} onClick={()=> Redireccionar()} disabled={salir}/>
            </Grid>

        </Grid>
    );
};

export default Listos;