import React, { useState, useEffect } from 'react'
import './Login.scss'
import LoginTextfield from './LoginTextfield'
import EmailIcon from "../../assets/images/iconmonstr-email-3.svg";
import Logo from "../../assets/images/sensor_rojo.png";
import {handleChange} from "./Login"
import LoginButton from './LoginButton';
import Circulos from '../Circulos/Circulos';
import { useAlert } from 'react-alert';
import {enviarCorreoRecuperar} from '../../Controllers/LoginFormController'

const Recuperar = () => {

    const alert = useAlert();

    const [correo, setCorreo] = useState("");
    const [mensaje, setMensaje] = useState("");

    useEffect(() => {
        if(mensaje === "") return;
        
        if(!mensaje.error){
            alert.info(mensaje.msg)
        }else{
            alert.error(mensaje.msg);
        }
            
    
        return () => {
            setMensaje("")
        }
    }, [mensaje])

    return (
        <div id='login-wrapper'>
            <img className="logo-sensor slide-fwd-center" src={Logo}/>
            <p className="texto-leyenda">
                Para recuperar su contraseña ingrese su correo electrónico, donde posteriormente
                se enviará un link para cambiar a su nueva contraseña.
            </p>
            <form onSubmit={(e)=> enviarCorreoRecuperar(e, correo, setMensaje)} className="login-form">
                <LoginTextfield
                    type="email"
                    placeholder="Correo"
                    name="Correo"
                    id="Correo"
                    label="Correo"
                    icon={EmailIcon}
                    onChange={(e) => handleChange(e, setCorreo)}
                />
                <LoginButton label="Enviar correo" />
            </form>

            <Circulos/>
        </div>
    )
}

export default Recuperar
