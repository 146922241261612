import React, {useState} from 'react';
import {Checkbox} from "@material-ui/core";
import myTheme from "../../helpers/Theme";
import withStyles from "@material-ui/core/styles/withStyles";

const StyledCheckbox = withStyles({
    root: {
        color: myTheme.palette.primary.main,
        '&$checked': {
            color: myTheme.palette.primary.main,
        },
    },
    checked: {},
})((props) => <Checkbox color='default' {...props} />);


export default StyledCheckbox;