import React, {Fragment, useState} from 'react';
import Box from "@material-ui/core/Box";
import myTheme from "../../helpers/Theme";
import CustomMenu from "./CustomMenu";
import CustomMenuItem from "./CustomMenuItem";
import {Link} from "react-router-dom";
import Boton from "./Boton";


const Funcion = ({icono, nombre, fragmentos, url}) => {

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = event => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null)



    const lista = fragmentos.map((fragmento) => (
            <CustomMenuItem key={fragmento} props={fragmento}>
                <Link
                    to={url + fragmento}
                    style={{color: myTheme.palette.secondary.main, textDecoration: 'none'}}
                    onClick={handleClose}
                >
                    {fragmento.toUpperCase()}
                </Link>
            </CustomMenuItem>
        )
    );

    return (
        <li>
            <Box component='div'>
                {fragmentos.length === 0 ?
                    <Link to={url} style={{color: myTheme.palette.secondary.main, textDecoration: 'none'}}>
                        <Boton
                            icono={icono}
                            nombre={nombre}
                        />
                    </Link>
                    :
                    <Fragment>
                        <Boton
                            icono={icono}
                            nombre={nombre}
                            handleClick={handleClick}
                        />
                        <CustomMenu
                            id='menuFuncion'
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            { lista }
                        </CustomMenu>
                    </Fragment>
                }
            </Box>
        </li>
    );
};

export default Funcion;