import React from 'react';
import {TableContainer, TableRow} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import StyledTableCell from "../../GlobalesCredenciales/StyledTableCell";
import StyledRow from "../../GlobalesCredenciales/StyledRow";


const Tabla = ({solicitudes}) => {



    return (
        <Paper style={{width: '100%'}}>
            <TableContainer style={{maxHeight: '70vh'}}>
                <Table stickyHeader aria-label="Historial de solicitudes">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Folio</StyledTableCell>
                            <StyledTableCell align="center">Cliente</StyledTableCell>
                            <StyledTableCell align="center">Fecha</StyledTableCell>
                            <StyledTableCell align="center">Cantidad</StyledTableCell>
                            <StyledTableCell align="center">Comentarios</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            solicitudes.map((row) => (
                                <StyledRow key={row['Folio']}>
                                    <StyledTableCell scope="row">{row.Folio}</StyledTableCell>
                                    <StyledTableCell align="center">{row.Cliente}</StyledTableCell>
                                    <StyledTableCell align="center">{row.Fecha}</StyledTableCell>
                                    <StyledTableCell align="center">{row.Cantidad}</StyledTableCell>
                                    <StyledTableCell align="center">{row.Comentarios}</StyledTableCell>
                                </StyledRow>
                            ))
                        }
                    </TableBody>
                </Table>

            </TableContainer>
        </Paper>

    );
};

export default Tabla;