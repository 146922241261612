import React from 'react'
import Pendiente from '../../../../assets/svg/pendiente.svg'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import DoneIcon from '@material-ui/icons/Done';
import {Divider, Tooltip, Button, IconButton, Paper} from '@material-ui/core'
import { green } from '@material-ui/core/colors';
import Grid from "@material-ui/core/Grid";

const TicketSingular = ({info, setDialogOpen, setOpenDialogTecnico, setFolio}) => {

    const {Completado, Fecha, Orden, Folio, Asignado, Cliente, Problema} = info;

    return (
        <Paper elevation={4} className="ticket-paper">
            <TicketHeader completado={Completado} fecha={Fecha} />
            <Divider />
            <Grid container>
                <TicketInfo orden={Orden} folio={Folio} cliente={Cliente} />
                <TicketProblema problema={Problema} />
                <TicketFooter
                    Folio={Folio}
                    setFolio={setFolio}
                    completado={Completado}
                    asignado={Asignado}
                    setDialogOpen={setDialogOpen}
                    setOpenDialogTecnico={setOpenDialogTecnico}
                />
            </Grid>
        </Paper>
    );
}


const TicketHeader = ({completado, fecha}) => {

    return (
        <Grid
            className="ticket-header"
            container
            justify="space-between"
            alignItems="center"
        >
            <div>
                {
                    !completado ? 
                    (
                        <>
                            <img alt="estado" src={Pendiente} />
                            <span>En proceso</span>
                        </>
                    )
                    :
                    (
                        <>
                            <AssignmentTurnedInIcon style={{color: green[500]}} />
                            <span style={{color:green[500]}}>Cerrado</span>
                        </>
                    )
                }
            </div>
            {
                !completado ?
                <span>{fecha}</span>
                :
                <span style={{color: '#999'}}>{fecha}</span>
            }
        </Grid>
    );
}


const TicketInfo = ({orden, folio, cliente}) => {
    return (
        <Grid style={{padding: 5}} item xs={12}>
            <Grid className="ticket-info" container spacing={2}>
                <Grid style={{padding: 3}} item xs={6} sm={1}>
                    <p>{orden}</p>
                </Grid>
                <Grid style={{padding: 3}} item xs={6} sm={2}>
                    <p style={{textAlign: 'right'}}>{folio}</p>
                </Grid>
                <Grid style={{padding: 3}} item xs={12} sm={9}>
                    <p style={{textAlign: 'right'}}>{cliente}</p>
                </Grid>
            </Grid>
        </Grid>
    );
}


const TicketProblema = ({problema}) => {
    return (
        <Grid item xs={12}>
            <Grid container justify="flex-start" alignItems="center">
                <Grid item xs>
                    <ReportProblemIcon style={{ color: "#999" }} />
                </Grid>
                <Grid item xs={10}>
                    <p className="ticket-problema">
                        {problema}
                    </p>
                </Grid>
            </Grid>
        </Grid>
    );
};

const TicketFooter = ({Folio, asignado, completado, setDialogOpen, setOpenDialogTecnico, setFolio}) => {

    return (
        <Grid className="ticket-footer" item xs={12}>
            <Grid
                container
                alignItems="flex-end"
                style={{ height: "100%" }}
                justify="space-between"
            >
                {completado ? (
                    <Grid item xs={12}>
                        <Button
                            disabled={completado}
                            style={{ margin: 0, padding: 0 }}
                        >
                            <AssignmentIndIcon />
                            {asignado !== null ? asignado : "Sin asignar"}
                        </Button>
                    </Grid>
                ) : (
                    <Grid item xs={11} >
                        <Tooltip title="Asignar técnico">
                            <Button
                                disabled={completado}
                                style={{ margin: 0, padding: 0 }}
                                onClick={() => {
                                    setOpenDialogTecnico(true);
                                    setFolio(Folio);
                                }}
                            >
                                <AssignmentIndIcon />
                                {asignado !== null ? asignado : "Sin asignar"}
                            </Button>
                        </Tooltip>
                    </Grid>
                )}

                {!completado && (
                    <Grid item xs={1} >
                        <Tooltip title="Cerrar ticket">
                            <IconButton
                                onClick={() => {
                                    setDialogOpen(true);
                                    setFolio(Folio);
                                }}
                                style={{ margin: 0, padding: 0 }}
                            >
                                <DoneIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}
export default TicketSingular
