import React, {useEffect, useState} from 'react';
import {CircularProgress} from "@material-ui/core";
import LocalesTabla from "./LocalesTabla";
import Grid from "@material-ui/core/Grid";
import clienteAxios from "../../../../../../config/axios";

const Locales = () => {

    const [locales, setLocales] = useState(null);

    useEffect(()=> {
        const fetchData = async () => {
            const resultado = await clienteAxios.get('api/credenciales/entregaspendientes')
            setLocales(resultado.data);
        }
        fetchData().then();
    }, [])

    const mostrarTabla = () => {
        if (locales === null) {
            return <CircularProgress color={"secondary"}/>
        } else if (locales.length === 0) {
            return <h3>No hay datos disponibles para mostrar</h3>
        } else {
            return <LocalesTabla entregas={locales}/>
        }
    }

    return (
        <Grid container justify={'center'} style={{minHeight: '60vh'}}>
            {
                mostrarTabla()
            }
        </Grid>
    );
};

export default Locales;