import clienteAxios from "../../../config/axios";



export const getPrivadas = async (setPrivadas) => {
    const resultado = await clienteAxios.get(`api/clientes/privada`);
    setPrivadas(resultado.data);
}



export const indexPrivadas = async () => {
    return await clienteAxios.get(`api/clientes/privada`);
}