import clienteAxios from "../config/axios";

export class UsuariosPermisosController
{
    constructor(
        elegirUsuario,
        agregarUsuarios,
        agregarSistemas,
        agregarPermisos,
        agregarMensaje,
        cambiarSisCheck,
        permisosUsuario
    ){
        this.elegirUsuario      =   elegirUsuario;
        this.agregarUsuarios    =   agregarUsuarios;
        this.agregarSistemas    =   agregarSistemas;
        this.agregarPermisos    =   agregarPermisos;
        this.agregarMensaje     =   agregarMensaje;
        this.cambiarSisCheck    =   cambiarSisCheck;
        this.permisos = permisosUsuario;
    }
    obtenerUsuarios = async () =>
    {
        const response = await clienteAxios.get('api/usuarios/usuarios?band=1');
        this.agregarUsuarios(response.data);
    }
    simpleHandleChange = (e) =>
    {
        this.elegirUsuario(e.target.value);
    }
    obtenerPermisos = async () =>
    {
        const response = await clienteAxios.get('api/usuarios/permisos');
        this.agregarSistemas(response.data);
    }
    obtenerPermisosUsuario = async (usuario) =>
    {
        const response = await clienteAxios.get('api/usuarios/usuariosPermisos/'+usuario);
        this.agregarPermisos(response.data);
    }
    registrarPermisos = async (permisosUsuario,usuario) =>
    {
        this.agregarMensaje({});
        clienteAxios.put('api/usuarios/usuariosPermisos/' + usuario, permisosUsuario)
                    .then(await ((response)=>{
                        this.agregarMensaje({
                            'mensaje':response.data.mensaje,
                            'estatus':200
                        });
                    })).catch(await (({response})=>{
                        this.agregarMensaje({
                            'mensaje':'Ocurrió un problema :(',
                            'estatus':400
                        });
                    }));
    }
    handleCheck = (e,id,sys,permisosUsuario,sisCheck) =>
    {
        if(e)
            this.agregarPermisos([
                ...permisosUsuario,
                {
                    'permiso' : id,
                    'PermSistema' : sys
                }
            ])
        else
        {
            this.agregarPermisos([
                ...permisosUsuario.filter(({permiso})=> permiso!==id)
            ])
            this.cambiarSisCheck({
                ...sisCheck,
                [sys]: e
            });
        }

    }
    handleClick = (usuario) =>
    {
        this.obtenerPermisos();
        this.obtenerPermisosUsuario(usuario);
    }
    handleCheckSis = (e,sisId,sisCheck,usuario,sistemas) =>
    {
        this.cambiarSisCheck({
            ...sisCheck,
            [sisId]: e
        });
        if(e)
        {
            let sis =sistemas.filter(({SisId}) => SisId === sisId);
            let permisosSis = sis[0].permisos;
            let nuevosPermisos = [];
            permisosSis.forEach(({PermID}) =>{
                nuevosPermisos.push({
                    'permiso' : PermID,
                    'PermSistema' : sisId
                });
            })
            this.agregarPermisos([
                ...this.permisos.filter( (permiso) => permiso.PermSistema!==sisId),
                ...nuevosPermisos
            ])
        }else
        {
            this.agregarPermisos([
                ...this.permisos.filter(({PermSistema})=> PermSistema !== sisId),
            ])
        }
    }
}