import React from 'react';
import {Button, Icon} from "@material-ui/core";

const BtnAgregarPresidente = ({deshabilitado, handleClick}) => {
    return (
        <Button disabled={deshabilitado} onClick={handleClick}>
            <Icon className={'fa fa-user-tie'} style={{padding: '0 5px 0'}}/>
            <p>Agregar</p>
        </Button>
    );
};

export default BtnAgregarPresidente;
