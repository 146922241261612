import clienteAxios from "../../../config/axios";

export const cerrarCampana = async (e, state, setState, setMensaje) => {

    e.preventDefault()

    clienteAxios.post('api/credenciales/cerrarcampana', state)
        .then(await (({data}) => {
                setMensaje('')
                setMensaje('Campaña cerrada exitosamente')
                setState({
                    cli_ID: '',
                    FechaInicio: '',
                    FechaFin: '',
                    cam_Tipo: 'Estudiantes',
                })
            }
        ))
        .catch(await ((error) => {
                setMensaje('');
                setMensaje('Error al cerrar Campaña')
        }));

}