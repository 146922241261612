import clienteAxios from "../config/axios";
export class UsuariosVerController
{
    constructor(
                usuarios,
                usuario,
                editando,
                agregarUsuarios,
                agregarMensaje,
                abrir,
                selectUsuario,
                activarEditar
                )
    {
        this.usuarios           =usuarios;
        this.usuario            =usuario;
        this.editando           =editando;
        this.agregarUsuarios    =agregarUsuarios;
        this.agregarMensaje     =agregarMensaje;
        this.abrir              =abrir;
        this.selectUsuario      =selectUsuario;
        this.activarEditar      =activarEditar;
    }

    obtenerUsuarios = async () =>
    {
        const response = await clienteAxios.get('api/usuarios/usuarios')
        this.agregarUsuarios(response.data);
    }
    handleEdit = (id) =>
    {
        this.selectUsuario(id);
        this.activarEditar(true)
    }
    eliminarUsuario = async id =>
    {
        this.agregarMensaje('');
        clienteAxios.delete('api/usuarios/usuarios/'+id)
                    .then(await ((response)=>{
                        this.agregarMensaje(response.data.mensaje);
                        this.selectUsuario(0);
                    })).catch(await (()=>{
                        console.log('Error');
                    }));
    }
    abrirDialogo = (id) =>
    {
        this.abrir(true);
        this.selectUsuario(id);
    }
    cerrarDialogo = () =>
    {
        this.abrir(false);
    }
    confirmarDialogo = () =>{
        this.cerrarDialogo();
        this.eliminarUsuario(this.usuario);
    }
}