import axios from 'axios';

    const clienteAxios = axios.create({
        baseURL :'https://sensormx.live/sistemas',
        headers : {
            'Content-Type' : 'application/json',
            'X-Requested-With' :'XMLHttpRequest',
            'Accept' : 'application/json'
        }
    });

export default clienteAxios;