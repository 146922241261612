export const INICIAR_SESION = 'INICIAR_SESSION';
export const CERRAR_SESION = 'CERRAR_SESSION';
export const OBTENER_USUARIO = 'OBTENER_USUARIO';
export const INICIO_FALLIDO = 'INICIO_FALLIDO';
export const PERMISO_OTORGADO = 'ACCESO_OTORGADO';
export const PERMISO_DENEGADO = 'PERMIOS_DENEGADO';
export const REGRESAR_PANEL = 'REGRESAR_PANEL';
export const OBTENER_CLIENTES = 'OBTENER_CLIENTES';
export const SELECCIONAR_CLIENTE = 'SELECCIONAR_CLIENTE';
export const AREA_TRABAJO = 'AREA_TRABAJO';
export const LOTES = 'LOTES';