import React from 'react';
import Grid from "@material-ui/core/Grid";
import {KeyboardDatePicker} from "@material-ui/pickers";
import {ThemeProvider} from "@material-ui/styles";
import {datePickerTheme} from "../../../helpers/Theme";


const SolicitoDate = ({fecha, setFecha}) => {

    const handleDataChange = (date) => {
        setFecha(date);
    }

    return (
        <>
            <ThemeProvider theme={datePickerTheme}>
                <KeyboardDatePicker
                    label={'Se solicitó'}
                    clearable
                    value={fecha}
                    placeholder={'01/01/2020'}
                    onChange={date => handleDataChange(date)}
                    minDate={new Date('01/01/2020')}
                    format={'dd/MM/yyyy'}
                />
            </ThemeProvider>
        </>

    );
};


export default SolicitoDate;