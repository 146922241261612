import React ,{Fragment}from "react";
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
const ConfirmDialog = ({abierto,cerrar,confirmar,titulo,descripcion}) =>
{
    return (
        <Fragment>
            <Dialog
                open={abierto}
                onClose={cerrar}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{titulo}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {descripcion}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <IconButton onClick={confirmar} color="primary">
                        <CheckIcon/>
                    </IconButton>
                    <IconButton onClick={cerrar} color="primary" autoFocus>
                        <ClearIcon/>
                    </IconButton>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}

export default ConfirmDialog;