import React, { useState } from "react";
import { registrarResidentes } from "../../Controllers/ResidentesController";
import Grid from "@material-ui/core/Grid";
import CamposTexto from "./CamposTexto";
import ListaResidentes from "./ListaResidentes";
import ListaVisitantes from "./ListaVisitantes";
import Button from "@material-ui/core/Button";
import SendIcon from "@material-ui/icons/Send";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import {theme} from '../Theme'
import "../../Components/PortalUsuario/InterfazUsuario.scss";


const FormularioRegistroResidentes = ({
    states,
    paramsID,
    url,
    presidente,
}) => {

    const {
        errores,
        setErrores,
        setMensaje,
        initialState,
        setResponse,
    } = states;

    const [residente, setResidente] = useState({
        ...initialState,
        ua_privada: paramsID,
    });
    const [listaResidentes, setListaResidentes] = useState([]);
    const [listaVisitas, setListaVisitas] = useState([]);
    const [selectedDate, setDate] = useState(new Date());
    const history = useHistory();
    const Redireccionar = () => history.push("/");
    const RedireccionarPresidente = () => history.push("/acceso/privadas/ver");

    return (
        <ThemeProvider theme={theme}>
            <form
                onSubmit={(e) =>
                    registrarResidentes(
                        e,
                        {
                            residente,
                            setResidente,
                            listaVisitas,
                            setListaVisitas,
                            listaResidentes,
                            setListaResidentes,
                            setMensaje,
                            setErrores,
                            setDate,
                        },
                        initialState,
                        Redireccionar,
                        url,
                        presidente,
                        RedireccionarPresidente
                    )
                }
            >
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="stretch"
                    spacing={3}
                    className="glass"
                >
                    <Grid item xs={12}>
                        <h3>Detalles de residente</h3>
                    </Grid>
                    <CamposTexto
                        residente={residente}
                        setResidente={setResidente}
                        errores={errores}
                        selectedDate={selectedDate}
                        setDate={setDate}
                        idPrivada={paramsID}
                        setResponse={setResponse}
                    />

                    {/* Lista de residentes */}
                    <Grid item xs={12}>
                        <ListaResidentes
                            setListaResidentes={setListaResidentes}
                            listaResidentes={listaResidentes}
                            setMensaje={setMensaje}
                        />
                    </Grid>
                    {/* Lista de visitas */}
                    <Grid item xs={12}>
                        <ListaVisitantes
                            setListaVisitantes={setListaVisitas}
                            listaVisitantes={listaVisitas}
                            setMensaje={setMensaje}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            endIcon={<SendIcon />}
                            type={"submit"}
                            variant={"contained"}
                        >
                            Registrar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </ThemeProvider>
    );
};

export default FormularioRegistroResidentes;
