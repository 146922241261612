import { createMuiTheme } from '@material-ui/core/styles';
import {red} from "@material-ui/core/colors";

const myTheme = createMuiTheme({
    palette: {
        primary: {
            light: '#eb6e62',
            main: '#e74a3b',
            dark: '#a13329',
            contrastText: '#ddd',
        },
        secondary: {
            light: '#e3e3e3',
            main: '#ddd',
            dark: '#9a9a9a',
            contrastText: '#000',
        },
        error: {
            light:'#d33',
            main: '#c00',
            dark: '#900',
            contrastText: '#ccc',
        },
    },
});

export const datePickerTheme = createMuiTheme({
    palette: {
        primary: red,
    },
});

export default myTheme