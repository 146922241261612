import React, {Fragment, useEffect, useState} from 'react';
import clienteAxios from "../../../../config/axios";
import {CircularProgress, Icon, TableContainer, TableRow} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Grid from "@material-ui/core/Grid";
import CopyToClipboard from "./CopyToClipboard";
import BtnAgregarPresidente from "./BtnAgregarPresidente";
import {useHistory, useLocation} from "react-router-dom";
import {Contenedor} from '../AccesoComponents'

const url = 'http://sensormx.ga/registro/'

const VerPrivadas = () => {

    const [privadas, setPrivadas] = useState(null);


    useEffect(() => {
        const fetchData = async () => {
            const resultado = await clienteAxios.get('api/clientes/privada');
            setPrivadas(resultado.data);
        }
        fetchData().then()
    }, []);

    const mostrarTabla = () => {
        if (privadas === null) {
            return <CircularProgress color={"secondary"}/>
        } else if (privadas.length === 0) {
            return <h3>No hay datos disponibles para mostrar</h3>
        } else {
            return <Tabla privadas={privadas}/>
        }
    }

    return (
        <Fragment>
            <h2 style={{textAlign: 'center'}}>Privadas</h2>
            <Contenedor>
                <Grid item>
                    {
                        mostrarTabla()
                    }
                </Grid>

            </Contenedor>
        </Fragment>

    );
};

const Tabla = ({privadas}) => {

    const history = useHistory();
    const location = useLocation();


    return (
        <Paper>
            <TableContainer style={{maxHeight: '70vh'}}>
                <Table stickyHeader aria-label="Privadas registradas">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell align="center">Descripción</TableCell>
                            <TableCell align='center'>Liga de registro</TableCell>
                            <TableCell align='center'>Presidente</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            privadas.map((row, index) => {

                                const Redireccionar = () =>
                                    history.push(`/clientes/registrar/presidente/${row['pri_ID']}`);

                                return(
                                <TableRow key={index} hover>
                                    <TableCell scope="row">{row.pri_ID}</TableCell>
                                    <TableCell align="center">
                                        {row.pri_Descripcion}

                                    </TableCell>
                                    <TableCell align='left'>
                                        <CopyToClipboard Id={row.pri_ID} url={url}/>
                                    </TableCell>
                                    <TableCell align='center'>
                                        <BtnAgregarPresidente deshabilitado={row['user_ID']?true:false} handleClick={Redireccionar}/>
                                    </TableCell>
                                </TableRow>
                            )})
                        }
                    </TableBody>
                </Table>

            </TableContainer>
        </Paper>
    );
};

export default VerPrivadas;