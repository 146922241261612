import React from 'react';
import UsuarioMenu from "./UsuarioMenu";
import LogoSensor from "./LogoSensor";
import NombreSistema from "./NombreSistema";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";

const myStyle = {
    height: "10vh",
    width: "100vw",
    position: 'fixed',
    padding: "0px",
    paddingRight: "0px",
    margin: "0px",
    zIndex: "100",
    backgroundColor: "#333",
};

const Navbar = ({nombreSistema}) => {
    return (
        <nav className="navbar fixed-top topbar row" style={myStyle}>
            <Grid container spacing={0}>
                <Grid item xs>
                    <LogoSensor/>
                </Grid>
                <Hidden smDown>
                    <Grid  item xs={6}>
                        <NombreSistema nombreSistema={nombreSistema}/>
                    </Grid>
                </Hidden>
                <Grid item xs display='flex'>
                    <UsuarioMenu/>
                </Grid>
            </Grid>
        </nav>
    );
};

export default Navbar;