import React from 'react';
import {CustomTF} from "../../GlobalesCredenciales/GlobalTextField";

const TextField = ({name, id, onChange}) => {
    return (
        <CustomTF name={name} id={id} onChange={onChange} type='number'/>
    );
};

export const handleChange = (e, objeto, setObjeto) => {
    setObjeto({
        ...objeto, [e.target.name]: e.target.value
    })
}

export default TextField;