import {withStyles} from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import myTheme from "../../helpers/Theme";
import React from "react";

const StyledMenuItem = withStyles((theme) => ({
    root: {
        backgroundColor: myTheme.palette.primary.main,
        color: myTheme.palette.secondary.main,
        '&focus':{
            backgroundColor: myTheme.palette.primary.light,
        },
    },
}))(MenuItem);

export default StyledMenuItem