import React, {useContext, useEffect, useState} from 'react';
import StyledTabs from "../GlobalesCredenciales/StyledTabs";
import StyledTab from "../GlobalesCredenciales/StyledTab";
import TabPanel from "../GlobalesCredenciales/TabPanel";
import Listos from "./Listos/Listos";
import Pendientes from "./Pendientes/Pendientes";
import Grid from "@material-ui/core/Grid";
import {abrirAreaTrabajo, generarEntrega} from "../../Controllers/LotesController";
import BtnEntrega from "./BtnEntrega";
import BtnAreaTrabajo from "./BtnAreaTrabajo";
import {useAlert} from "react-alert";
import globalContext from "../../../../Context/GlobalContext/globalContext";
import {useHistory} from "react-router-dom";
import AlertaDialog from "./AlertaDialog";

const useStyles = () => ({
    root: {
        flexGrow: 1,
    },
});

const TabLotes = () => {

    const context = useContext(globalContext)
    const alert = useAlert();
    const history = useHistory();
    const classes = useStyles();

    const {activarAreaTrabajo, setLotes} = context;
    const [value, setValue] = useState(0);
    const [listos, setListos] = useState(null);
    const [pendientes, setPendientes] = useState(null);
    const [mensaje, setMensaje] = useState({id:'',msg: ''});
    const [count, setCount] = useState(0);

   const sets = {
       setLotes: setLotes,
       setMensaje: setMensaje,
   }

    const incrementar = flag => flag ? setCount(count+1):setCount(count-1);
    const contador = {incrementar, count}
    const Redireccionar = () => history.push('/credenciales/AreaTrabajo');


    useEffect(() => {
        if (mensaje.id === 2){
            alert.error(mensaje.msg);
        } else if (mensaje.id === 1)
            alert.success(mensaje.msg);
    }, [mensaje]);


    return (
        <div className={classes.root}>
            <Grid container direction="row" justify="space-between" alignItems="center">
                <Grid item>
                    <StyledTabs value={value} onChange={(event, newValue)=>setValue(newValue)} aria-label="Lotes">
                        <StyledTab label={'Listos'}/>
                        <StyledTab label={'Pendientes'}/>
                    </StyledTabs>
                </Grid>
                <Grid item>
                    {
                        value === 0 ?
                            <BtnEntrega onClick={() => generarEntrega(listos, setMensaje, setListos)}/>
                            :
                            <BtnAreaTrabajo
                                onClick={() => abrirAreaTrabajo(count, pendientes, activarAreaTrabajo, Redireccionar, sets)}
                            />
                    }
                </Grid>
            </Grid>

            <TabPanel value={value} index={0}>
                <Listos listos={listos} setListos={setListos}/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Pendientes pendientes={pendientes} setPendientes={setPendientes} contador={contador}/>
            </TabPanel>
        </div>
    );
};



export default TabLotes;