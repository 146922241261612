import axios from "../../../config/axios";
import * as qs from "qs";
import clienteAxios from "../../../config/axios";

export const getLotesTrabajo = (lotesId, setLotes) => {
    axios.get('api/credenciales/trabajarLotes', {
        params: {
            lotes: lotesId
        },
        paramsSerializer: params => {
            return qs.stringify(params)
        }
    })
        .then(({data}) => {
            setLotes(data);
        })
        .catch((error) => {})


}

export const mover = (fila, setSiguiente, sigState,stateActual, setStateActual, final) => {
    //Lega la fila a mover
    //Agregarla al state nuevo
    setSiguiente([...sigState, fila]);
    //Buscar en stateActual para quitarla
    let arr = stateActual.filter(item => item.ID !== fila.ID)
    setStateActual(arr);
    if(!final)
        clienteAxios.put('api/credenciales/actualizarLote', {lot_ID: fila.ID})

}

export const finalizarLote = async (idLote, codigos,arregloCredenciales, moverState, setMensaje) => {

    const {fila, setSiguiente, sigState, stateActual, setStateActual} = moverState;
    
    const body = {
        lot_ID: idLote,
        Codigos: codigos
    }

    if(checkCodigos(codigos, arregloCredenciales)){
        setMensaje('')
        setMensaje('Hay códigos repetidos')
        return
    }
        clienteAxios.put('api/credenciales/actualizarLote', body).then()
        mover(fila, setSiguiente, sigState, stateActual, setStateActual, true)
}

const checkCodigos = (state, arregloCredenciales) => {
    const values = Object.values(state); //Valores de codigos internos del state
    const valuesSet = new Set(values);

    if(values.length !== valuesSet.size)
        return true
    //Retorna true si el tamaño difiere y se toma como error,o si hay un campo vacio
    if(values.length !== arregloCredenciales.length || values.includes(''))
        return true
}

export const alterarErrores = async (errores, zona) => {
    const body = {
        err_Zona: zona,
        err_Cantidad: errores
    }
    clienteAxios.post('api/credenciales/agregarErrores',body)
        .then()

}


