import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import myTheme from "../../helpers/Theme";
import Tooltip from "@material-ui/core/Tooltip";

const CustomTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: myTheme.palette.secondary.light,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);

export default CustomTooltip;