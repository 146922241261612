import React from 'react'
import RegistrarPrivadas from './RegistrarPrivadas'
import VerPrivadas from './VerPrivadas'

const Privadas = () => {
    return (
        <div>
            <RegistrarPrivadas/>
            <VerPrivadas/>
        </div>
    )
}

export default Privadas
