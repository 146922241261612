import React, {useEffect, useState} from "react";
import {UsuariosFormController as Controller} from "../../Controllers/UsuariosFormController";
import {useAlert} from "react-alert";
import {
        TextField,
        Select,
        MenuItem,
        FormControl,
        InputLabel,
        Button
} from '@material-ui/core';

const stateInicial ={
    UsNombre : '',
    UsApPat : '',
    UsApMat : '',
    email : '',
    UsTipo : '',
    UsFechaNac : new Date().getFullYear()+'-01-01',
    password : '',
    confirmar : '',
    passwordActual : '',
};

const margen  = {
    marginTop: '10vh'
};
const UsuariosForm = () =>
{
    const alert = useAlert();
    const [usuario , nuevoUsuario] = useState(stateInicial);
    const [tiposDeUsuario,agregarTipos] = useState([]);
    const [errores,agregarErrores] = useState({});
    const [mensaje,agregarMensaje] =useState('');
    const {obtenerTipos,handleChange,handleSubmit} = new Controller();

    useEffect(()=>{
        obtenerTipos(agregarTipos);
    },[]);

    useEffect(()=>{
        if(mensaje!=='')
            alert.success(mensaje)
    },[mensaje]);

    return (
        <form className='col-12' onSubmit={(e) =>
                                            handleSubmit(e,usuario,nuevoUsuario,stateInicial,agregarErrores,agregarMensaje)}>
            <div className='row col-sm-10 col-md-12 justify-content-sm-end  justify-content-md-center'>
                <div className='col-4'>
                    <TextField
                        type='text'
                        name='UsNombre'
                        label='Nombre'
                        onChange={(e)=> handleChange(e,usuario,nuevoUsuario)}
                        value={usuario.UsNombre}
                        fullWidth
                        error={errores.UsNombre?true:false}
                        helperText={errores.UsNombre}
                    />
                </div>
                <div className='col-3'>
                    <TextField
                        type='text'
                        name='UsApPat'
                        label='Apellido Paterno'
                        onChange={(e)=> handleChange(e,usuario,nuevoUsuario)}
                        value={usuario.UsApPat}
                        fullWidth
                        error={errores.UsApPat?true:false}
                        helperText={errores.UsApPat}
                    />
                </div>
                <div className='col-3'>
                    <TextField
                        type='text'
                        name='UsApMat'
                        label='Apellido Materno'
                        onChange={(e)=> handleChange(e,usuario,nuevoUsuario)}
                        value={usuario.UsApMat}
                        fullWidth
                        error={errores.UsApMat?true:false}
                        helperText={errores.UsApMat}
                    />
                </div>
            </div>
            <div style={margen} className='row col-sm-10 col-md-12 justify-content-sm-end  justify-content-md-center'>
                <div className='col-4'>
                    <TextField
                        type='email'
                        name='email'
                        label='Correo Electrónico'
                        onChange={(e)=> handleChange(e,usuario,nuevoUsuario)}
                        value={usuario.email}
                        fullWidth
                        error={errores.email?true:false}
                        helperText={errores.email}
                    />
                </div>
                <div className='col-3'>
                    <FormControl
                         fullWidth
                         error={errores.UsTipo?true:false}
                         helperText={errores.UsTipo}
                    >
                        <InputLabel>Tipo de usuario</InputLabel>
                        <Select
                            name='UsTipo'
                            onChange={(e)=> handleChange(e,usuario,nuevoUsuario)}
                            value={usuario.UsTipo}
                        >
                            <MenuItem>Seleccione</MenuItem>
                            {tiposDeUsuario.map(tipo=>
                                <MenuItem key={tipo.TuID} value={tipo.TuID}>{tipo.UsTipo}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </div>
                <div className='col-3'>
                    <TextField
                        type='date'
                        name='UsFechaNac'
                        label='Fecha de nacimiento'
                        onChange={(e)=> handleChange(e,usuario,nuevoUsuario)}
                        value={usuario.UsFechaNac}
                        fullWidth
                        error={errores.UsFechaNac?true:false}
                        helperText={errores.UsFechaNac}
                    />
                </div>
            </div>
            <div style={margen} className={'row col-sm-8  col-md-12 justify-content-sm-end  justify-content-md-center mt-5'}>
                <div className='col-4'>
                    <TextField
                        type={'password'}
                        name={'password'}
                        label={'Contraseña'}
                        value={usuario.password}
                        onChange={(e)=> handleChange(e,usuario,nuevoUsuario)}
                        fullWidth
                        error={errores.password?true:false}
                        helperText={errores.password}
                    />
                </div>
                <div className='col-3'>
                    <TextField
                        type={'password'}
                        name={'confirmar'}
                        label={'Confirmar Contraseña'}
                        value={usuario.confirmar}
                        onChange={(e)=> handleChange(e,usuario,nuevoUsuario)}
                        fullWidth
                        error={errores.confirmar?true:false}
                        helperText={errores.confirmar}
                    />
                </div>
                <div className='col-3'>
                    <TextField
                        type={'password'}
                        name={'passwordActual'}
                        label={'Contraseña actual'}
                        value={usuario.passwordActual}
                        onChange={(e)=> handleChange(e,usuario,nuevoUsuario)}
                        fullWidth
                        error={errores.passwordActual?true:false}
                        helperText={errores.passwordActual}
                    />
                </div>
            </div>
            <div style={margen} className='row justify-content-center col-12' >
                <Button
                    variant="contained"
                    color='secondary'
                    className='col-10 col-md-3'
                    type='submit'
                    fullWidth
                >Registrar</Button>
            </div>
        </form>
    );
}

export default UsuariosForm;