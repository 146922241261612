import React, {useEffect, useState} from 'react';
import Tabla from "./Tabla";
import {basicContainer} from "../../../helpers/Styles";
import Grid from "@material-ui/core/Grid";
import clienteAxios from "../../../../../config/axios";
import {CircularProgress} from "@material-ui/core";

const Historial = () => {

    const [solicitudes, setSolicitudes] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const resultado = await clienteAxios.get('api/credenciales/solicitudes');
            setSolicitudes(resultado.data);
        }
        fetchData().then()
    }, []);

    const mostrarTabla = () => {
        if (solicitudes === null) {
            return <CircularProgress color={"secondary"}/>
        } else if (solicitudes.length === 0) {
            return <h3>No hay datos disponibles para mostrar</h3>
        } else {
            return <Tabla solicitudes={solicitudes}/>
        }
    }

    return (
        <Grid
            container
            display='flex'
            justify='flex-start'
            alignItems='center'
            component='div'
            direction='column'
            style={basicContainer}
        >
            <Grid item>
                <h1 style={{textAlign: 'center', marginTop: '5vh'}}>Historial de solicitudes</h1>
            </Grid>
            <Grid item style={{width: '100%'}}>
                <Grid container  justify={'center'} style={{minHeight: '60vh'}}>
                    {
                        mostrarTabla()
                    }
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Historial;