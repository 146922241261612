import clienteAxios from "../config/axios";

export class UsuariosEditController
{
    constructor(
                usuario,
                tiposDeUsuario,
                errores,
                editarUsuario,
                agregarTipos,
                agregarErrores,
                finalizarEdit
                )
    {
        this.usuario        = usuario;
        this.tiposDeUsuario = tiposDeUsuario;
        this.errores        = errores;
        this.editarUsuario  = editarUsuario;
        this.agregarTipos   = agregarTipos;
        this.agregarErrores = agregarErrores;
        this.finalizarEdit  = finalizarEdit;
    }

    obtenerTipos = async () =>
    {
        const response = await clienteAxios.get('api/usuarios/tiposDeUsuario');
        this.agregarTipos(response.data);
    }
    handleChange = (e) =>
    {
        this.editarUsuario({
            ...this.usuario,
            [e.target.name] : e.target.value
        })
    }
    handleSubmit = async (e) =>
    {
        const {usuario,agregarErrores,finalizarEdit} = this;

        e.preventDefault();

        if(usuario.password!==usuario.confirmar){
            agregarErrores({
                'confirmar' : 'Las contraseñas no coinciden.'
            });return;
        }
        clienteAxios.put('api/usuarios/usuarios/'+usuario.id,usuario)
            .then(await (({data})=>{
                agregarErrores({});
                finalizarEdit(false);
            })).catch(await ((error)=>{
            agregarErrores(error.response.data.errors);
        }))
    }
    obtenerUsuario = async (user) =>
    {
        const response = await clienteAxios.get('api/usuarios/usuarios/'+user);
        this.editarUsuario(response.data);
    }
    regresar = (e) =>
    {
        e.preventDefault();
        this.finalizarEdit(false);
    }
}