import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {basicContainer, tituloStyle} from "../../../helpers/Styles";
import {KeyboardDatePicker} from "@material-ui/pickers";
import {ThemeProvider} from "@material-ui/styles";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import {red} from "@material-ui/core/colors";
import RedButton from "../../GlobalesCredenciales/RedButton";
import ComboClientes from '../../Solicitudes/Nueva/ComboClientes'
import {fechaString} from "../../../helpers/Util";
import RadioTipos from "../../Solicitudes/Nueva/RadioTipos";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {cerrarCampana, cerrarCampaña} from "../../../Controllers/CerrarCampañaController";
import {useAlert} from "react-alert";

const materialTheme = createMuiTheme({
    palette: {
        primary: red,
    },
});

const useStyles = makeStyles({
    margins: {
        marginBottom: '5vh',
    }
});


const stateInicial = {
    cli_ID: '',
    FechaInicio: '',
    FechaFin: '',
    cam_Tipo: 'Estudiantes',
};

const Cerrar = () => {

    const alert = useAlert()
    const [mensaje, setMensaje] = useState('')
    const [cerrar, setCerrar] = useState(stateInicial)
    const [inicial, setInicial] = useState(new Date())
    const [final, setFinal] = useState(new Date())

    useEffect(() => {
        if (mensaje !== '') {
            alert.info(mensaje);
        }
    }, [mensaje]);

    useEffect(() => {
        if (inicial === null)
            return;
        cerrar.FechaInicio = fechaString(inicial);
    }, [inicial]);

    useEffect(() => {
        if (final === null)
            return;
        cerrar.FechaFin = fechaString(final);
    }, [final]);

    const handleChangeInicial = date => {
        setInicial(date);
        setFinal(date)
    };

    const handleChangeFinal = date => setFinal(date)
    const classes = useStyles();

    return (

            <Grid
                container
                justify='flex-start'
                component='div'
                direction='column'
                style={basicContainer}
            >
                <Grid item>
                    <h1 style={tituloStyle}>Cerrar campaña</h1>
                </Grid>

                <Grid item>
                    <Grid
                        container
                        component='div'
                        direction='row'
                        justify='center'
                        alignItems='center'
                    >
                        <form
                            onSubmit={e => cerrarCampana(e, cerrar, setCerrar, setMensaje)}
                            style={{width: '500px'}}
                        >

                            <Grid
                                container
                                display='flex'
                                justify='center'
                                direction='row'
                            >
                                <Grid item xs={12} className={classes.margins}>
                                    <ComboClientes state={cerrar} setState={setCerrar} name={'cli_ID'}/>
                                </Grid>
                                <Grid item xs={12}  className={classes.margins}>
                                    <RadioTipos state={cerrar} setState={setCerrar} name={'cam_Tipo'}/>
                                </Grid>
                                <ThemeProvider theme={materialTheme}>
                                    <Grid item xs={12}  className={classes.margins}>
                                        <KeyboardDatePicker
                                            clearable
                                            value={inicial}
                                            label={'Inicio de campaña'}
                                            placeholder="10/10/2018"
                                            onChange={date => handleChangeInicial(date)}
                                            minDate='1/1/2020'
                                            maxDate={final}
                                            variant={'dialog'}
                                            format="MM/dd/yyyy"
                                        />
                                    </Grid>
                                    <Grid item xs={12}  className={classes.margins}>
                                        <KeyboardDatePicker
                                            clearable
                                            label={'Fin de campaña'}
                                            value={final}
                                            placeholder="10/10/2018"
                                            onChange={date => handleChangeFinal(date)}
                                            minDate={inicial}
                                            variant={'dialog'}
                                            format="MM/dd/yyyy"
                                        />
                                    </Grid>
                                </ThemeProvider>
                            </Grid>

                            <Grid item>
                                <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                                    <RedButton
                                        type={'submit'}
                                        icono={'calendar-times'}
                                        text={'Cerrar campaña'}
                                    />
                                </div>
                            </Grid>
                        </form>
                    </Grid>

                </Grid>


            </Grid>

    );
};

export default Cerrar;