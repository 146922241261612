import React from 'react';
import {KeyboardDatePicker} from "@material-ui/pickers";
import {ThemeProvider} from "@material-ui/styles";
import {datePickerTheme} from "../../../helpers/Theme";

const DatePicker = ({fecha, setFecha}) => {

    const handleDataChange = (date) => {
        setFecha(date);
    }

    return (
        <ThemeProvider theme={datePickerTheme}>
            <KeyboardDatePicker
                clearable
                value={fecha}
                onChange={date => handleDataChange(date)}
                minDate={new Date('01/01/2020')}
                format={'dd/MM/yyyy'}
            />
        </ThemeProvider>
    );
};

export default DatePicker;