import React,{Fragment} from "react";
import UsuariosEditForm from "./UsuariosEditForm";

const UsuariosEdit = ({user,mostrar,activarEditar}) =>
{
    return(
        <Fragment>
        {mostrar?
            <div className={'container-flex'}>
                <div className={'row'}>
                    <div className={'offset-1 col-3'}>
                        <h1 style={{fontFamily : 'impact',fontSize : '3.0rem', color : 'grey'}}>EDITAR</h1>
                    </div>
                </div>
                <div className='row' style={{fontFamily : 'impact'}}>
                    <UsuariosEditForm
                        user={user}
                        finalizarEdit={activarEditar}
                    />
                </div>
            </div>
            :''
        }
        </Fragment>
    );
}

export default UsuariosEdit;