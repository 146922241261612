import clienteAxios from "../config/axios";

export class UsuariosFormController
{
    obtenerTipos = async (agregarTipos) =>
    {
        const response = await clienteAxios.get('api/usuarios/tiposDeUsuario');
        agregarTipos(response.data);
    };
    handleChange = (e,usuario,nuevoUsuario) =>
    {
        nuevoUsuario({
            ...usuario,
            [e.target.name] : e.target.value
        })
    };
    handleSubmit = async (e,usuario,nuevoUsuario,stateInicial,agregarErrores,agregarMensaje) =>
    {
        e.preventDefault();
        if(usuario.password!==usuario.confirmar){
            agregarErrores({
                'confirmar' : 'Las contraseñas no coinciden.'
            });return;
        }

        clienteAxios.post('api/usuarios/usuarios',usuario)
            .then(await (({data})=>{
                agregarMensaje(data.mensaje);
                nuevoUsuario(stateInicial);
                agregarErrores({});
            })).catch(await ((error)=>{
                agregarErrores(error.response.data.errors);
            }))
    }
}