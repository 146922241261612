import React from 'react';
import Grid from "@material-ui/core/Grid";
import ListaAgregar from "./ListaAgregar";

const ListaVisitantes = ({setListaVisitantes, listaVisitantes, setMensaje}) => {

    return (
        <Grid item xs={12}>
            <Grid container>
                <Grid item xs={12}>
                    <h3>Visitas</h3>
                </Grid>
                <Grid item xs={12}>
                    <ListaAgregar
                        concepto={'visitantes'}
                        setState={setListaVisitantes}
                        lista={listaVisitantes}
                        setMensaje={setMensaje}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ListaVisitantes;
