import clienteAxios from "../../../config/axios";
import {objectToFormData} from "object-to-formdata";


export const handleSubmit = async (e, solicitud, nuevaSolicitud, stateInicial, agregarMensaje, agregarErrores, agregarErrorMsg) => {
    e.preventDefault();

    const formData = objectToFormData(solicitud);
    const excel = formData.get('sol_Excel[]');
    const zip = formData.get('sol_FyF[]')
    formData.append('sol_Excel', excel);
    formData.append('sol_FyF', zip);
    formData.delete('sol_Excel[]');
    formData.delete('sol_FyF[]');

    clienteAxios.post('api/credenciales/solicitudes', formData)
        .then(await (({data}) => {
            nuevaSolicitud(stateInicial);
            agregarMensaje('');
            agregarMensaje('Se ha registrado la solicitud correctamente');
        }))
        .catch(await ((error) => {
            nuevaSolicitud(solicitud);
            if (error.response.data.error) {
                agregarErrorMsg('');
                agregarErrorMsg(error.response.data.error)
            } else {
                agregarErrores(error.response.data.errors)
            }
        }));
}

export const getCriterios = async (agregarCriterios, clienteID, solicitud, nuevaSolicitud) => {

    clienteAxios.get(`api/credenciales/criterios/${clienteID}`)
        .then(await (({data}) => {
            if (data.length === 0) {
                nuevaSolicitud({
                    ...solicitud,
                    ['lot_Criterio']: '0'
                })
                agregarCriterios([
                    {cri_ID: 0, cri_Descripcion: 'No disponible'}
                ])
                return;
            }
            nuevaSolicitud({
                ...solicitud,
                ['lot_Criterio']: data[0].cri_ID
            })
            agregarCriterios(data);
        }))
        .catch(await (() => {
                agregarCriterios([
                    {cri_ID: '0', cri_Descripcion: 'Seleccione cliente'}
                ])
            }
        ));
};

