import React from 'react';
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import myTheme from "../../helpers/Theme";
import withStyles from "@material-ui/core/styles/withStyles";

const MyButton = withStyles({
   root: {
       backgroundColor: myTheme.palette.primary.main,
       margin: '2rem 2rem 0.5rem 2rem',
       boxShadow: '1px 1px 10px 0px rgba(0,0,0,0.4)',
       '&:hover':{
           backgroundColor: myTheme.palette.primary.dark,
       }
   } ,

})(Button);

const RedButton = ({icono,text, onClick, disabled, type}) => {

    return (
        <MyButton
            onClick={onClick}
            disabled={disabled}
            type={type}
        >
            <Icon className={`fas fa-${icono}`} style={{color: '#fff'}} />
            <span style={{marginLeft: '5px', color: '#fff'}}>{text}</span>
        </MyButton>
    );
};

export default RedButton;
