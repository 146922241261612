import React, {useContext} from 'react';
import Funcion from '../../../Credenciales/Components/SideBar/Funcion'
import BotonRegresar from '../../../Credenciales/Components/SideBar/BotonRegresar'
import Grid from "@material-ui/core/Grid";
import globalContext from "../../../../Context/GlobalContext/globalContext";

const ListaFunciones = () => {

    const context = useContext(globalContext);
    const {regresarPanel} = context;
    const regresar = () => regresarPanel();


    return (
        <Grid
            container
            direction='column'
            alignItems='center'
            justify='center'
        >
            <ul style={{width: '100%'}}>
                <Funcion
                    icono='home'
                    nombre='Privadas'
                    fragmentos={[]}
                    url={'/clientes/privadas'}
                />
                <Funcion
                    icono='user-check'
                    nombre='Residentes'
                    fragmentos={[]}
                    url={'/clientes/residentes/verificar'}
                />
                <Funcion 
                    icono='clipboard-list'
                    nombre='Tickets'
                    fragmentos={[]}
                    url={'/clientes/tickets/prioridad'}
                />
                <BotonRegresar
                    icono={'sign-out-alt'}
                    handleClick={regresar}
                />
            </ul>
        </Grid>

    );
};

export default ListaFunciones;