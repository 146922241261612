import React, {useState} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Acordeon from "./Acordeon";



const AlertaDialog = ({titulo,alertas, errores}) => {

    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{titulo}</DialogTitle>
            <DialogContent style={{width: '100%'}}>
                <Acordeon titulo={'Alertas'} info={alertas}/>
                <Acordeon titulo={'Errores'} info={errores}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}  autoFocus>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AlertaDialog;