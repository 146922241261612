import React, {useEffect, useState} from 'react';
import {CircularProgress} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import clienteAxios from "../../../../../../config/axios";
import ExternasTabla from "./ExternasTabla";

const Externas = () => {

    const [externas, setExternas] = useState(null);

    useEffect(()=> {
        const fetchData = async () => {
            const resultado = await clienteAxios.get('api/credenciales/entregasextpendientes')
            setExternas(resultado.data);
        }
        fetchData().then();
    }, [])

    const mostrarTabla = () => {
        if (externas === null) {
            return <CircularProgress color={"secondary"}/>
        } else if (externas.length === 0) {
            return <h3>No hay datos disponibles para mostrar</h3>
        } else {
            return <ExternasTabla entregas={externas}/>
        }
    }

    return (
        <Grid container justify={'center'} style={{minHeight: '60vh'}}>
            {
                mostrarTabla()
            }
        </Grid>
    );
};

export default Externas;