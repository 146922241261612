import React from 'react';
import {TableContainer, TableRow} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import StyledTableCell from "../../../GlobalesCredenciales/StyledTableCell";
import TableBody from "@material-ui/core/TableBody";
import StyledRow from "../../../GlobalesCredenciales/StyledRow";
import Paper from "@material-ui/core/Paper";

const TablaExternas = ({entregasExternas}) => {
    return (
        <Paper style={{width: '100%'}}>
            <TableContainer style={{maxHeight: '70vh'}}>
                <Table stickyHeader aria-label="Historial de entregas externas">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Folio</StyledTableCell>
                            <StyledTableCell align="center">Fecha enviada</StyledTableCell>
                            <StyledTableCell align="center">Fecha recibida</StyledTableCell>
                            <StyledTableCell align="center">Cantidad</StyledTableCell>
                            <StyledTableCell align="center">Cliente</StyledTableCell>
                            <StyledTableCell align="center">Proveedor</StyledTableCell>
                            <StyledTableCell align="center">Número de guía</StyledTableCell>
                            <StyledTableCell align="center">Recibió</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            entregasExternas.map((row) => (
                                <StyledRow key={row['Folio']}>
                                    <StyledTableCell scope="row">{row.Folio}</StyledTableCell>
                                    <StyledTableCell align="center">{row['Fecha enviada']}</StyledTableCell>
                                    <StyledTableCell align="center">{row['Fecha recibida']}</StyledTableCell>
                                    <StyledTableCell align="center">{row.Cantidad}</StyledTableCell>
                                    <StyledTableCell align="center">{row.Cliente}</StyledTableCell>
                                    <StyledTableCell align="center">{row.Proveedor}</StyledTableCell>
                                    <StyledTableCell align="center">{row['Numero de guia']}</StyledTableCell>
                                    <StyledTableCell align="center">{row.Recibio}</StyledTableCell>
                                </StyledRow>
                            ))
                        }
                    </TableBody>
                </Table>

            </TableContainer>
        </Paper>
    );
};

export default TablaExternas;