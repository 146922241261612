import React from 'react';
import {TextField} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import myTheme from "../../helpers/Theme";
import withStyles from "@material-ui/core/styles/withStyles";

export const CustomTF = withStyles({
    root: {
        '& .Mui-focused': {
            color: myTheme.palette.secondary.dark,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: myTheme.palette.secondary.dark,
        },
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                color: myTheme.palette.secondary.dark,
            },
            '&.Mui-focused fieldset': {
                borderColor: myTheme.palette.secondary.dark,
            },
        },
    },
})
(props => (
    <TextField
        fullWidth
        {...props}
    />
));

const GlobalTextField = ({id, name, disabled,label, icono, tipo, value,
                             error, helperText, onChange, multiline,
                             rows,long,onBlur,variant}) => {

    return (
        <Grid container spacing={1} alignItems="flex-end">
            {
                icono === undefined ? null :
                    <Grid item>
                        <Icon className={`fa fa-${icono}`}/>
                    </Grid>
            }
            <Grid item xs>
                <CustomTF
                    id={id}
                    name={name}
                    disabled={disabled}
                    label={label}
                    onChange={onChange}
                    onBlur={onBlur}
                    type={tipo}
                    error={error}
                    helperText={helperText}
                    value={value}
                    multiline={multiline}
                    rowsMax={rows}
                    variant={variant}
                    inputProps= {
                        {maxLength: long}
                    }
                />
            </Grid>
        </Grid>
    );
};

export const handleChange = (e, objeto, setObjeto) => {
    setObjeto({
        ...objeto, [e.target.name]: e.target.value
    })
}

export default GlobalTextField;