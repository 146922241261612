import React, {useState, useEffect, useContext} from 'react';
import Grid from "@material-ui/core/Grid";
import GlobalTextField from "../../../../GlobalesCredenciales/GlobalTextField";
import SubmitButton from "../Components/SubmitButton";
import {handleChange, handleSubmit} from '../../../../../Controllers/FormulariosController'
import {isInputNumber} from "../../../../../helpers/Util";
import {useAlert} from "react-alert";
import {onBlurHandler} from "../../../../../Controllers/ModificarController";
import globalContext from "../../../../../../../Context/GlobalContext/globalContext";
import InputFile from "../../../../GlobalesCredenciales/InputFile";

const stateInicial = {
    doc_NumeroEmpleado: '',
    doc_Nombre: '',
    doc_ApellidoMaterno: '',
    doc_ApellidoPaterno: '',
    doc_Adscripcion: '',
    doc_Categoria: '',
    doc_Correo: '',
    doc_Telefono: '',
    doc_CalleNumero: '',
    doc_Colonia: '',
    doc_PoblacionCodigoPostal: '',
    doc_Firma: null,
    doc_Foto: null,
};


const Docentes = () => {

    const alert = useAlert();
    const [docente, nuevoDocente] = useState(stateInicial);
    const [mensaje, agregarMensaje] = useState('');
    const [errores, agregarErrores] = useState({});
    const [modificando, setModificando] = useState(false);
    const [poblacionCodigoPostal, setPoblacionCodigoPostal] = useState(
        {poblacion: '', codigoPostal: ''}
    )

    const context = useContext(globalContext)
    const {cliente} = context;
    const clienteID = cliente['cli_ID'];


    useEffect(() => {
        if (mensaje !== '') {
            alert.success(mensaje);
        }
    }, [mensaje]);


    return (
        <Grid container
              display='flex'
              justify='flex-start'
              component='div'
              direction='column'
        >
            <form onSubmit={(e) =>
                handleSubmit(e, docente, nuevoDocente, clienteID, 'doc',
                    stateInicial, agregarErrores, agregarMensaje, modificando, setModificando, poblacionCodigoPostal, setPoblacionCodigoPostal)}>
                <Grid item>
                    <Grid
                        container
                        display='flex'
                        direction="row"
                        justify="center"
                        alignItems="flex-start"
                        spacing={2}
                    >
                        <Grid item md={3} sm={12}>
                            <GlobalTextField
                                id={'numID'}
                                label={'Numero de empleado'}
                                icono={'id-card'}
                                long={4}
                                name={'doc_NumeroEmpleado'}
                                value={docente.doc_NumeroEmpleado}
                                error={!!errores.doc_NumeroEmpleado}
                                disabled={modificando}
                                helperText={errores.doc_NumeroEmpleado}
                                onBlur={(e) =>
                                    onBlurHandler(e, 'doc', nuevoDocente, docente.doc_NumeroEmpleado, stateInicial, setModificando, setPoblacionCodigoPostal, clienteID, alert)}
                                onChange={(e) => {
                                    isInputNumber(e);
                                    handleChange(e, docente, nuevoDocente);
                                }}/>
                        </Grid>
                        <Grid item md={4} sm={12}>
                            <GlobalTextField
                                id={'adscripción'}
                                label={'Adscripción'}
                                icono={'suitcase'}
                                long={100}
                                name={'doc_Adscripcion'}
                                value={docente.doc_Adscripcion}
                                error={!!errores.doc_Adscripcion}
                                helperText={errores.doc_Adscripcion}
                                onChange={(e) => handleChange(e, docente, nuevoDocente)}/>
                        </Grid>
                        <Grid item md={5} sm={12}>
                            <GlobalTextField
                                id={'categoria'}
                                label={'Categoría'}
                                long={100}
                                name={'doc_Categoria'}
                                value={docente.doc_Categoria}
                                error={!!errores.doc_Categoria}
                                helperText={errores.doc_Categoria}
                                onChange={(e) => handleChange(e, docente, nuevoDocente)}/>
                        </Grid>

                        {/*Parte del formulario donde se vuelve general */}

                        <Grid item md={6} sm={12}>
                            <GlobalTextField
                                id={'nombre'}
                                label={'Nombre'}
                                icono={'user'}
                                name={'doc_Nombre'}
                                long={100}
                                value={docente.doc_Nombre}
                                error={!!errores.doc_Nombre}
                                helperText={errores.doc_Nombre}
                                onChange={(e) => handleChange(e, docente, nuevoDocente)}
                            />
                        </Grid>
                        <Grid item md={3} sm={12}>
                            <GlobalTextField
                                id={'apellidoPaterno'}
                                label={'Apellido paterno'}
                                name={'doc_ApellidoPaterno'}
                                long={50}
                                value={docente.doc_ApellidoPaterno}
                                error={!!errores.doc_ApellidoPaterno}
                                helperText={errores.doc_ApellidoPaterno}
                                onChange={(e) => handleChange(e, docente, nuevoDocente)}/>
                        </Grid>
                        <Grid item md={3} sm={12}>
                            <GlobalTextField
                                id={'apellidoMaterno'}
                                label={'Apellido materno'}
                                name={'doc_ApellidoMaterno'}
                                value={docente.doc_ApellidoMaterno}
                                error={!!errores.doc_ApellidoMaterno}
                                helperText={errores.doc_ApellidoMaterno}
                                long={50}
                                onChange={(e) => handleChange(e, docente, nuevoDocente)}/>
                        </Grid>

                        <Grid item md={6} sm={12}>
                            <GlobalTextField
                                id={'correo'}
                                label={'Correo'}
                                icono={'envelope'}
                                tipo={'email'}
                                name={'doc_Correo'}
                                value={docente.doc_Correo}
                                error={!!errores.doc_Correo}
                                helperText={errores.doc_Correo}
                                long={100}
                                onChange={(e) => handleChange(e, docente, nuevoDocente)}/>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <GlobalTextField
                                id={'telefono'}
                                label={'Teléfono'}
                                icono={'phone'}
                                tipo={'tel'}
                                name={'doc_Telefono'}
                                value={docente.doc_Telefono}
                                error={!!errores.doc_Telefono}
                                helperText={errores.doc_Telefono}
                                long={10}
                                onChange={(e) => {
                                    isInputNumber(e);
                                    handleChange(e, docente, nuevoDocente)
                                }}/>
                        </Grid>


                        <Grid item md={5} sm={12}>
                            <GlobalTextField
                                id={'calle'}
                                label={'Calle y número'}
                                icono={'home'}
                                name={'doc_CalleNumero'}
                                value={docente.doc_CalleNumero}
                                error={!!errores.doc_CalleNumero}
                                helperText={errores.doc_CalleNumero}
                                long={50}
                                onChange={(e) => handleChange(e, docente, nuevoDocente)}/>
                        </Grid>
                        <Grid item md={3} sm={12}>
                            <GlobalTextField
                                id={'colonia'}
                                label={'Colonia'}
                                tipo={'text'}
                                name={'doc_Colonia'}
                                value={docente.doc_Colonia}
                                error={!!errores.doc_Colonia}
                                helperText={errores.doc_Colonia}
                                long={50}
                                onChange={(e) => handleChange(e, docente, nuevoDocente)}/>
                        </Grid>
                        <Grid item md={2} sm={12}>
                            <GlobalTextField
                                id={'poblacion'}
                                label={'Población'}
                                tipo={'text'}
                                name={'poblacion'}
                                error={!!errores.doc_PoblacionCodigoPostal}
                                helperText={errores.doc_PoblacionCodigoPostal}
                                value={poblacionCodigoPostal.poblacion}
                                long={50}
                                onChange={(e) => handleChange(e, poblacionCodigoPostal, setPoblacionCodigoPostal)}/>
                        </Grid>
                        <Grid item md={2} sm={12}>
                            <GlobalTextField
                                id={'postal'}
                                label={'Código postal'}
                                name={'codigoPostal'}
                                error={!!errores.doc_PoblacionCodigoPostal}
                                helperText={errores.doc_PoblacionCodigoPostal}
                                value={poblacionCodigoPostal.codigoPostal}
                                long={5}
                                onChange={(e) => {
                                    isInputNumber(e);
                                    handleChange(e, poblacionCodigoPostal, setPoblacionCodigoPostal)
                                }}/>
                        </Grid>

                        {/* Archivos */}
                        <Grid item md={6} sm={12}>
                            <InputFile
                                id={'foto'}
                                icono={'camera'}
                                name={'doc_Foto'}
                                objeto={docente}
                                setObjeto={nuevoDocente}
                                accept={'image/jpeg'}
                                error={!!errores.est_Foto}
                                helperText={errores.est_Foto}
                            />
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <InputFile
                                id={'firma'}
                                icono={'feather'}
                                name={'doc_Firma'}
                                objeto={docente}
                                setObjeto={nuevoDocente}
                                accept={'image/jpeg'}
                                error={!!errores.est_Foto}
                                helperText={errores.est_Foto}
                            />
                        </Grid>

                        <Grid item>
                            <SubmitButton/>
                        </Grid>
                    </Grid>
                </Grid>
            </form>

        </Grid>
    );
};

export default Docentes;