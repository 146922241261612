import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import RadioTipos from "./RadioTipos";
import GlobalTextField from "../../GlobalesCredenciales/GlobalTextField";
import ComboCriterio from "./ComboCriterio";
import SolicitoDate from "./SolicitoDate";
import InputFile from "../../GlobalesCredenciales/InputFile";
import makeStyles from "@material-ui/core/styles/makeStyles";
import BotonRojo from "../../GlobalesCredenciales/BotonRojo";
import {getCriterios, handleSubmit} from "../../../Controllers/SolicitudesController";
import {handleChange} from "../../GlobalesCredenciales/GlobalTextField";
import {basicContainer, thinContainer} from '../../../helpers/Styles'
import ComboClientes from "./ComboClientes";
import {useAlert} from "react-alert";
import {fechaString} from "../../../helpers/Util";
import {ThemeProvider} from "@material-ui/styles";
import myTheme from "../../../helpers/Theme";


const useStyles = makeStyles({
    margins: {
        marginBottom: '5vh',
    }
});

const stateInicial = {
    sol_Tipo: 'Estudiantes',
    sol_Fecha: '',
    sol_Comentarios: '',
    sol_Cliente: '',
    lot_Criterio: '0',
    sol_Excel: null,
    sol_FyF: null,
}

const Nueva = () => {

    const classes = useStyles();

    const alert = useAlert();
    const [solicitud, nuevaSolicitud] = useState(stateInicial);
    const [criterios, agregarCriterios] = useState([]);
    const [mensaje, agregarMensaje] = useState('');
    const [errorMsg, agregarErrorMsg] = useState('')
    const [errores, agregarErrores] = useState({})
    const [fecha, setFecha] = useState(new Date());

    useEffect(() => {
        getCriterios(agregarCriterios, solicitud['sol_Cliente'], solicitud, nuevaSolicitud).then()
    }, [solicitud.sol_Cliente]);

    useEffect(() => {
        if (fecha === null)
            return;
        solicitud.sol_Fecha = fechaString(fecha);
    }, [fecha]);

    useEffect(() => {
        if (mensaje !== '')
            alert.success(mensaje);
    }, [mensaje]);

    useEffect(() => {
        if (errorMsg !== '')
            alert.error(errorMsg);
    }, [errorMsg]);

    return (
        <Grid
            container
            display='flex'
            justify='flex-start'
            alignItems='center'
            component='div'
            direction='column'
            style={basicContainer}
        >
            <Grid item>
                <h1 style={{textAlign: 'center', marginTop: '5vh'}}>Nueva solicitud</h1>
            </Grid>
            <Grid item>
                <form
                    style={{width: '800px'}}
                    onSubmit={(e => handleSubmit(e, solicitud, nuevaSolicitud, stateInicial, agregarMensaje, agregarErrores, agregarErrorMsg))}>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        spacing={2}
                    >
                        <ThemeProvider theme={myTheme}>
                            <Grid item xs={12} className={classes.margins}>
                                <ComboClientes
                                    state={solicitud}
                                    setState={nuevaSolicitud}
                                    name={'sol_Cliente'}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.margins}>
                                <ComboCriterio
                                    solicitud={solicitud}
                                    nuevaSolicitud={nuevaSolicitud}
                                    criterios={criterios}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.margins}>
                                <SolicitoDate
                                    fecha={fecha}
                                    setFecha={setFecha}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.margins}>
                                <RadioTipos
                                    state={solicitud}
                                    setState={nuevaSolicitud}
                                    name={'sol_Tipo'}
                                />
                            </Grid>


                            {/*archivos */}
                            <Grid item xs={12} className={classes.margins}>
                                <InputFile
                                    id={'excel'}
                                    icono={'file-excel'}
                                    name={'sol_Excel'}
                                    objeto={solicitud}
                                    setObjeto={nuevaSolicitud}
                                    accept={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
                                    error={!!errores.sol_Excel}
                                    helperText={errores.sol_Excel}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.margins}>
                                <InputFile
                                    id={'archivos'}
                                    icono={'file-archive'}
                                    name={'sol_FyF'}
                                    objeto={solicitud}
                                    setObjeto={nuevaSolicitud}
                                    accept={'application/x-zip-compressed'}
                                    error={!!errores.sol_FyF}
                                    helperText={errores.sol_FyF}
                                />
                            </Grid>


                            <Grid item xs={12} className={classes.margins}>
                                <GlobalTextField
                                    id={'comentarios'}
                                    label={'Comentarios'}
                                    name={'sol_Comentarios'}
                                    onChange={(e) => handleChange(e, solicitud, nuevaSolicitud)}
                                    variant={'outlined'}
                                    long={120}
                                    multiline
                                    rows={4}
                                />
                            </Grid>
                        </ThemeProvider>
                        <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                            <BotonRojo text={'Registrar'}/>
                        </Grid>
                    </Grid>
                </form>

            </Grid>
        </Grid>
    );
};

export default Nueva;