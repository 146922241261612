import React, {Fragment, useContext, useEffect} from 'react';
import globalContext from "../../Context/GlobalContext/globalContext";
import {useAlert} from "react-alert";
import {Switch, Route, useRouteMatch} from 'react-router-dom';
import VerificarResidentes from "./Components/Residentes/VerificarResidentes";
import RegistrarPrivadas from "./Components/Privadas/RegistrarPrivadas";
import VerPrivadas from "./Components/Privadas/VerPrivadas";
import {ThemeProvider} from "styled-components";
import Theme from "./Components/Theme";
import RegistrarResidentes from "./Components/Residentes/RegistrarResidentes";
import Presidente from "./Components/Presidente/Presidente";
import Navbar from '../Credenciales/Components/Navbar/Navbar';
import SideBar from '../Credenciales/Components/SideBar/SideBar';
import ListaFunciones from './Components/Sidebar/ListaFunciones';
import Tickets from './Components/Tickets/Tickets';
import Privadas from './Components/Privadas/Privadas'

const Acceso = (props) => {

    const context = useContext(globalContext);
    const {permiso, usuario} = context
    const {path} = useRouteMatch();
    const alert = useAlert();

    useEffect(() => {
        document.body.style.backgroundColor = 'whitesmoke';
        if (permiso) {
            alert.success('Bienvenido ' + usuario.UsNombre + '!');
            return;
        }
        props.history.push('/panel');
    }, [permiso]);

    return (
        <ThemeProvider theme={Theme}>
            <Fragment>
                <Navbar nombreSistema="Clientes"/>
                <SideBar>
                    <ListaFunciones/>
                </SideBar>
                <main style={{padding: '2rem' ,paddingTop:'10vh', paddingLeft: '5rem',minHeight: '100vh', height: 'auto'}}>

                    <Switch>
                        {/*Residentes*/}
                        <Route exact path={`${path}/residentes/verificar`} component={VerificarResidentes}/>
                        {/*Privadas*/}
                        <Route exact path={`${path}/privadas`} component={Privadas}/>
                        {/*Registrar presidentes*/}
                        <Route path={`${path}/registrar/presidente/:id`} component={RegistrarResidentes}/>
                        {/*Seccion de presidentes*/}
                        <Route path={`${path}/administrar/presidente`} component={Presidente} />
                        {/*Tickets */}
                        <Route exact path={`${path}/tickets/prioridad`} component={Tickets}/>
                    </Switch>
                </main>
            </Fragment>
        </ThemeProvider>

    );
};

export default Acceso;