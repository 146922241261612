import React from 'react';
import Box from "@material-ui/core/Box";

const h1Style = {
    margin: 0,
    color: 'rgb(251,251,251)',
    height: '100%',
    textTransform: 'uppercase'
};

const NombreSistema = ({nombreSistema}) => {
    return (
        <Box component='div'
             display="flex"
             justifyContent="center"
             alignItems='center'
             style={h1Style}
            >
            <h1>{nombreSistema}</h1>
        </Box>

    );
};

export default NombreSistema