import React, {useEffect} from 'react';
import Grid from "@material-ui/core/Grid";
import {Add, Remove} from "@material-ui/icons";
import {alterarErrores} from '../../Controllers/AreaTrabajoController'
import IconButton from "@material-ui/core/IconButton";

const BotonErrores = ({states, zona}) => {

    const {errores, setErrores} = states

    useEffect(() => {
        console.log(errores);
        alterarErrores(errores, zona).then()
    }, [errores])

    const disminuir = () => {
        if (errores === 0) return;
        setErrores(() => errores - 1);
    }

    const aumentar = () => setErrores(() => errores + 1);


    return (
        <Grid
            container
            direction='column'
            justify='center'
            alignItems='center'
        >
            <Grid item>
                <span style={{fontWeigth: 600, fontSize: '8pt'}}>Errores</span>
            </Grid>
            <Grid item>
                <Grid
                    container
                    direction='row'
                    justify='center'
                    alignItems='center'>
                    <Grid item>
                        <IconButton onClick={() => disminuir()}>
                            <Remove/>
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <h2>{errores}</h2>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={() => aumentar()}>
                            <Add/>
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    );
};

export default BotonErrores;