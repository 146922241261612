import styled from "styled-components";

export const FiltroLabel = styled.p`
  text-align: left;
  font-size: 1.2rem;
  font-weight: bold;
`

export const ContenedorWidthFixed = styled.div`
  width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Contenedor = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const FiltroDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const Wrapper = styled.div`
display: flex;
  flex-direction: column;
  justify-content: center;
width: 100%;
align-items: center;
`

export const Titulo = styled.h2`
  text-align: center;
  width: 100%;
`
