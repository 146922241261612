import React from 'react';
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/styles";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import Grid from "@material-ui/core/Grid";
import {createMuiTheme, TableRow} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import StyledTableCell from "../../../Credenciales/Components/GlobalesCredenciales/StyledTableCell";
import StyledRow from "../../../Credenciales/Components/GlobalesCredenciales/StyledRow";
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import green from "@material-ui/core/colors/green";
import {verificarResidenteV2} from "../../Controllers/ResidentesController";
import CheckIcon from "@material-ui/icons/Check";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

const ThemePortal = createMuiTheme({
    palette: {
        primary: {
            main: '#c42e2e',
            light: '#868686',
            dark: '#242424',
            contrastText: '#FFF'
        }
    }
})


const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    paper: {
        
        // maxWidth: 720,
    }
});

const VerificarResidentes = ({idPrivada, residentesPrivada, setResidentesPrivada, setMensaje}) => {

    const classes = useStyles();


    const Fila = ({row}) => {

        return (
            <StyledRow>
                <StyledTableCell scope="center">{row['NumCasa']}</StyledTableCell>
                <StyledTableCell align="center">{row['UsNombre']}</StyledTableCell>
                <StyledTableCell align="center">{row['UsApPat']}</StyledTableCell>
                <StyledTableCell align="center">{row['UsApMat']}</StyledTableCell>
                <StyledTableCell align="center">{row['UsCorreo']}</StyledTableCell>
                <StyledTableCell align="center">{row['UsTelefono']}</StyledTableCell>
                <StyledTableCell align="center">{row['EsRentador']}</StyledTableCell>
                <StyledTableCell align="center">
                    {
                        row['UsVerificado'] !== 'Sin verificar' ?
                            <Tooltip title="Residente verificado">
                                <VerifiedUserIcon style={{color: green[500]}}/>
                            </Tooltip>
                            :
                            <Tooltip title="Verificar residente">
                                <IconButton
                                    style={{padding: 0, margin: 0}}
                                    onClick={() => verificarResidenteV2(row['Us_ID'], idPrivada, setResidentesPrivada, setMensaje)}>
                                    <CheckIcon/>
                                </IconButton>
                            </Tooltip>
                    }
                </StyledTableCell>
            </StyledRow>
        )
    }

    return (
        <Paper style={{width: '100%', maxHeigth: '80%'}}>
            <ThemeProvider theme={ThemePortal}>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="stretch"
                    className={classes.root}
                >
                    <Grid item xs={12}>
                        <TableContainer>
                            <Table stickyHeader aria-label={"Verificar residentes"}>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Num. Casa</StyledTableCell>
                                        <StyledTableCell align="center">Nombre</StyledTableCell>
                                        <StyledTableCell align="center">Apellido paterno</StyledTableCell>
                                        <StyledTableCell align="center">Apellido materno</StyledTableCell>
                                        <StyledTableCell align="center">Correo</StyledTableCell>
                                        <StyledTableCell align="center">Teléfono</StyledTableCell>
                                        <StyledTableCell align="center">Rentador</StyledTableCell>
                                        <StyledTableCell align="center">Verificado</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {residentesPrivada.map((row, index) => <Fila key={index} row={row}/>)}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </ThemeProvider>
        </Paper>
    );
};

export default VerificarResidentes;
