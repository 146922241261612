import React from 'react';
import Grid from "@material-ui/core/Grid";
import {basicContainer, tituloStyle} from "../../helpers/Styles";
import Formulario from "./Formulario";

const Pendientes = () => {

    return (
        <Grid
            container
            display='flex'
            justify='flex-start'
            component='div'
            direction='column'
            style={basicContainer}
        >
            <Grid item >
                <h1 style={tituloStyle}>{'Fotos y firmas pendientes'}</h1>
            </Grid>
            <Grid item>
                <Formulario/>
            </Grid>
        </Grid>
    );
};

export default Pendientes;