import React, {useEffect, useState} from 'react';
import clienteAxios from "../../../../../config/axios";
import {CircularProgress} from "@material-ui/core";
import PendientesTabla from "./PendientesTabla";
import Grid from "@material-ui/core/Grid";

const Pendientes = ({pendientes, setPendientes, contador}) => {


    useEffect(() => {
        const fetchData = async () => {
            clienteAxios.get('api/credenciales/lotespendientes')
                .then(await (({data})=> {
                    data.forEach(item => item.Checked = false)  //Se agrega propiedad Checked para control de Checkbox
                    setPendientes(data);
                }))
                .catch( await (() => setPendientes(null)));
        }
        fetchData().then()
    }, []);


    const mostrarTabla = () => {
        if (pendientes === null) {
            return <CircularProgress color={"secondary"}/>
        } else if (pendientes.length === 0) {
            return <h3>No hay datos disponibles para mostrar</h3>
        } else {
            return <PendientesTabla pendientes={pendientes} contador={contador}/>
        }
    }

    return (
        <Grid container justify={'center'} style={{minHeight: '60vh'}}>
            {
                mostrarTabla()
            }
        </Grid>
    );
};


export default Pendientes;