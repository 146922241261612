import clienteAxios from "../../../config/axios";

export const submitEntrega = async (entrega, setMensaje, tipo) => {

    delete entrega['Fecha enviada']
    delete entrega['Numero de guia']
    delete entrega['Proveedor']

    let values = Object.values(entrega);
    let apiURL = '';
    
    if (values.includes(null) || values.includes('')) {
        setMensaje("")
        setMensaje({msg: "Campos incompletos", tipo: 'error'})
        return;
    }

    if (tipo === 'local')
        apiURL = `api/credenciales/entregas/${entrega['Folio']}`
    else
        apiURL = `api/credenciales/entregasexternas/${entrega['Folio']}`

   
    async function postCrearZip(){
        return clienteAxios.post('api/credenciales/entregas', entrega)
            .catch(()=> {
                setMensaje('');
                setMensaje({msg: "Entrega finalizada con éxito", tipo: 'success'})
            })
    }

    async function descargarZip(){
        clienteAxios({
            url: 'api/credenciales/descargarRegistro',
            method: 'GET',
            responseType: 'blob'
        })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download','Entrega.zip');
                document.body.appendChild(link);
                link.click();
            });
    }
    
    await postCrearZip();
    await descargarZip();

    // clienteAxios.put(apiURL, entrega)
    //     .then(() => {
    //         setMensaje({msg: "Entrega finalizada con éxito", tipo: 'success'});
    //     })
    //     .catch((() => {
    //         setMensaje({msg: "Hubo un error al finalizar entrega", tipo: 'error'});
    //     })



}

