import React from 'react';
import {TableContainer, TableRow} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import StyledTableCell from "../../GlobalesCredenciales/StyledTableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import StyledRow from "../../GlobalesCredenciales/StyledRow";
import TextField, {handleChange} from "./TextField";

const CredencialesTabla = ({row, state, setState}) => {

    const {Credenciales} = row;
    const arreglo = Object.values(Credenciales);

    return (
        <Paper style={{minWidth: '100%'}}>
            <TableContainer style={{minHeight: 'auto', maxHeight: '80vh'}}>
                <Table stickyHeader aria-label="Lotes listos">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>ID</StyledTableCell>
                            <StyledTableCell align="center">Código interno</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {arreglo.map((item,index) => {
                            return(
                                <StyledRow key={index}>
                                    <StyledTableCell align='rigth'>{item}</StyledTableCell>
                                    <StyledTableCell align='center'>
                                        <TextField
                                            id={item}
                                            name={item}
                                            value={state[`${item}`]}
                                            onChange={e => handleChange(e, state, setState) }/>
                                    </StyledTableCell>
                                </StyledRow>
                            )
                        })}
                    </TableBody>
                </Table>

            </TableContainer>

        </Paper>
    );
};

export default CredencialesTabla;