import React, {useContext, useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {Grid} from '@material-ui/core';
import {makeStyles} from "@material-ui/styles";
import myTheme from "../../../../../helpers/Theme";
import RedButton from "../../../../GlobalesCredenciales/RedButton";
import {agregarAction, modificarAction} from "../../../../../Controllers/ModificarController";
import {useAlert} from "react-alert";
import globalContext from "../../../../../../../Context/GlobalContext/globalContext";

const useStyles = makeStyles({
    root: {
        maxWidth: '400px',
    },
    label: {
        color: myTheme.palette.secondary.dark,
        fontSize: '1.5rem',
        fontWeight: "bold",
        paddingRight: '5px',
    },
    text: {
        color: '#111',
        fontSize: '1.5rem',
        fontWeight: "bold",
        paddingLeft: '5px'
    },
    p: {
        fontSize: '0.8rem',
        textAlign: 'center',
        color: myTheme.palette.secondary.dark,
        padding: 0,
    },
});


const ModificarDialog = ({informacion, setPersona, stateInicial, setActivo, clienteID,alert}) => {

    const classes = useStyles();

    const [mensaje, setMensaje] = useState('');
    const [open, setOpen] = useState(true)
    const info = {
        id: informacion.id,
        nombre: informacion.nombre,
        apellidos: informacion.apellidos,
    }

    useEffect(() => {
        if (mensaje !== '')
            alert.success(mensaje);
    }, [mensaje]);

    const handleClose = () => {
        setOpen(false)
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth={'sm'}
        >
            <Grid
                container
                component='div'
                direction='row'
                justify='center'
                alignItems='center'
                className={classes.root}
            >
                <Grid item xs={12}>
                    <p className={classes.p}>Ya existe en la base de datos</p>
                </Grid>
                <Grid item xs={5}>
                    <Grid container component='div' className={classes.label} direction="column" justify="center"
                          alignItems="flex-end">
                        <LabelText text={'ID'}/>
                        <LabelText text={'Nombre'}/>
                        <LabelText text={'Apellidos'}/>
                    </Grid>
                </Grid>
                <Grid item xs={7}>
                    <Grid container component='div' className={classes.text} direction="column" justify="center"
                          alignItems="flex-start">
                        <LabelText text={info.id}/>
                        <LabelText text={info.nombre}/>
                        <LabelText text={info.apellidos}/>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container component='div' direction='row' justify='center' alignItems='center'>
                        <Grid item>
                            <RedButton
                                icono={'plus-square'}
                                text={'Agregar'}
                                onClick={(e) => agregarAction(e,clienteID, setOpen, info.id, setMensaje, setPersona, stateInicial)}
                            />
                        </Grid>
                        <Grid item>
                            <RedButton
                                icono={'edit'}
                                text={'Modificar'}
                                onClick={(e) => modificarAction(e, setOpen, setActivo)}/>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </Dialog>
    );
};

const LabelText = ({text}) => {
    return (
        <Grid item style={{paddingTop: '1rem'}}>
            {text}
        </Grid>
    )
}

export default ModificarDialog;