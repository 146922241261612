import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";
import Tab from "@material-ui/core/Tab";
import myTheme from "../../helpers/Theme";

const StyledTab = withStyles((theme) => ({
    root: {
        borderRadius: '5px',
        textTransform: 'none',
        minWidth: 72,
        fontSize: '1.2rem',
        marginRight: theme.spacing(4),
        '&:hover': {
            color: '#222',
            backgroundColor: myTheme.palette.secondary.light,
            opacity: 1,
        },
        '&$selected': {
            color: myTheme.palette.primary.main,
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: myTheme.palette.primary.main,
        },
    },
}))
(
    props => <Tab disableRipple {...props}/>
);

export default StyledTab