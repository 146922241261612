import clienteAxios from "../../../config/axios"

export const getTicketsPrioridad = async (setTicketsPrioridad) => {
    const response = await clienteAxios.get('api/tickets/ticketsXPrioridad')
    setTicketsPrioridad(response.data)
}

export const exportarExcelTickets = () => {
    clienteAxios({
        url: 'api/tickets/ticketsXPrioridad/exportarExcel',
        method: 'GET',
        responseType: 'blob'
    })
        .then( response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Tickes_de_prioridad.xlsx');
            document.body.appendChild(link);
            link.click();
        })
}

export const getTecnicos = async (setTecnicos) => {
    const response = await clienteAxios.get('api/usuarios/tecnicos');
    setTecnicos(response.data);
}

export const asignarTecnico = async (folio, IdTecnico, setMensaje, setTickets) => {

    const body = {
        folio : folio,
        tecnico: IdTecnico
    }

    await clienteAxios
        .put(`api/tickets/asignarTecnico`, body)
        .then((response) => {
            setMensaje({
                error: false,
                msg: "Se ha asignado el técnico al ticket exitosamente",
            });
        })
        .catch((error) => {

            if(error.response.status === 401){
                setMensaje({
                    error: true,
                    msg: 'No tiene permisos para realizar esta acción'
                })
            }else{
                 setMensaje({
                     error: true,
                     msg:
                         "Ha ocurrido un error al asignar técnico, itente de nuevo",
                 });
            }  
        });


        await getTicketsPrioridad(setTickets);
}

export const cerrarTicket = async (folio, setMensaje, setTickets) => {
    await clienteAxios
        .put(`api/tickets/cerrar/${folio}`)
        .then((response) => {
            setMensaje({
                error: false,
                msg: "Se ha cerrado el ticket exitosamente",
            });
        })
        .catch((error) =>{

            if(error.response.status === 401){
                setMensaje({
                    error: true,
                    msg: 'No tiene permisos para realizar esta acción'
                })
            }else {
                setMensaje({
                    error: true,
                    msg:
                        "Ha ocurrido un error al cerrar ticket, itente más tarde",
                });
            }
        });

    await getTicketsPrioridad(setTickets);
};