import React from 'react';
import {basicContainer} from "../../../helpers/Styles";
import Grid from "@material-ui/core/Grid";
import TabHistorial from "./TabHistorial";

const EntregasHistorial = () => {

    return (
        <Grid
            container
            display='flex'
            justify='flex-start'
            alignItems='center'
            component='div'
            direction='column'
            style={basicContainer}
        >
            <Grid item>
                <h1 style={{textAlign: 'center', marginTop: '5vh'}}>Historial de entregas</h1>
            </Grid>
            <Grid item style={{width: '100%'}}>
                <TabHistorial/>
            </Grid>
        </Grid>

    );
};

export default EntregasHistorial;