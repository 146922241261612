import clienteAxios from "../../../config/axios";
import {objectToFormData} from "object-to-formdata";

export const subirFotosPendientes = async (e,archivo, setMensaje, stateInicial, setArchivo) => {

    e.preventDefault();
    setMensaje('');
    const formData = objectToFormData(archivo);
    const zip = formData.get('FyF[]');
    formData.append('FyF', zip);
    formData.delete('FyF[]')

    clienteAxios.post('api/credenciales/subirFotos', formData)
        .then(await (({data})=> {
            setMensaje({msg: "Se ha subido con éxito el archivo", error: false});
            setArchivo(stateInicial);
        }))
        .catch(await ((error) => {
            setMensaje({msg: "Error al subir el archivo", error: true});
    }))
}