import clienteAxios from "../../../config/axios";
import AlertaDialog from "../Components/Lotes/AlertaDialog";
import React from "react";
import {renderIntoDocument} from "react-dom/test-utils";

export const generarEntrega = async (listos, setMensaje, setListos) => {

    const trueArray = listos.filter(row => row.Checked);
    let solicitudes = [], lotesId = [];


    trueArray.map(row => {
        solicitudes.push(row.Solicitud);
        lotesId.push(row.ID)
    });

    solicitudes = new Set(solicitudes);
    setMensaje({id: '', msg: ''})

    if(solicitudes.size === 0){
        setMensaje({
            id: 2,
            msg: 'Seleccione un lote mínimo para generar una entrega'
        })
    }

    if (solicitudes.size > 1) {
        setMensaje({
            id: 2,
            msg: 'Las entregas no pueden ser de más de una solicitud'
        });
        return;
    }
    console.log(lotesId);

    clienteAxios.post('api/credenciales/entregas', {ent_lotes: lotesId})
        .then(await (({data}) => setMensaje({msg: data['Mensaje'], id: 1})))

    // despues de generar entrega, actualizar los lotes listos
    clienteAxios.get('api/credenciales/loteslistos')
        .then(await (({data}) => setListos(data)))

}

export const abrirAreaTrabajo = async (count, datos, activarAreaTrabajo, Redireccionar, sets) => {

    const {setLotes, setMensaje} = sets

    if (count > 4) {
        setMensaje({msg: 'El máximo son 4 lotes', id: 2});
        return;
    } else if (count <= 0) {
        setMensaje({msg: 'Seleccione un lote mínimo', id: 2});
        return;
    }

    //Se puede optimizar
    const checkLote = row => row.Checked ? lotes.push(row.ID) : 0;
    let lotes = [];
    datos.map(row => checkLote(row));
    setLotes(lotes);


    async function postCrearZip(){
        return clienteAxios.post('api/credenciales/credenciales',{cre_lotes: lotes})
            .catch(error => {
                const {data} = error.response;
                const alertas = data['Alertas'];
                const errores = data['Errores'];
                const titulo = data['Mensaje'];
                renderIntoDocument(
                    <AlertaDialog titulo={titulo} alertas={alertas} errores={errores}/>
                )
                return null
            });
    }


    async function descargarZip(){
        clienteAxios({
            url: 'api/credenciales/descargarMaterial',
            method: 'GET',
            responseType: 'blob',
        })
            .then(response => {
                Redireccionar();
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Lote.zip');
                document.body.appendChild(link);
                link.click();
            });
    }

    await postCrearZip();
    await descargarZip();

}



