import React, {Fragment, useContext, useEffect} from "react";
import {Switch, Route} from 'react-router-dom';
import SideBar from "./Components/SideBar/SideBar";
import Navbar from "./Components/Navbar/Navbar";
import Nueva from './Components/Solicitudes/Nueva/Nueva'
import Historial from './Components/Solicitudes/Historial/Historial'
import Cerrar from "./Components/Registrar/Cerrar/Cerrar";
import globalContext from "../../Context/GlobalContext/globalContext";
import {useAlert} from "react-alert";
import Lotes from "./Components/Lotes/Lotes";
import EntregasHistorial from "./Components/Entregas/Historial/EntregasHistorial";
import EntregasPendientes from "./Components/Entregas/Pendientes/EntregasPendientes";
import AreaTrabajo from "./Components/TrabajoArea/AreaTrabajo";
import Pendientes from "./Components/Pendientes/Pendientes";
import UAdO from "./Components/Registrar/Clientes/UAdO/UI/UAdO";
import ListaFunciones from "./Components/SideBar/ListaFunciones";


const Credenciales = (props) => {

    const context = useContext(globalContext);
    const {permiso, usuario, obtenerClientes, area} = context;
    const alert = useAlert();

    useEffect(() => {
        document.body.style.backgroundColor = 'whitesmoke';
        if (permiso) {
            alert.success('Bienvenido ' + usuario.UsNombre + '!');
            obtenerClientes();
            return;
        }
        props.history.push('/panel');
    }, [permiso]);



    return (
        <Fragment>
            <Navbar nombreSistema='Credenciales'/>
            {!area ? <SideBar children={<ListaFunciones/>}/> : null}
            <main>
                <Switch>
                    {/*Campaña*/}
                    <Route exact path='/credenciales/UAdO Culiacan' component={Culiacan}/>
                    <Route exact path='/credenciales/UAdO Los Mochis' component={Mochis}/>
                    <Route exact path='/credenciales/UAdO Guasave' component={Guasave}/>
                    <Route exact path='/credenciales/UAdO Sinaloa de Leyva' component={Sinaloa}/>
                    <Route exact path='/credenciales/cerrar' component={Cerrar}/>
                    {/*Solicitudes*/}
                    <Route exact path='/credenciales/solicitudes/nueva' component={Nueva}/>
                    <Route exact path='/credenciales/solicitudes/historial' component={Historial}/>
                    {/*Lotes */}
                    <Route exact path='/credenciales/lotes' component={Lotes}/>
                    {/*Entregas */}
                    <Route exact path='/credenciales/entregas/pendientes' component={EntregasPendientes}/>
                    <Route exact path='/credenciales/entregas/historial' component={EntregasHistorial}/>
                    {/*Area de trabajo */}
                    <Route exact path='/credenciales/AreaTrabajo' component={AreaTrabajo}/>
                    {/*Fotos y firmas pendientes*/}
                    <Route exact path='/credenciales/FotosFirmasPendientes' component={Pendientes}/>
                </Switch>
            </main>

        </Fragment>
    );
};

const Culiacan = () => <UAdO title={'Culiacán'}/>
const Mochis   = () => <UAdO title={'Los Mochis'}/>
const Guasave  = () => <UAdO title={'Guasave'}/>
const Sinaloa  = () => <UAdO title={'Sinaloa de Leyva'}/>
export default Credenciales;