import withStyles from "@material-ui/core/styles/withStyles";
import Theme from "../Theme";
import Tabs from "@material-ui/core/Tabs";

const StyledTabs = withStyles({
    root: {
    },
    indicator: {
        backgroundColor: Theme.primary,
    },
})(Tabs);


export default StyledTabs