import {
    INICIAR_SESION,
    CERRAR_SESION,
    OBTENER_USUARIO,
    INICIO_FALLIDO,
    PERMISO_OTORGADO,
    PERMISO_DENEGADO,
    REGRESAR_PANEL,
    OBTENER_CLIENTES,
    SELECCIONAR_CLIENTE,
    AREA_TRABAJO,
    LOTES,
} from '../Types';

export default (state,action) =>
{
    switch (action.type)
    {
        case INICIAR_SESION:
            localStorage.setItem('token',action.payload.token);
            return {
                ...state,
                token : localStorage.getItem('token'),
                session: true,
                mensaje: null
            };
        case INICIO_FALLIDO:
            return {
                ...state,
                mensaje: action.payload
            };
        case OBTENER_USUARIO:
            return{
                ...state,
                usuario: action.payload
            };
        case CERRAR_SESION:
            localStorage.setItem('token','');
            return  {
                ...state,
                token: localStorage.removeItem('token')
            };
        case PERMISO_OTORGADO:
            return {
                ...state,
                permiso : true,
                sistema : action.payload
            };
        case PERMISO_DENEGADO:
            return {
                ...state,
                permiso : false,
                mensaje: 'No tiene los permisos para acceder al sistema.'
            };
        case REGRESAR_PANEL:
            return {
                ...state,
                permiso : false
            };
        case OBTENER_CLIENTES:
            return{
                ...state,
                clientes: action.payload
            };
        case SELECCIONAR_CLIENTE:
            return{
                ...state,
                cliente: action.payload
            };
        case AREA_TRABAJO:
            return{
                ...state,
                area: action.payload
            };
        case LOTES:
            return{
                ...state,
                lotes: action.payload
            };
        default:
            return {};
    }
}