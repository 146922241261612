import React, { useEffect, useState} from 'react';
import TablaVerificar from "./TablaVerificar";
import {useAlert} from "react-alert";
import {indexPrivadas} from "../../Controllers/RelaysController";
import {StyledLinearProgress} from "../AccesoComponents";
import {Contenedor, FiltroDiv, FiltroLabel, Titulo, Wrapper} from "../Layout/FiltrarElements";
import {DialogPrivadas, FilterButton} from "../AccesoComponents";
import {indexResidentes} from "../../Controllers/ResidentesController";


const VerificarResidentes = () => {

    const alert = useAlert();
    const [residentes, setResidentes] = useState(null);
    const [mensaje, setMensaje] = useState('');
    const [privadas, setPrivadas] = useState(null);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        if (mensaje === '') return;
        if (!mensaje.error)
            alert.success(mensaje.msg);
        else
            alert.error(mensaje.msg);
    }, [mensaje]);

    useEffect(() => {
        async function fetchData (){
            const responseResidentes = await indexResidentes();
            setResidentes(responseResidentes.data);
            const responsePrivadas = await indexPrivadas();
            setPrivadas(responsePrivadas.data);
            setLoading(false)
        }
        fetchData().then()
    }, []);


    return (
        <div>
            {
                isLoading
                ?
                    <StyledLinearProgress/>
                    :
                    <Contenido
                        residentes={residentes}
                        privadas={privadas}
                        setMensaje={setMensaje}
                        setResidentes={setResidentes}
                    />
            }
        </div>

    );
};


const Contenido = ({residentes, privadas, setMensaje, setResidentes}) => {

    const [open, setOpen] = useState(false);
    const [infoTabla, setInfo] = useState([]);
    const [filtro, setFiltro] = useState('Sin filtro');

    function filtrarResidentes() {
        if(filtro === 'Sin filtro') {
            setInfo(residentes)
            return;
        }
        setInfo(residentes.filter(item => item['UsPrivada'] === filtro))
    }

    useEffect(() => {
        setInfo(residentes);
    }, [residentes])

    useEffect(()=> {
        filtrarResidentes();
    }, [filtro]);

    return(
        <Wrapper>
            <Titulo>Residentes</Titulo>
            <Contenedor>
                <FiltroDiv>
                    <FiltroLabel>
                        {filtro}
                    </FiltroLabel>
                    <FilterButton onClick={()=> setOpen(true)}/>
                    <DialogPrivadas setOpen={setOpen} contenido={privadas} open={open} setFiltro={setFiltro}/>
                </FiltroDiv>
                <div>
                    <TablaVerificar
                        residentes={residentes}
                        setResidentes={setResidentes}
                        setMensaje={setMensaje}
                        infoTabla={infoTabla}
                    />
                </div>
            </Contenedor>
        </Wrapper>
    )
}

export default VerificarResidentes;