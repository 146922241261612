import React from 'react';
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import myTheme from "../../helpers/Theme";
import withStyles from "@material-ui/core/styles/withStyles";

const MyButton = withStyles({
    root: {
        backgroundColor: myTheme.palette.primary.main,
        padding: '5px',
        boxShadow: '1px 1px 10px 0px rgba(0,0,0,0.4)',
        '&:hover': {
            backgroundColor: myTheme.palette.primary.dark,
        }
    },

})(props => (
        <Button
            type="submit"
            {...props}
        />
    )
);

const BotonRojo = ({icono, text, onClick}) => {

    return (
        <MyButton
            onClick={onClick}
        >
            {
                icono === undefined ? null :
                    <Icon className={`fas fa-${icono}`} style={{color: '#fff', fontSize: '0.8rem'}}/>
            }
            <span style={{marginLeft: '5px', color: '#fff'}}>{text}</span>
        </MyButton>
    );
};

export default BotonRojo;
