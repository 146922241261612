import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {FormControlLabel, RadioGroup} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import {handleChange} from "../../../../../Controllers/FormulariosController";


const RadiosEmpleados = ({empleado, nuevoEmpleado}) => {





    return (
        <Grid item xs={12}>
            <RadioGroup aria-label={'tipo'}
                        name={'emp_Tipo'}
                        value={empleado['emp_Tipo']}
                        onChange={e => handleChange(e,empleado,nuevoEmpleado)}
            >
                <Grid container direction="row" justify="space-evenly" alignItems='center'>
                    <Grid item>
                        <FormControlLabel
                            value={'1'}
                            control={<Radio/>}
                            label={'Administrativos'}/>
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            value={'2'}
                            control={<Radio/>}
                            label={'Rectoría'}/>
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            value={'3'}
                            control={<Radio/>}
                            label={'General'}/>
                    </Grid>
                </Grid>

            </RadioGroup>
        </Grid>
    );
};

export default RadiosEmpleados;