import React, {useEffect, useState} from "react";
import {UsuariosPermisosController as Controller} from "../../Controllers/UsuariosPermisosController";
import ReactSwitch from "react-switch";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import {useAlert} from "react-alert";


const margen = {
    marginTop : '1vh'
}
const UsuariosPermisos = () =>
{
    const [usuario, elegirUsuario] = useState(0);
    const [usuarios,agregarUsuarios] = useState([]);
    const [sistemas,agregarSistemas] = useState([]);
    const [permisosUsuario,agregarPermisos] = useState([]);
    const [mensaje,agregarMensaje] = useState({});
    const [sisCheck , cambiarSisCheck] = useState({});
    const [expanded,changeExpanded] = useState(0);
    const {
            obtenerUsuarios,
            simpleHandleChange,
            registrarPermisos,
            handleCheck,
            handleClick,
            handleCheckSis
    } = new Controller(
        elegirUsuario,
        agregarUsuarios,
        agregarSistemas,
        agregarPermisos,
        agregarMensaje,
        cambiarSisCheck,
        permisosUsuario
    );

    const alert = useAlert();
    const checar = (id) =>permisosUsuario.filter(({permiso})=>permiso===id).length!==0;
    const checarSys = (id) => sisCheck[id];

    useEffect(()=>{
        obtenerUsuarios()
    },[]);
    useEffect(()=>{
        if(mensaje.mensaje)
            if(mensaje.estatus===200)
                alert.success(mensaje.mensaje)
            else
                alert.error(mensaje.mensaje)
    },[mensaje])
    const handleExpand = panel => (event, isExpanded) => {
        changeExpanded(isExpanded ? panel : 0);
    };
    return(
        <div className='containter-flex'>
            <div className={'row justify-content-center'}>
                <div className={'col-4'}>
                    <h1 style={{fontFamily : 'impact',fontSize : '3.0rem', color : 'grey'}}>OTORGAR PERMISOS</h1>
                </div>
            </div>
            <div className={'row justify-content-center'} style={{fontFamily : 'impact'}}>
                <div className={'col-10 row justify-content-center'} >
                    <div className='col-8'>
                        <FormControl fullWidth >
                            <InputLabel>Elegir usuario</InputLabel>
                            <Select
                                name='UsNombre'
                                onChange={(e)=> simpleHandleChange(e)}
                                value={usuario.UsTipo}
                            >
                                <MenuItem>Seleccione</MenuItem>
                                {usuarios.map(usuario=>
                                    <MenuItem key={usuario.Us_ID} value={usuario.Us_ID}>{usuario.UsNombre}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </div>
                    <div className='row col-8 justify-content-center' style={{margin : '3vh 0 3vh 0'}}>
                        <Button variant='contained'
                                color='secondary'
                                className='col-5'
                                onClick={()=>handleClick(usuario)}
                                style={{marginRight : '3vh'}}
                        >Elegir</Button>
                        <Button variant='contained'
                                color='secondary'
                                className='col-5'
                                onClick={()=>registrarPermisos(permisosUsuario,usuario)}
                        >Guardar</Button>
                    </div>
                </div>
                <div style={{marginBottom : '5vh'}}className='col-8'>
                    {
                        sistemas.map(
                            ({SisId,SisNombre,permisos}) =>
                                (
                                <ExpansionPanel
                                    key={SisId}
                                    aria-label="Expand"
                                    aria-controls="additional-actions1-content"
                                    expanded={expanded===SisId}
                                    onChange={handleExpand(SisId)}
                                >
                                    <ExpansionPanelSummary
                                        expandIcon={<FontAwesomeIcon icon={faChevronDown}/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <div className='container-fluid'>
                                            <label
                                                className='row justify-content-between'
                                                onClick={event => event.stopPropagation()}
                                                onFocus={event => event.stopPropagation()}
                                            >
                                                    <span id={'id'+SisId} style={{fontSize : '2rem'}}>{SisNombre}</span>
                                                    <ReactSwitch
                                                        onChange={(e)=>
                                                            handleCheckSis(e,SisId,sisCheck,usuario,sistemas)
                                                        }
                                                        checked={checarSys(SisId)}
                                                        className="react-switch mr-5 mt-3"
                                                        aria-labelledby={'id'+SisId}
                                                    />
                                            </label>
                                        </div>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails >
                                        <div className='container-fluid'>
                                            {permisos.map(({PermID,PermNombre,check})=>
                                                (
                                                    <label  style={margen} key={PermID} className='row justify-content-between'>
                                                        <span id={'id'+PermID} >{PermNombre}</span>
                                                        <ReactSwitch
                                                            onChange={(e)=>
                                                            {
                                                                handleCheck(e,PermID,SisId,permisosUsuario,sisCheck)
                                                                }
                                                            }
                                                            checked={checar(PermID)}
                                                            className="react-switch"
                                                            aria-labelledby={'id'+PermID}
                                                        />
                                                    </label>
                                                )
                                            )}
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            )
                        )
                    }

                </div>
            </div>
        </div>
    );
};

export default UsuariosPermisos;