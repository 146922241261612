import React from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

const SubmitButton = () => {
    return (

        <Grid item xs={12} display='flex'>
            <Button style={{color: "white" ,backgroundColor: "rgb(231,74,59", marginTop: '5vh'}}
                    type="submit"
                    name="action">
                Registrar
            </Button>
        </Grid>
    );
};

export default SubmitButton;