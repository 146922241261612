import React, {useEffect, useState,Fragment} from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {useAlert} from "react-alert";

import ConfirmDialog from "../ConfirmDialog";
import {UsuariosVerController as Controller} from '../../Controllers/UsuariosVerController'
import UsuariosEdit from "./UsuariosEdit";

const UsuariosVer = () =>
{
    const [usuarios,agregarUsuarios]    = useState([]);
    const [mensaje,agregarMensaje]      = useState('');
    const [abierto,abrir]               = useState(false);
    const [usuario,selectUsuario]       = useState(0);
    const [editar,activarEditar]        = useState(false);
    const alert = useAlert();

    const {
        obtenerUsuarios,
        handleEdit,
        abrirDialogo,
        cerrarDialogo,
        confirmarDialogo
    } = new Controller (
        usuarios,
        usuario,
        editar,
        agregarUsuarios,
        agregarMensaje,
        abrir,
        selectUsuario,
        activarEditar
    );


    useEffect(()=>{
        if(usuario===0 || !editar)
            obtenerUsuarios();

    },[usuario,editar])

    useEffect(()=>{
            if(mensaje!=='')
                alert.success(mensaje);
    },[mensaje])


    return(
        <Fragment>
            <UsuariosEdit
                user={usuario}
                mostrar={editar}
                activarEditar={activarEditar}
            />
            <div hidden={editar}>
            <ConfirmDialog
                abierto={abierto}
                cerrar={() => cerrarDialogo()}
                confirmar={() => confirmarDialogo()}
                titulo={'¿Está seguro?'}
                descripcion={'¿Realmente desea dar de baja a este usuario?' +
                             ', este proceso no puede ser revertido.'}
            />
            <div className={'container-flex'}>
                <div className={'row'}>
                    <div className={'offset-1 col-3'}>
                        <h1 style={{fontFamily : 'impact',fontSize : '3.0rem', color : 'grey'}}>USUARIOS</h1>
                    </div>
                </div>
                <div className='row justify-content-center' style={{fontFamily : 'impact'}}>
                    <TableContainer className={'col-10'} component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{fontFamily : 'impact',fontSize : '1.5rem'}}>NOMBRE</TableCell>
                                    <TableCell align="center" style={{fontFamily : 'impact',fontSize : '1.5rem'}}>APELLIDOS</TableCell>
                                    <TableCell align="center" style={{fontFamily : 'impact',fontSize : '1.5rem'}}>TIPO</TableCell>
                                    <TableCell align="center" style={{fontFamily : 'impact',fontSize : '1.5rem'}}>CORREO</TableCell>
                                    <TableCell align="center" style={{fontFamily : 'impact',fontSize : '1.5rem'}}>FECHA DE NACIMIENTO</TableCell>
                                    <TableCell align="center" style={{fontFamily: 'impact', fontSize: '1.5rem'}}/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {usuarios.map(({id,Nombre,ApPat,ApMat,Tipo,correo,FechaNac}) => (
                                    <TableRow key={id}>
                                        <TableCell component="th" style={{fontFamily : 'Franklin Gothic Medium',fontSize : '1.1rem',color : '#757678'}} scope="row">
                                            {Nombre}
                                        </TableCell>
                                        <TableCell align="center" style={{fontFamily : 'Franklin Gothic Medium',fontSize : '1.1rem',color : '#757678'}}>{ApPat+' '+ApMat}</TableCell>
                                        <TableCell align="center" style={{fontFamily : 'Franklin Gothic Medium',fontSize : '1.1rem',color : '#757678'}}>{Tipo}</TableCell>
                                        <TableCell align="center" style={{fontFamily : 'Franklin Gothic Medium',fontSize : '1.1rem',color : '#757678'}}>{correo}</TableCell>
                                        <TableCell align="center" style={{fontFamily : 'Franklin Gothic Medium',fontSize : '1.1rem',color : '#757678'}}>{FechaNac}</TableCell>
                                        <TableCell align="center" style={{fontFamily : 'Franklin Gothic Medium',fontSize : '1.1rem',color : '#757678'}}>
                                            <IconButton onClick={(e) => abrirDialogo(id)} aria-label="delete">
                                                <DeleteIcon />
                                            </IconButton>
                                            <IconButton onClick={(e) => handleEdit(id)} aria-label="edit">
                                                <EditIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
            </div>
        </Fragment>
    );
}

export default UsuariosVer;