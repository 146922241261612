import React, {useContext} from 'react';
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import globalContext from "../../../../../Context/GlobalContext/globalContext";
import {handleChange} from "../../GlobalesCredenciales/GlobalTextField";
import {Redirect} from "react-router-dom";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import {makeStyles} from "@material-ui/styles";
import PropTypes from 'prop-types'

const useStyles = makeStyles(() => ({
    formControl: {
        minWidth: '100%',
    }
}))


const ComboClientes = ({state, setState, name}) => {

    const context = useContext(globalContext);
    const {clientes} = context;
    const classes = useStyles();

    const Clientes = () => {
        try {
            return (
                clientes.map(cliente =>
                    <MenuItem key={cliente['cli_ID']} value={cliente['cli_ID']}>{cliente['cli_Nombre']}</MenuItem>
                )
            )
        } catch (e) {
            return <Redirect to={'/panel'}/>
        }
    }

    return (
        <FormControl className={classes.formControl}>
            <InputLabel id={'clientes'}>Clientes</InputLabel>
            <Select
                labelId="clientes"
                label={'Clientes'}
                id="clientes"
                name={name}
                value={state[name]}
                onChange={e => handleChange(e, state, setState)}
            >
                {
                    Clientes()
                }
            </Select>
        </FormControl>

    );
};

ComboClientes.propTypes = {
    state : PropTypes.any.isRequired,
    setState: PropTypes.any.isRequired,
    name: PropTypes.string.isRequired,
}

export default ComboClientes;