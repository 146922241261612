import React, {useState} from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import clsx from "clsx";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import myTheme from "../../../Credenciales/helpers/Theme";

const useStyles = makeStyles({
    error: {
        color: '#f44336',
        margin: 0,
        fontSize: '0.75rem',
        marginTop: '3px',
        paddingLeft: '14px',
        paddingRight: '14px',
        textAlign: 'left',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        lineHeight: 1.66,
        letterSpacing: '0.033333em'
    },
})

const PasswordField = ({name, onChange, label,id, value, color, variant, error, helperText}) => {
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <FormControl fullWidth >
            <InputLabel color={color} htmlFor={id}>{label}</InputLabel>
            <Input
                id={id}
                color={color}
                name={name}
                type={showPassword ? 'text' : 'password'}
                value={value}
                onChange={onChange}
                error={error}
                helperText={helperText}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
                labelWidth={90}
            />
            {error ?
                <p className={classes.error}>{helperText}</p>
                :
                null
            }
        </FormControl>
    );
};

export default PasswordField;