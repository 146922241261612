import React from 'react';
import Grid from "@material-ui/core/Grid";
import ListaAgregar from "./ListaAgregar";

const ListaResidentes = ({setListaResidentes, listaResidentes, setMensaje}) => {
    return (
        <Grid item xs={12}>
            <Grid container>
                <Grid item xs={12}>
                    <h3>Residentes</h3>
                </Grid>
                <Grid item xs={12}>
                    <ListaAgregar
                        concepto={'residentes'}
                        setState={setListaResidentes}
                        lista={listaResidentes}
                        setMensaje={setMensaje}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ListaResidentes;
