
    export const cleanPoblacionPostal= () => {
        document.getElementById("poblacion").value = "";
        document.getElementById("postal").value = "";
    };


    export const isInputNumber = (e) => {
        let regex = /[^0-9]/;
        e.target.value = e.target.value.replace(regex,'')
    }


    export function checkTipo (tipoPrefix){
        switch(tipoPrefix) {
            case 'emp':
                return 'empleados';
            case 'doc':
                return 'docentes';
            case 'est':
                return 'estudiantes';
            default:
                return null
        }
    }

    export const identificadorPersona =  tipoPrefix =>{
        if(tipoPrefix !== 'est')
            return 'NumeroEmpleado';
        return 'Matricula';
    }

    export const ponCeroIzq = (num) => {
        return num < 10 ? '0'+num : num;
    }

    export const fechaString = (fecha) => {
        return `${fecha.getFullYear()}-${ponCeroIzq(fecha.getMonth()+1)}-${ponCeroIzq(fecha.getDate())}`;
    }



