import clienteAxios from "../../../config/axios";

export const registrarResidentes = async (
    e,
    states,
    initialState,
    Redireccionar,
    url,
    presidente,
    RedireccionarPresidente
) => {
    e.preventDefault();

    const {
        residente,
        setResidente,
        listaVisitas,
        setListaVisitas,
        listaResidentes,
        setListaResidentes,
        setMensaje,
        setErrores,
        setDate,
    } = states;

    const data = {
        ...residente,
        listaResidentes: listaResidentes,
        listaVisitas: listaVisitas,
    };

    // console.log(
    //     "%c%s%c%s%O",
    //     "font-size: 8pt; margin: 5px;",
    //     "INFO",
    //     "background: dodgerblue; padding: 5px; border-radius: 5px; margin: 5px;",
    //     '📄 State',
    //     data
    // );

    clienteAxios
        .post(url, data)
        .then(() => {
            setResidente(initialState);
            setErrores({});
            setDate(new Date());
            setListaResidentes([]);
            setListaVisitas([]);
            setMensaje({ msg: "Registrado exitosamente", error: false });
            if (!presidente) Redireccionar();

            RedireccionarPresidente();
        })
        .catch((error) => {
            setErrores(error.response.data.errors);
            if (data.listaResidentes.length === 0) {
                setMensaje({
                    msg: "Debe haber al menos un residente",
                    error: true,
                });
                return;
            }
            if (data.listaVisitas.length === 0) {
                setMensaje({
                    msg: "Debe haber al menos un visitante",
                    error: true,
                });
                return;
            }
            setMensaje({ msg: "Error al registrar", error: true });
        });
};

export const verificarResidente = async (userID, setResidentes, setMensaje) => {
    try {
        await clienteAxios.put(`api/clientes/usuarioacceso/${userID}`);
        const residentes = await clienteAxios.get(`api/clientes/usuarioacceso`);
        setResidentes(residentes.data);
        setMensaje("");
        setMensaje({ error: false, msg: "Residente verificado correctamente" });
    } catch (error) {
        setMensaje("");
        setMensaje({ error: true, msg: "Error al verificar residente" });
        console.log(error);
    }
};

export const verificarResidenteV2 = async (
    userID,
    privadaID,
    setResidentesPrivada,
    setMensaje
) => {
    try {
        await clienteAxios.put(`api/clientes/usuarioacceso/${userID}`);
        const residentesPrivada = await clienteAxios.get(
            `api/clientes/residentesPrivada/${privadaID}`
        );
        setResidentesPrivada(residentesPrivada.data);
        setMensaje("");
        setMensaje({ error: false, msg: "Residente verificado correctamente" });
    } catch (error) {
        setMensaje("");
        setMensaje({ error: true, msg: "Error al verificar residente" });
    }
};

export const indexResidentes = async () =>
    await clienteAxios.get("api/clientes/usuarioacceso");

export const registroRentador = async (rentador, setMensaje) => {
    const response = await clienteAxios
        .post(`api/registroRentador`, rentador)
        .then(({ data }) => {
            setMensaje(
                "El residente ha sido registrado como rentador correctamente"
            );
        });
};


export const activarCasaParaRenta = async (e,idUsuario, setMensaje, setResidente, residente) => {
    clienteAxios.put(`api/clientes/usuarioacceso/enRenta/${idUsuario}`)
        .catch((error) => {
            setResidente({ ...residente, ["EnRenta"]: true })
            setMensaje({msg: error.response.data.Mensaje, error: true})
        })

}

export const getRentadores = async (idUsuario, setRentadores) => {
    const response = await clienteAxios.get(`api/clientes/rentadores/${idUsuario}`);
    setRentadores(response.data);

}

export const verificarRentador = async (idRentador, setRentadores, setMensaje, idUsuario) => {
    try {
        await clienteAxios.put(`api/clientes/rentador/${idRentador}`);

        const rentadores = await clienteAxios.get(
            `api/clientes/rentadores/${idUsuario}`
        );
        setRentadores(rentadores.data);
        setMensaje("");
        setMensaje({ error: false, msg: "Rentador verificado correctamente" });
    } catch (error) {
        setMensaje("");
        setMensaje({ error: true, msg: "Error al verificar rentador" });
    }
}

export const eliminarRentador = async (idRentador, setMensaje, setRentadores, idUsuario) => {
    try {
        await clienteAxios.delete(`api/clientes/rentadores/${idRentador}`);

        const rentadores = await clienteAxios.get(
            `api/clientes/rentadores/${idUsuario}`
        );
        setRentadores(rentadores.data);

        setMensaje({msg:"Se ha eliminado exitosamente el rentador", error: false});

    } catch (error) {
        setMensaje("");
        setMensaje({msg: "Ha ocurrido un error al eliminar el rentador", error: true})
    }
};