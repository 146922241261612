import React, {useContext} from 'react';
import Funcion from "./Funcion";
import Grid from "@material-ui/core/Grid";
import BotonRegresar from "./BotonRegresar";
import globalContext from "../../../../Context/GlobalContext/globalContext";
import FuncionClientes from "./FuncionClientes";

const ListaFunciones = () => {

    const context = useContext(globalContext);
    const {regresarPanel} = context;
    const regresar = () => regresarPanel();


    return (
        <Grid
            container
            direction='column'
            alignItems='center'
            justify='center'
        >
            <ul style={{width: '100%'}}>
                <FuncionClientes
                    icono='users'
                    nombre='Registrar'
                    url={'/credenciales/'}
                />
                <Funcion
                    icono='envelope-open'
                    nombre='Solicitudes'
                    fragmentos={['nueva', 'historial']}
                    url={'/credenciales/solicitudes/'}
                />
                <Funcion
                    icono='box'
                    nombre='Lotes'
                    fragmentos={[]}
                    url={'/credenciales/lotes'}
                />
                <Funcion
                    icono='truck'
                    nombre='Entregas'
                    fragmentos={['pendientes', 'historial']}
                    url={'/credenciales/entregas/'}
                />
                <Funcion
                    icono='shipping-fast'
                    nombre='Solicitud rápida'
                    fragmentos={[]}
                    url={'/credenciales/solicitud-rapida'}
                />
                <Funcion
                    icono='images'
                    nombre='Pendientes'
                    fragmentos={[]}
                    url={'/credenciales/FotosFirmasPendientes'}
                />
                <BotonRegresar
                    icono={'sign-out-alt'}
                    handleClick={regresar}
                />
            </ul>
        </Grid>

    );
};

export default ListaFunciones;