import React from 'react';
import {TableContainer, TableRow} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import StyledTableCell from "../../GlobalesCredenciales/StyledTableCell";
import TableBody from "@material-ui/core/TableBody";
import Fila from "../Fila";
import Paper from "@material-ui/core/Paper";

const ListosTabla = ({states}) => {

    const {listos} = states;

    return (
        <Paper style={{width: '100%'}}>
            <TableContainer style={{maxHeight: '70vh'}}>
                <Table stickyHeader aria-label="Lotes listos">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>ID</StyledTableCell>
                            <StyledTableCell align="center">Solicitud</StyledTableCell>
                            <StyledTableCell align="center">Cantidad</StyledTableCell>
                            <StyledTableCell align="center">Fecha</StyledTableCell>
                            <StyledTableCell align="center">Rápida</StyledTableCell> {/*Si es especial*/}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {listos.map(row => <Fila row={row} />)}
                    </TableBody>
                </Table>

            </TableContainer>

        </Paper>
    );
};

export default ListosTabla;