import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {FormControl, TextField} from "@material-ui/core";
import {handleChange} from "../../../Credenciales/Components/GlobalesCredenciales/GlobalTextField";
import Button from "@material-ui/core/Button";
import SendIcon from '@material-ui/icons/Send';
import {makeStyles} from "@material-ui/styles";
import {getPrivadas, registrarPrivada} from "../../Controllers/PrivadasController";
import {useAlert} from "react-alert";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((t) => ({
    formControl: {
        minWidth: 250,
    },
}));

const initialState = {
    pri_privada: '',
}

const RegistrarPrivadas = () => {

    const alert = useAlert();
    const classes = useStyles();

    const [privadas, setPrivadas] = useState([])
    const [mensaje, setMensaje] = useState('');
    const [privada,setPrivada] = useState(initialState);

    useEffect(()=> {
        getPrivadas(setPrivadas)
    },[])

    useEffect(() => {
        if (mensaje === '') return;
        if (!mensaje.error)
            alert.success(mensaje.msg);
        else
            alert.error(mensaje.msg);
    }, [mensaje]);

    return (
        <div>
            <h2>Registrar Privadas</h2>
            <form onSubmit={e => registrarPrivada(e, privada, setMensaje, initialState, setPrivada)}>
                <Grid container direction='column' spacing={3} justify='center' alignItems='center'>
                    <Grid item xs>
                        <FormControl className={classes.formControl}>
                            <InputLabel id={'privadas'}>Privada</InputLabel>
                            <Select
                                labelId={'privadas'}
                                name={'pri_privada'}
                                value={privadas.pri_privada}
                                onChange={(e) => handleChange(e, privada, setPrivada)}
                            >
                                {
                                    privadas.map(privada =>
                                        <MenuItem key={privada['id']}
                                                  value={privada['id']}>{privada['nombre_privada']}</MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs>
                        <Button endIcon={<SendIcon/>} type={'submit'} color='secondary' variant='outlined'>
                            Registrar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};

export default RegistrarPrivadas;