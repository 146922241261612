import React from 'react';
import '../../assets/styles/MenuSistemas/News-Cards.scss'
import './MenuSistemas.scss'
import {Button, Grid, Paper} from '@material-ui/core'

const Carta = ({sistema, handleClick, svg, texto}) => {


    return (
        <Grid item xs={12} md={6} >
            <Button onClick={handleClick}>
                <Paper elevation={3}>
                    <h2 className='card-title'>{sistema}</h2>
                    <Grid container className='card-container'>
                        <Grid item xs={12} md={3}>
                            <p className='card-legend'>{texto}</p>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <img className='card-image' src={svg} alt={`sistema de ${sistema}`} />
                        </Grid>
                    </Grid>
                </Paper>
            </Button>
        </Grid>
    );
};

export default Carta;