import React, {useEffect, useState} from 'react';
import {TableContainer, TableRow} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import StyledTableCell from "../../GlobalesCredenciales/StyledTableCell";
import TableBody from "@material-ui/core/TableBody";
import StyledRow from "../../GlobalesCredenciales/StyledRow";
import Paper from "@material-ui/core/Paper";
import StyledCheckbox from "../../GlobalesCredenciales/StyledCheckbox";

const PendientesTabla = ({pendientes, contador}) => {

    const {count, incrementar} = contador;

    const Fila = ({row}) => {

        const handleChange = () => {
            row.Checked = !row.Checked;
            incrementar(row.Checked);
        }
        return (
            <StyledRow key={row['ID']}>
                <StyledTableCell scope="row">{row.ID}</StyledTableCell>
                <StyledTableCell align="center">{row.Solicitud}</StyledTableCell>
                <StyledTableCell align="center">{row.Cantidad}</StyledTableCell>
                <StyledTableCell align="center">{row.Fecha}</StyledTableCell>
                <StyledTableCell align="center" style={{padding: 0, margin: 0}}>
                    <StyledCheckbox name={row.id} onChange={handleChange} checked={row.Checked}/>
                </StyledTableCell>

            </StyledRow>
        )
    };


    return (
        <Paper style={{width: '100%'}}>
            <TableContainer style={{maxHeight: '70vh'}}>
                <Table stickyHeader aria-label="Lotes pendientes">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Folio</StyledTableCell>
                            <StyledTableCell align="center">Solicitud</StyledTableCell>
                            <StyledTableCell align="center">Cantidad</StyledTableCell>
                            <StyledTableCell align="center">Fecha</StyledTableCell>
                            <StyledTableCell align="center">{`${count} (max.4)`}</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { pendientes.map( (row, key) => <Fila key={key} row={row}/>) }
                    </TableBody>
                </Table>

            </TableContainer>

        </Paper>
    );
};




export default PendientesTabla;