import React, {Fragment} from 'react';
import FileCopyIcon from "@material-ui/icons/FileCopy";
import IconButton from "@material-ui/core/IconButton";
import {useAlert} from "react-alert";
import Grid from "@material-ui/core/Grid";

const CopyToClipboard = ({Id, url}) => {

    const alert = useAlert();

    const copiarLink = () => {

        let copyTextarea = document.createElement("textarea");
        copyTextarea.style.position = "fixed";
        copyTextarea.style.opacity = "0";
        copyTextarea.textContent = document.getElementById(`copytext-${Id}`).value;

        document.body.appendChild(copyTextarea);
        copyTextarea.select();
        document.execCommand("copy");
        document.body.removeChild(copyTextarea);

        alert.success('Copiado')


    }

    return (
        <Grid container alignItems={'center'} spacing={2}>
            <Grid item>
                <input style={{width: 250}} value={`${url}${Id}`} disabled id={`copytext-${Id}`}/>
            </Grid>
            <Grid item>
                <IconButton id={'button-to-copy'} onClick={() => copiarLink()}>
                    <FileCopyIcon/>
                </IconButton>
            </Grid>
        </Grid>


    );
};

export default CopyToClipboard;