import {withStyles} from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import React from "react";
import Slide from "@material-ui/core/Slide";



const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5'
    },
})
(props => (
    <Menu
        elevation={0}
        TransitionComponent={Slide}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

export default StyledMenu