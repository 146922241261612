export class MenuSistemasController
{
    constructor()
    {
    }
    handleClick(checarPermisos,sistema,usuario)
    {
        checarPermisos(sistema,usuario);
    }

}