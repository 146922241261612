import React from 'react';

import Grid from "@material-ui/core/Grid";
import LaminarTabla from "./LaminarTabla";
import BotonErrores from "../BotonErrores";

const Laminar = ({states}) => {

    const {laminar, erroresLaminar, setErroresLaminar} = states;

    const mostrarTabla = () => {
        if(laminar.length === 0)
            return <h3>No hay lotes para laminar</h3>
        else
            return <LaminarTabla states={states} />
    }

    return (
        <Grid container justify={'center'} style={{minHeight: 'auto'}}>
            <Grid item xs={12}>
                <BotonErrores zona={false} states={{errores: erroresLaminar, setErrores: setErroresLaminar}}/>
            </Grid>
            <Grid item>
                {mostrarTabla()}
            </Grid>

        </Grid>
    );
};

export default Laminar;