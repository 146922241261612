import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import clienteAxios from "../../../../config/axios";
import Grid from "@material-ui/core/Grid";
import { useAlert, transitions, positions } from "react-alert";
import "./RegistrarResidentes.scss";
import FormularioRegistroResidentes from "./FormularioRegistroResidentes";
import Circulos from "../../../Circulos/Circulos";
import Logo from "../../../../assets/images/sensor-white.png";
import {StyledLinearProgress} from '../AccesoComponents'

const LogoSensor = {
    filter: "invert(100%)",
    webkitFilter: "invert(100%)",
    height: "3rem",
    top: "0.5rem",
    left: "0.5rem",
    zIndex: "100",
    position: "fixed",
    transition: "top 0.3s",
};

const initialState = {
    UsNombre: "",
    UsApPat: "",
    UsApMat: "",
    email: "",
    password: "",
    UsFechaNac: new Date(),
    numCasa: "",
    ua_telefono: "",
    comentarios: "",
    ua_privada: "",
};

const alertOptions = {
    position: positions.BOTTOM_RIGHT,
    timeout: 10000,
    offset: "20px",
    transition: transitions.SCALE,
};

const RegistrarResidentes = () => {
    const alert = useAlert();
    const params = useParams();
    const location = useLocation();

    const [privada, setPrivada] = useState(null);
    const [mensaje, setMensaje] = useState("");
    const [errores, setErrores] = useState({});
    const [response, setResponse] = useState(null);
    const [url, setUrl] = useState("api/registroResidentes");

    const states = {
        setMensaje,
        setErrores,
        errores,
        initialState,
        setResponse,
    };

    const presidente = location.pathname.includes(
        "/clientes/registrar/presidente"
    );

    //Funcion para esconder el logo cuando se hace scroll
    try {
        if (document.getElementById("sensor-logo")) {
            let prevScrollpos = window.pageYOffset;
            window.onscroll = function () {
                let currentScrollPos = window.pageYOffset;
                if (prevScrollpos > currentScrollPos) {
                    document.getElementById("sensor-logo").style.top = "0";
                } else {
                    document.getElementById("sensor-logo").style.top = "-50px";
                }
                prevScrollpos = currentScrollPos;
            };
        }
    } catch (e) {}

    useEffect(() => {
        if (response === null) return;
        if (response.Codigo === "0") return;
        else if (response.Codigo === "1") {
            //Existe pero no se renta
            alert.error(response.Mensaje, alertOptions);
        } else if (response.Codigo === "2") {
            //Se renta
            alert.info(response.Mensaje, alertOptions);
            setUrl(`api/registroRentador`);
        }
    }, [response]);

    useEffect(() => {
        if (presidente) setUrl("api/clientes/registrarPresidente");

        const fetchData = async () => {
            const resultado = await clienteAxios.get(
                `api/clientes/getPrivada/${params.id}`
            );
            setPrivada(resultado.data);
        };
        fetchData().then();
    }, []);

    useEffect(() => {
        if (mensaje === "") return;
        if (!mensaje.error) alert.success(mensaje.msg);
        else alert.error(mensaje.msg);
    }, [mensaje]);

    return (
        <div>
            {!presidente ? (
                <img
                    id={"sensor-logo"}
                    src={Logo}
                    style={LogoSensor}
                    alt={"logo-sensor"}
                />
            ) : null}

            {privada === null ? 
                <StyledLinearProgress />
             : 
            
                <Grid container justify="center" alignItems={"center"}>
                    <Grid item xs={12}>
                        <h1 className="nombre-privada">
                            {presidente
                                ? `Registro de presidente de ${privada}`
                                : `${privada}`}
                        </h1>
                    </Grid>
                    {/* Formulario y datos */}
                    <Grid item xs={12}>
                        <Grid
                            container
                            justify={"center"}
                            alignItems={"stretch"}
                            style={{ height: "100%" }}
                        >
                            <FormularioRegistroResidentes
                                states={states}
                                url={url}
                                paramsID={params.id}
                                presidente={presidente}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            }

            <Circulos />
        </div>
    );
};

export default RegistrarResidentes;
