import {  Button, Fab, Grid, Paper, Tooltip } from '@material-ui/core'
import React, {useState, useEffect } from 'react'
import { asignarTecnico, cerrarTicket, exportarExcelTickets, getTecnicos, getTicketsPrioridad } from '../../Controllers/TicketsController'
import { ConfirmDialog, DialogSelect, StyledLinearProgress } from '../AccesoComponents'
import './Tickets.scss'
import TicketSingular from './TicketSingular'
import {theme} from '../Theme'
import {createStyles, ThemeProvider,makeStyles, Theme } from "@material-ui/styles";
import { useAlert } from 'react-alert'
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles({
    fab: {
        position: "fixed",
        bottom: '1rem',
        right: '1rem',
    },
});

const Tickets = () => {

    const alert = useAlert();
    const classes = useStyles();
    const [ticketsPrioridad, setTicketsPrioridad] = useState(null)
    const [dialogOpen, setDialogOpen] = useState(false)
    const [openDialogTecnico, setOpenDialogTecnico] = useState(false)
    const [folio, setFolio] = useState(null)
    const [mensaje, setMensaje] = useState('');
    const [tecnicos, setTecnicos] = useState(null)
    const [selectedTecnico, setSelectedTecnico] = useState('');

    const handleCloseConfirmDialog = () => {
        setDialogOpen(false);
        setFolio(null);
    }

    const handleCloseSelectDialog =() => {
        setOpenDialogTecnico(false);
        setSelectedTecnico('')
    }

    useEffect(() => {
        getTicketsPrioridad(setTicketsPrioridad);
        getTecnicos(setTecnicos)
    }, [])

    useEffect(() => {
        if (mensaje === "") return;
        if (mensaje.error) {
            alert.error(mensaje.msg);
        } else {
            alert.success(mensaje.msg);
        }
        return () => {
            setMensaje("");
        };
    }, [mensaje]);


    const IterateTickets = ({arreglo}) => {
        if(arreglo.length === 0){
            return null;
        }
        else{
            return (
                <Paper elevation={4} className="paper-prioridad">
                    {arreglo.map((item, index) => (
                        <TicketSingular
                            key={index}
                            info={item}
                            setDialogOpen={setDialogOpen}
                            setFolio={setFolio}
                            setOpenDialogTecnico={setOpenDialogTecnico}
                        />
                    ))}
                </Paper>
            );
            
        }
    }

    return (
        <>
            <h1 className="tickets-h1">Tickets de prioridad</h1>
            <Grid
                className="container-secciones"
                container
                justify="flex-start"
            >
                <Grid container alignItems="flex-start" justify="center">
                    <Grid
                        className="prioridad-seccion"
                        item
                        xs={12}
                        md={6}
                        lg={4}
                    >
                        <Grid
                            container
                            justify="space-between"
                            alignItems="flex-end"
                        >
                            <Grid item xs={6}>
                                <p className="cantidad-tickets">
                                    {ticketsPrioridad !== null &&
                                        `${ticketsPrioridad.alta.length} tickets`}
                                </p>
                            </Grid>
                            <Grid item xs={6}>
                                <h2 className="prioridad-h2">Alta</h2>
                            </Grid>
                        </Grid>

                        {ticketsPrioridad === null ? (
                            <StyledLinearProgress />
                        ) : (
                            <IterateTickets arreglo={ticketsPrioridad.alta} />
                        )}
                    </Grid>

                    <Grid
                        className="prioridad-seccion"
                        item
                        xs={12}
                        md={6}
                        lg={4}
                    >
                        <Grid
                            container
                            justify="space-between"
                            alignItems="flex-end"
                        >
                            <Grid item xs={6}>
                                <p className="cantidad-tickets">
                                    {ticketsPrioridad !== null &&
                                        `${ticketsPrioridad.media.length} tickets`}
                                </p>
                            </Grid>
                            <Grid item xs={6}>
                                <h2 className="prioridad-h2">Media</h2>
                            </Grid>
                        </Grid>

                        {ticketsPrioridad === null ? (
                            <StyledLinearProgress />
                        ) : (
                            <IterateTickets arreglo={ticketsPrioridad.media} />
                        )}
                    </Grid>
                    <Grid
                        className="prioridad-seccion"
                        item
                        xs={12}
                        md={6}
                        lg={4}
                    >
                        <Grid
                            container
                            justify="space-between"
                            alignItems="flex-end"
                        >
                            <Grid item xs={6}>
                                <p className="cantidad-tickets">
                                    {ticketsPrioridad !== null &&
                                        `${ticketsPrioridad.baja.length} tickets`}
                                </p>
                            </Grid>
                            <Grid item xs={6}>
                                <h2 className="prioridad-h2">Baja</h2>
                            </Grid>
                        </Grid>

                        {ticketsPrioridad === null ? (
                            <StyledLinearProgress />
                        ) : (
                            <IterateTickets arreglo={ticketsPrioridad.baja} />
                        )}
                    </Grid>
                    <Grid
                        className="prioridad-seccion"
                        item
                        xs={12}
                        md={6}
                        lg={4}
                    >
                        <Grid
                            container
                            justify="space-between"
                            alignItems="flex-end"
                        >
                            <Grid item xs={6}>
                                <p className="cantidad-tickets">
                                    {ticketsPrioridad !== null &&
                                        `${ticketsPrioridad.cerrados.length} tickets`}
                                </p>
                            </Grid>
                            <Grid item xs={6}>
                                <h2 className="prioridad-h2">
                                    Completadas hoy
                                </h2>
                            </Grid>
                        </Grid>

                        {ticketsPrioridad === null ? (
                            <StyledLinearProgress />
                        ) : (
                            <IterateTickets
                                arreglo={ticketsPrioridad.cerrados}
                            />
                        )}
                    </Grid>
                </Grid>
                <ThemeProvider theme={theme}>
                    <ConfirmDialog
                        dialogTitle="Confirmación de ticket"
                        dialogContentText="Esta acción cambiará el estado del ticket a Cerrado. 
                        Haga esta acción cuando el ticket haya sido cerrado y terminado completamente"
                        handleClose={() => handleCloseConfirmDialog()}
                        dialogOpen={dialogOpen}
                        confirmAction={() => {
                            cerrarTicket(
                                folio,
                                setMensaje,
                                setTicketsPrioridad
                            );
                            setDialogOpen(false);
                        }}
                    />
                    <DialogSelect
                        dialogTitle="Asignar técnico a un ticket"
                        dialogContentText="Al agregar a un técnico a un ticket se le enviará un correo
                            con los detalles del ticket a atenderse. Se puede cambiar el asignado en cualquier momento"
                        handleClose={() => handleCloseSelectDialog()}
                        dialogOpen={openDialogTecnico}
                        setSelected={setSelectedTecnico}
                        selected={selectedTecnico}
                        inputLabel="Técnicos"
                        selectContent={tecnicos}
                        confirmAction={() => {
                            asignarTecnico(
                                folio,
                                selectedTecnico,
                                setMensaje,
                                setTicketsPrioridad
                            );
                            setOpenDialogTecnico(false);
                        }}
                    />
                    <Tooltip title="Descargar archivo Excel">
                        <Fab
                            className={classes.fab}
                            variant="round"
                            color="primary"
                            onClick={() => exportarExcelTickets()}
                        >
                            <GetAppIcon />
                        </Fab>
                    </Tooltip>
                </ThemeProvider>
            </Grid>
        </>
    );
}


export default Tickets
