import React, {useContext, useEffect, useState} from 'react';
import SubmitButton from "../Components/SubmitButton";
import Grid from "@material-ui/core/Grid";
import GlobalTextField from "../../../../GlobalesCredenciales/GlobalTextField";
import {
    handleChange,
    handleSubmit,
    getCarreras,
} from '../../../../../Controllers/FormulariosController'
import {isInputNumber} from "../../../../../helpers/Util";
import {useAlert} from "react-alert";
import FormControl from "@material-ui/core/FormControl";
import {InputLabel, Select} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import globalContext from "../../../../../../../Context/GlobalContext/globalContext";
import {onBlurHandler} from "../../../../../Controllers/ModificarController";
import InputFile from "../../../../GlobalesCredenciales/InputFile";

const stateInicial = {
    est_Matricula: '',
    est_Nombre: '',
    est_ApellidoMaterno: '',
    est_ApellidoPaterno: '',
    est_CalleNumero: '',
    est_Colonia: '',
    est_PoblacionCodigoPostal: '',
    est_Correo: '',
    est_Carrera: '',
    est_Telefono: '',
    est_Firma: null,
    est_Foto: null,
};

const Estudiantes = () => {

    const alert = useAlert();
    const [estudiante, nuevoEstudiante] = useState(stateInicial);
    const [mensaje, agregarMensaje] = useState('');
    const [errores, agregarErrores] = useState({});
    const [carreras, agregarCarreras] = useState([]);
    const [modificando, setModficando] = useState(false);
    const [poblacionCodigoPostal, setPoblacionCodigoPostal] = useState(
        {poblacion: '', codigoPostal: ''}
    )

    const context = useContext(globalContext)
    const {cliente} = context;
    const clienteID = cliente['cli_ID'];


    useEffect(() => {
        getCarreras(agregarCarreras).then();
    }, []);

    useEffect(() => {
        if (mensaje !== '') {
            alert.success(mensaje);
        }
    }, [mensaje]);


    return (
        <Grid
            container
            display='flex'
            justify='flex-start'
            component='div'
            direction='column'
        >
            <Grid item>

                <form onSubmit={(e => handleSubmit
                (e, estudiante, nuevoEstudiante, clienteID, 'est',
                    stateInicial, agregarErrores, agregarMensaje, modificando,setModficando, poblacionCodigoPostal, setPoblacionCodigoPostal))}>
                    <Grid
                        container
                        display='flex'
                        direction="row"
                        justify="center"
                        alignItems="flex-start"
                        spacing={2}
                    >


                        <Grid item md={3} sm={12}>
                            <GlobalTextField
                                id={'numID'}
                                name={'est_Matricula'}
                                label={'Matrícula'}
                                icono={'id-card'}
                                value={estudiante.est_Matricula}
                                error={!!errores.est_Matricula}
                                helperText={errores.est_Matricula}
                                disabled={modificando}
                                long={8}
                                onBlur={(e) =>
                                    onBlurHandler(e, 'est', nuevoEstudiante, estudiante.est_Matricula, stateInicial, setModficando, setPoblacionCodigoPostal, clienteID, alert)}
                                onChange={(e) => {
                                    isInputNumber(e);
                                    handleChange(e, estudiante, nuevoEstudiante)
                                }}/>
                        </Grid>
                        <Grid item md={9} sm={12}>
                            <FormControl fullWidth>
                                <InputLabel id={'carrera'}>Carrera</InputLabel>
                                <Select
                                    labelId={'carrera'}
                                    name={'est_Carrera'}
                                    value={estudiante.est_Carrera}
                                    onChange={(e) => handleChange(e, estudiante, nuevoEstudiante)}
                                >
                                    {
                                        carreras.map(carrera =>
                                            <MenuItem key={carrera['car_ID']}
                                                      value={carrera['car_ID']}>{carrera['car_Descripcion']}</MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Grid>


                        {/*Parte del formulario donde se vuelve general */}

                        <Grid item md={6} sm={12}>
                            <GlobalTextField
                                id={'nombre'}
                                label={'Nombre'}
                                icono={'user'}
                                name={'est_Nombre'}
                                value={estudiante.est_Nombre}
                                error={!!errores.est_Nombre}
                                helperText={errores.est_Nombre}
                                long={100}
                                onChange={(e) => handleChange(e, estudiante, nuevoEstudiante)}
                            />
                        </Grid>
                        <Grid item md={3} sm={12}>
                            <GlobalTextField
                                id={'apellidoPaterno'}
                                label={'Apellido paterno'}
                                name={'est_ApellidoPaterno'}
                                value={estudiante.est_ApellidoPaterno}
                                error={!!errores.est_ApellidoPaterno}
                                helperText={errores.est_ApellidoPaterno}
                                long={50}
                                onChange={(e) => handleChange(e, estudiante, nuevoEstudiante)}/>
                        </Grid>
                        <Grid item md={3} sm={12}>
                            <GlobalTextField
                                id={'apellidoMaterno'}
                                label={'Apellido materno'}
                                name={'est_ApellidoMaterno'}
                                value={estudiante.est_ApellidoMaterno}
                                error={!!errores.est_ApellidoMaterno}
                                helperText={errores.est_ApellidoMaterno}
                                long={50}
                                onChange={(e) => handleChange(e, estudiante, nuevoEstudiante)}/>
                        </Grid>

                        <Grid item md={6} sm={12}>
                            <GlobalTextField
                                id={'correo'}
                                label={'Correo'}
                                icono={'envelope'}
                                tipo={'email'}
                                name={'est_Correo'}
                                value={estudiante.est_Correo}
                                error={!!errores.est_Correo}
                                helperText={errores.est_Correo}
                                long={100}
                                onChange={(e) => handleChange(e, estudiante, nuevoEstudiante)}/>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <GlobalTextField
                                id={'telefono'}
                                label={'Teléfono'}
                                icono={'phone'}
                                tipo={'tel'}
                                name={'est_Telefono'}
                                value={estudiante.est_Telefono}
                                error={!!errores.est_Telefono}
                                helperText={errores.est_Telefono}
                                long={10}
                                onChange={(e) => {
                                    isInputNumber(e);
                                    handleChange(e, estudiante, nuevoEstudiante)
                                }}/>
                        </Grid>


                        <Grid item md={5} sm={12}>
                            <GlobalTextField
                                id={'calle'}
                                label={'Calle y número'}
                                icono={'home'}
                                name={'est_CalleNumero'}
                                value={estudiante.est_CalleNumero}
                                error={!!errores.est_CalleNumero}
                                helperText={errores.est_CalleNumero}
                                long={50}
                                onChange={(e) => handleChange(e, estudiante, nuevoEstudiante)}/>
                        </Grid>
                        <Grid item md={3} sm={12}>
                            <GlobalTextField
                                id={'colonia'}
                                label={'Colonia'}
                                tipo={'text'}
                                name={'est_Colonia'}
                                value={estudiante.est_Colonia}
                                error={!!errores.est_Colonia}
                                helperText={errores.est_Colonia}
                                long={50}
                                onChange={(e) => handleChange(e, estudiante, nuevoEstudiante)}/>
                        </Grid>
                        <Grid item md={2} sm={12}>
                            <GlobalTextField
                                id={'poblacion'}
                                label={'Población'}
                                tipo={'text'}
                                name={'poblacion'}
                                error={!!errores.est_PoblacionCodigoPostal}
                                helperText={errores.est_PoblacionCodigoPostal}
                                value={poblacionCodigoPostal.poblacion}
                                long={50}
                                onChange={(e) => handleChange(e, poblacionCodigoPostal, setPoblacionCodigoPostal)}/>
                        </Grid>
                        <Grid item md={2} sm={12}>
                            <GlobalTextField
                                id={'postal'}
                                label={'Código postal'}
                                name={'codigoPostal'}
                                error={!!errores.est_PoblacionCodigoPostal}
                                helperText={errores.est_PoblacionCodigoPostal}
                                value={poblacionCodigoPostal.codigoPostal}
                                long={5}
                                onChange={(e) => {
                                    isInputNumber(e);
                                    handleChange(e, poblacionCodigoPostal, setPoblacionCodigoPostal)
                                }}/>
                        </Grid>

                        {/* Archivos */}
                        <Grid item md={6} sm={12}>
                            <InputFile
                                id={'foto'}
                                icono={'camera'}
                                name={'est_Foto'}
                                objeto={estudiante}
                                setObjeto={nuevoEstudiante}
                                accept={'image/jpeg'}
                                error={!!errores.est_Foto}
                                helperText={errores.est_Foto}
                            />
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <InputFile
                                id={'Firma'}
                                icono={'feather'}
                                name={'est_Firma'}
                                objeto={estudiante}
                                setObjeto={nuevoEstudiante}
                                accept={'image/jpeg'}
                                error={!!errores.est_Firma}
                                helperText={errores.est_Firma}
                            />
                        </Grid>


                        <Grid item>
                            <SubmitButton/>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Grid>


    );
};

export default Estudiantes;