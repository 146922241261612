import React, {useEffect, useState} from 'react';
import {CircularProgress} from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
import ImprimirTabla from "./ImprimirTabla";
import BotonErrores from "../BotonErrores";

const Imprimir = ({states}) => {

    const {imprimir, erroresImprimir, setErroresImprimir} = states;


    const mostrarTabla = () => {
        if (imprimir === null)
            return <CircularProgress color={"secondary"}/>
        else if (imprimir.length === 0)
            return <h3>No hay lotes para imprimir</h3>
        else
            return <ImprimirTabla states={states}/>
    }

    return (
        <Grid container justify={'center'} style={{minHeight: 'auto'}}>
            <Grid item xs={12}>
                <BotonErrores zona={true} states={{errores: erroresImprimir, setErrores: setErroresImprimir}}/>
            </Grid>
            <Grid item>
                {mostrarTabla()}
            </Grid>
        </Grid>
    );
};

export default Imprimir;