import React from 'react';
import Grid from "@material-ui/core/Grid";
import {basicContainer, tituloStyle} from "../../../../../helpers/Styles";
import RegistrarUADEO from "./RegistrarUADEO";


const UAdO = ({title,cliente}) => {

    return (
        <Grid
            container
            display='flex'
            justify='flex-start'
            component='div'
            direction='column'
            style={basicContainer}
        >
            <Grid item >
                <h1 style={tituloStyle}>{title}</h1>
            </Grid>
            <Grid
                item
            >
                <RegistrarUADEO/>
            </Grid>
        </Grid>
    );
};

export default UAdO