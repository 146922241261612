import { Paper, Grid, Divider, Tooltip } from '@material-ui/core'
import React from 'react'
import '../Tickets/Tickets.scss'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

const TicketsTecnicos = ({info}) => {

    const { Fecha, Folio, Reportero, Cliente, Problema} = info;

    return (
        <Grid item xs={12} sm={6} md={4} lg={3}>
            <Paper elevation={4} className="ticket-paper">
                <TicketHeader folio={Folio} fecha={Fecha} />
                <Divider />
                <Grid container>
                    <TicketInfo cliente={Cliente} />
                    <TicketProblema problema={Problema} />
                    <TicketFooter reportero={Reportero} />
                </Grid>
            </Paper>
        </Grid>
    );
}


const TicketHeader = ({folio, fecha}) => {

    return (
        <Grid
            className="ticket-header"
            container
            justify="space-between"
            alignItems="center"
        >
            <div>
                <span>{folio}</span>
            </div>
            <span>{fecha}</span>
        </Grid>
    );
}


const TicketInfo = ({ cliente}) => {
    return (
        <Grid item xs={12}>
            <Grid className="ticket-info" container spacing={2}>
                <Grid item xs={12}>
                    <p style={{textAlign: 'right'}}>{cliente}</p>
                </Grid>
            </Grid>
        </Grid>
    );
}

const TicketProblema = ({problema}) => (
        <Grid item xs={12}>
            <Grid container justify="flex-start" alignItems="center">
                <Grid item xs>
                    <ReportProblemIcon style={{ color: "#999" }} />
                </Grid>
                <Grid item xs={10}>
                    <p className="ticket-problema">
                        {problema}
                    </p>
                </Grid>
            </Grid>
        </Grid>
    );


const TicketFooter = ({
    Folio,
    reportero,
    completado,
    setDialogOpen,
    setOpenDialogTecnico,
    setFolio,
}) => (
    <Grid className="ticket-footer" item xs={12}>
        <Grid
            container
            alignItems="flex-end"
            style={{ height: "100%" }}
            justify="space-between"
        >
            <Grid item>{`Reportero: ${reportero}`}</Grid>
        </Grid>
    </Grid>
);


export default TicketsTecnicos
