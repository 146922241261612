import React from 'react'
import "./Circulos.scss"

const Circulos = () => {
    return (
        <>
            <div className="circulo-rojo1"></div>
            <div className="circulo-rojo2"></div>
            <div className="circulo-rojo3"></div>
            <div className="circulo-rojo4"></div>
            <div className="circulo-rojo5"></div>
            <div className="circulo-rojo6"></div>
            <div className="circulo-rojo7"></div>
            <div className="circulo-rojo8"></div>
            <div className="circulo-rojo9"></div>
            <div className="circulo-rojo10"></div>
            <div className="circulo-gris1"></div>
            <div className="circulo-gris2"></div>
            <div className="circulo-gris3"></div>
            <div className="circulo-gris4"></div>
            <div className="circulo-gris5"></div>
            <div className="circulo-gris6"></div>
        </>
    )
}

export default Circulos
