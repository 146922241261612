import React, {useEffect, useState} from 'react';
import {TableContainer, TableRow} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import StyledTableCell from "../../../GlobalesCredenciales/StyledTableCell";
import TableBody from "@material-ui/core/TableBody";
import StyledRow from "../../../GlobalesCredenciales/StyledRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import myTheme from "../../../../helpers/Theme";
import GlobalTextField, {handleChange} from "../../../GlobalesCredenciales/GlobalTextField";
import DatePicker from "../DatePicker";
import {useAlert} from "react-alert";
import {submitEntrega} from "../../../../Controllers/EntregasController";
import {fechaString} from "../../../../helpers/Util";

const LocalesTabla = ({entregas}) => {

    const alert = useAlert();
    const [mensaje, setMensaje] = useState('');

    useEffect(() => {
        if(mensaje === '') return;
        if (mensaje.tipo === 'success')
            alert.success(mensaje.msg);
        else
            alert.error(mensaje.msg)
    }, [mensaje]);

    const Fila = ({row}) => {

        const [fila, setFila] = useState(row);
        const [fecha, setFecha] = useState(new Date());

        useEffect(() => {
            if(fecha === null)
                return;
            fila['ent_FechaRecibido'] = fechaString(fecha);
        },[fecha]);

        return (
            <StyledRow>
                <StyledTableCell scope="row">{row.Folio}</StyledTableCell>
                <StyledTableCell align="center">{row.Cantidad}</StyledTableCell>
                <StyledTableCell align="center">
                    <DatePicker
                        fecha={fecha}
                        setFecha={setFecha}
                    />
                </StyledTableCell>
                <StyledTableCell align="center">
                    <GlobalTextField
                        id='ent_Recibio'
                        name='ent_Recibio'
                        onChange={e => handleChange(e, fila, setFila)}
                    />
                </StyledTableCell>
                <StyledTableCell align="center" >
                    <IconButton aria-label='Hecho' onClick={()=> submitEntrega(fila, setMensaje, 'local')}>
                        <Icon className='fa fa-check-circle' style={{color: myTheme.palette.primary.main}}/>
                    </IconButton>
                </StyledTableCell>
            </StyledRow>
        )
    }

    return (
        <Paper style={{width: '100%'}}>
            <TableContainer style={{maxHeight: '70vh'}}>
                <Table stickyHeader aria-label="Historial de entregas locales">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Folio</StyledTableCell>
                            <StyledTableCell align="center">Cantidad</StyledTableCell>
                            <StyledTableCell align="center">Fecha de entrega</StyledTableCell>
                            <StyledTableCell align="center">Recibió</StyledTableCell>
                            <StyledTableCell align="center"> </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            entregas.map((row, index) => <Fila key={index} row={row}/>)
                        }
                    </TableBody>
                </Table>

            </TableContainer>
        </Paper>
    );
};

export default LocalesTabla;