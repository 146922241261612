import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {positions, Provider as AlertProvider} from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider} from '@material-ui/pickers'
import GlobalState from "./Context/GlobalContext/GlobalState";
import Login from "./Components/Login/Login.jsx";
import MenuSistemas from "./Components/MenuSistemas/MenuSistemas.jsx";
import Credenciales from "./Components/Credenciales/Credenciales";
import Usuarios from "./Components/Usuarios/Usuarios";
import {library} from '@fortawesome/fontawesome-svg-core'
import {faCheckSquare, faChevronDown} from '@fortawesome/free-solid-svg-icons'
import Clientes from "./Components/Acceso/Clientes";
import RegistrarResidentes from "./Components/Acceso/Components/Residentes/RegistrarResidentes";
import PortalUsuario from "./Components/Acceso/Components/PortalUsuario/PortalUsuario";
import Recuperar from './Components/Login/Recuperar';
import ResetPassword from './Components/Login/ResetPassword';
import Tecnicos from './Components/Acceso/Components/Tecnicos/Tecnicos'

library.add(faChevronDown, faCheckSquare);

function App() {
    const options = {
        timeout: 2500,
        position: positions.BOTTOM_CENTER
    };

    return (
        <AlertProvider template={AlertTemplate} {...options}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <GlobalState>
                    <Router>
                        <Switch>
                            <Route exact path="/" component={Login}/>
                            <Route exact path="/login" component={Login}/>
                            <Route exact path="/panel" component={MenuSistemas}/>
                            <Route path="/credenciales" component={Credenciales}/>
                            <Route path="/usuarios" component={Usuarios}/>
                            <Route path="/clientes" component={Clientes} />
                            <Route exact path='/usuario/residente' component={PortalUsuario}/>
                            <Route path={`/registro/:id`} component={RegistrarResidentes}/>
                            <Route exact path={'/password'} component={Recuperar}/>
                            <Route path="/password/token" component={ResetPassword}/>
                            <Route exact path={'/tickets/tecnicos'} component={Tecnicos}/>
                        </Switch>
                    </Router>
                </GlobalState>
            </MuiPickersUtilsProvider>
        </AlertProvider>
    );
}

export default App;
