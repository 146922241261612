import React from "react";
import "./LoginTextfield.scss";

const LoginTextfield = ({ type, placeholder, name, id, label, icon, onChange }) => {
    return (
        <div className="login-textfield">
            <img src={icon}></img>
            <div className="form__group">
                <input
                    onChange={onChange}
                    type={type}
                    className="form__field"
                    placeholder={placeholder}
                    name={name}
                    id={id}
                />
                <label htmlFor={id} className="form__label">
                    {label}
                </label>
            </div>
        </div>
    );
};

export default LoginTextfield;
