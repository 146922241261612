import React from 'react';
import StyledTabs from "../GlobalesCredenciales/StyledTabs";
import StyledTab from "../GlobalesCredenciales/StyledTab";
import TabPanel from "../GlobalesCredenciales/TabPanel";
import Imprimir from "./Imprimir/Imprimir";
import Laminar from "./Laminar/Laminar";
import CodigoInterno from "./Codigos Internos/CodigoInterno";
import Listos from "./Listos/Listos";


const useStyles = () => ({
    root: {
        flexGrow: 1,
    },
});

const TabsArea = ({states}) => {

    const [value, setValue] = React.useState(0);
    const classes = useStyles();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <div>
                <StyledTabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    <StyledTab label={'Imprimir'} />
                    <StyledTab label={'Laminar'} />
                    <StyledTab label={'Código'}/>
                    <StyledTab label={'Listos'}/>
                </StyledTabs>
            </div>

            <TabPanel value={value} index={0}>
                <Imprimir states={states}/>
            </TabPanel>

            <TabPanel value={value} index={1}>
                <Laminar states={states}/>
            </TabPanel>

            <TabPanel value={value} index={2}>
                <CodigoInterno states={states}/>
            </TabPanel>

            <TabPanel value={value} index={3}>
                <Listos states={states}/>
            </TabPanel>
        </div>
    );
};

export default TabsArea;