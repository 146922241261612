import React, {useEffect, useState} from 'react';
import {CircularProgress} from "@material-ui/core";

import clienteAxios from "../../../../../../config/axios";
import Grid from "@material-ui/core/Grid";
import TablaExternas from "./TablaExternas";

const Externas = () => {

    const [entregas, setEntregas] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const resultado = await clienteAxios.get('api/credenciales/entregasexternas');
            setEntregas(resultado.data);
        }
        fetchData().then()
    }, []);

    const mostrarTabla = () => {
        if(entregas === null){
            return <CircularProgress color={"secondary"}/>
        }
        else if(entregas.length === 0){
            return <h3>No hay datos disponibles para mostrar</h3>
        }
        else{
            return <TablaExternas entregasExternas={entregas}/>
        }
    }

    return (
        <Grid container  justify={'center'} style={{minHeight: '60vh'}}>
            {
                mostrarTabla()
            }
        </Grid>
    );
};

export default Externas;