import React, {useContext, useEffect, useState} from 'react';
import {basicContainer, tituloStyle} from "../../helpers/Styles";
import Grid from "@material-ui/core/Grid";
import TabsArea from "./TabsArea";
import globalContext from "../../../../Context/GlobalContext/globalContext";
import {getLotesTrabajo} from "../../Controllers/AreaTrabajoController";

const AreaTrabajo = () => {

    const context = useContext(globalContext);
    const {activarAreaTrabajo, area, lotes} = context;
    //States de cada tab
    const [imprimir, setImprimir] = useState(null);
    const [laminar, setLaminar] = useState([]);
    const [codigos, setCodigos] = useState([]);
    const [listos, setListos] = useState([]);
    //States de errores
    const [erroresLaminar, setErroresLaminar] = useState(0);
    const [erroresImprimir, setErroresImprimir] = useState(0);
    //State de salir de area de trabajo
    const [salir, setSalir] = useState(true);

    const states = {
        imprimir, setImprimir,
        laminar, setLaminar,
        codigos, setCodigos,
        listos, setListos,
        erroresImprimir, setErroresImprimir,
        erroresLaminar, setErroresLaminar,
        salir
    }

    //Cuando llegan a este componente el sidebar se cierra
    useEffect(() => {
        activarAreaTrabajo(true);
        return () => {
            activarAreaTrabajo(false);
        } //cuando se desmonta el componente el sidebar se activa de nuevo
    }, [area]);

    useEffect(() => {
       getLotesTrabajo(lotes, setImprimir);
    }, [lotes]);

    useEffect(() => {
        if(imprimir === null)return
        if(imprimir.length === 0 && laminar.length === 0 && codigos.length === 0)
            setSalir(false)
    },[imprimir, laminar, codigos])


    return (
        <Grid
            container
            display='flex'
            justify='flex-start'
            component='div'
            direction='column'
            style={basicContainer}
        >
            <Grid item>
                <h1 style={tituloStyle}>Área de trabajo</h1>
            </Grid>

            <Grid item>
                <TabsArea states={states}/>
            </Grid>

        </Grid>
    );
};

export default AreaTrabajo;