import {TableCell, withStyles} from "@material-ui/core";
import myTheme from "../../helpers/Theme";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: myTheme.palette.secondary.light,
        color: myTheme.palette.primary.main,
        fontWeight: '1000',
        fontSize: 14.
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

export default StyledTableCell;