import withStyles from "@material-ui/core/styles/withStyles";
import myTheme from "../../helpers/Theme";
import Tabs from "@material-ui/core/Tabs";

const StyledTabs = withStyles({
    root: {
    },
    indicator: {
        backgroundColor: myTheme.palette.primary.main,
    },
})(Tabs);


export default StyledTabs