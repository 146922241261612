import clienteAxios from "../../../config/axios";
import {objectToFormData} from "object-to-formdata";
import {checkTipo, cleanPoblacionPostal, identificadorPersona} from "../helpers/Util";


const concat = (value1, value2) => {
    return document.getElementById(value1).value + ' ' + document.getElementById(value2).value;
};

export const getCarreras = async (agregarCarreras) => {
    clienteAxios.get('api/credenciales/carreras')
        .then(await (({data}) => agregarCarreras(data)))
};

export const selectedValue = (e, setCarrera) => {
    setCarrera(e.target.value)
};

export const handleChange = (e, persona, nuevaPersona) => {
    nuevaPersona({
        ...persona, [e.target.name]: e.target.value
    })
};

export const handleConcat = (e, persona, nuevaPersona, value1, value2) => {
    nuevaPersona({
        ...persona,
        [e.target.name]: concat(value1, value2)
    })
};


export const fileSelectedHandler = (e, persona, nuevaPersona, alert, id) => {
    const file = e.target.files[0];
    const {type} = file;

    //Validacion de tipo de archivo sin usar atributo 'accept' de input
    if (type !== ('image/jpeg') && type !== ('image/png')) {
        alert.error('El formato de la imagen no es válido');
        document.getElementById(id).value = null;
        return;
    }

    const data = new FormData()
    data.append(e.target.name, file)
    nuevaPersona({
        ...persona,
        [e.target.name]: data.getAll(e.target.name)
    })
};


export const handleSubmit =
    async (e, persona, nuevaPersona, clienteID, tipoPrefix,
           stateInicial, agregarErrores, agregarMensaje, modificando, setModificando, poblacionCodigoPostal, setPoblacionCodigoPostal) => {

        e.preventDefault();

        nuevaPersona(persona['cli_ID'] = clienteID);
        nuevaPersona(
            persona[`${tipoPrefix}_PoblacionCodigoPostal`] = poblacionCodigoPostal['poblacion'] + ' ' + poblacionCodigoPostal['codigoPostal']
        )
        const tipo = checkTipo(tipoPrefix)
        let formData

        if (!modificando) { //Si no se esta modificando nada, se va a hacer un post

            checkDataImages(persona, tipoPrefix)

            formData = (`${tipoPrefix}_Foto` in persona || `${tipoPrefix}_Firma` in persona)
                ? makeFormData(persona, tipoPrefix) : persona;

            clienteAxios.post(`api/credenciales/${tipo}`, formData)
                .then(await (({data}) => exito(nuevaPersona, stateInicial, agregarMensaje, agregarErrores, data, setModificando, setPoblacionCodigoPostal)))
                .catch(await ((error) => {
                agregarErrores(error.response.data.errors);
                nuevaPersona(persona);
            }));


        } else {
            //Si se esta modificando uno ya existente, se hace un put
            let id = persona[`${tipoPrefix}_${identificadorPersona(tipoPrefix)}`]
            //Borrar propiedades innecesarias
            if (persona[`${tipoPrefix}_Correo`] === '')
                delete persona[`${tipoPrefix}_Correo`]
            delete persona['created_at']; delete persona['deleted_at'];
            delete persona[`${tipoPrefix}_created_by`]; delete persona['updated_at']

            //Si hay un string en el campo de foto\firma, lo elimina
            checkDataImgString(persona, tipoPrefix)

            //Si existe una foto o una firma, tiene que hacer un formData para agregar las imagenes
            // y se tienen que mandar con ese url
            if(`${tipoPrefix}_Foto` in persona || `${tipoPrefix}_Firma` in persona){
                formData = makeFormData(persona, tipoPrefix)
                // url = `api/credenciales/${tipo}/${id}?_method=PUT`
            }
            // Si no existe la propiedad de foto y firma, manda el objeto de persona sin tener que hacer formData
            else{
                formData = persona
                // url = `api/credenciales/${tipo}/${id}`
            }

            //Promise para mandar el
            clienteAxios.post(`api/credenciales/${tipo}/${id}?_method=PUT`, formData)
                .then(await (({data}) =>
                    exito(nuevaPersona, stateInicial, agregarMensaje, agregarErrores, data, setModificando, setPoblacionCodigoPostal)))
                .catch(await (error => {
                        agregarErrores(error.response.data.errors)
                        nuevaPersona(persona)
                    }
                ));

        }
    }

const makeFormData = (persona, tipoPrefix) => {
    const formData = objectToFormData(persona);
    if( `${tipoPrefix}_Foto` in persona){
        const foto = formData.get(`${tipoPrefix}_Foto[]`);
        formData.append(`${tipoPrefix}_Foto`, foto);
    }
    if(`${tipoPrefix}_Firma` in persona){
        const firma = formData.get(`${tipoPrefix}_Firma[]`);
        formData.append(`${tipoPrefix}_Firma`, firma);
    }

    return formData;
}

const checkDataImages = (data, tipoPrefix) => {
    if(data[`${tipoPrefix}_Foto`] === null)
        delete data[`${tipoPrefix}_Foto`]
    if(data[`${tipoPrefix}_Firma`] === null)
        delete data[`${tipoPrefix}_Firma`]
}

const checkDataImgString = (data, tipoPrefix) => {

    console.log(typeof data[`${tipoPrefix}_Foto`])
    console.log(typeof data[`${tipoPrefix}_Firma`])

    if(typeof data[`${tipoPrefix}_Foto`] === 'string' || data[`${tipoPrefix}_Foto`] === null)
        delete data[`${tipoPrefix}_Foto`]
    if(typeof data[`${tipoPrefix}_Firma`] === 'string' || data[`${tipoPrefix}_Firma`] === null)
        delete data[`${tipoPrefix}_Firma`]
}

const exito = (nuevaPersona, stateInicial, agregarMensaje, agregarErrores, data, setModificando, setPoblacionCodigoPostal) => {
    cleanPoblacionPostal();
    nuevaPersona(stateInicial);
    setPoblacionCodigoPostal(state => ({
        ...state,
        poblacion: '',
        codigoPostal: '',
    }))
    agregarMensaje('');
    agregarMensaje(data.mensaje);
    agregarErrores({});
    setModificando(false);
}
