import React, {useEffect, useState} from 'react';
import InterfazUsuario from "../PortalUsuario/InterfazUsuario";
import Grid from "@material-ui/core/Grid";
import VerificarResidentes from "./VerificarResidentes";
import StyledTab from "./StyledTab";
import StyledTabs from "./StyledTabs";
import TabPanel from "./TabPanel";
import {getResidentesPorPrivada} from "../../Controllers/PortalController";

const useStyles = () => ({
    root: {
        flexGrow: 1,
        maxWidth: '90%',
        width: '1200px',
    },
    container: {
        maxWidth: '1200px',
    },
});

const Presidente = ({states, UserID}) => {

    const { idPrivada, residente, setResidente,listaResidentes, setListaResidentes, listaVisitantes, setListaVisitantes, setMensaje} = states;
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [residentesPrivada, setResidentesPrivada] = useState([]);

    useEffect(()=> {
        getResidentesPorPrivada(setResidentesPrivada, idPrivada).then();
    },[])

    return (
        <div className={classes.root}>
            <Grid container direction="row" justify="space-between" alignItems="center" className={classes.container}>
                    <StyledTabs value={value} onChange={(event, newValue)=>setValue(newValue)} aria-label="Lotes">
                        <StyledTab label={'Residentes'}/>
                        <StyledTab label={'Modificar información'}/>
                    </StyledTabs>
            </Grid>

            <TabPanel value={value} index={0}>
                <VerificarResidentes
                    idPrivada={idPrivada}
                    residentesPrivada={residentesPrivada}
                    setResidentesPrivada={setResidentesPrivada}
                    setMensaje={setMensaje}
                />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <InterfazUsuario
                    residente={residente}
                    setResidente={setResidente}
                    listaResidentes={listaResidentes}
                    setListaResidentes={setListaResidentes}
                    listaVisitantes={listaVisitantes}
                    setListaVisitantes={setListaVisitantes}
                    setMensaje={setMensaje}
                    UserID={UserID}
                    presidente
                />
            </TabPanel>
        </div>
    );
};

export default Presidente;
