
export const basicContainer = {
    width: '100%',
    minHeight: '100vh',
    zIndex: -1,
    paddingTop: '10vh',
    paddingLeft: '10vw',
    paddingRight: '10vw',
};

export const widthContainer = {
    width: '100%',
    minHeight: '100vh',
    zIndex: -1,
    paddingTop: '10vh',
    paddingLeft: '5vw',
    paddingRight: '5vw',
}

export const thinContainer = {
    width: '800px',
    minHeight: '100vh',
    zIndex: -1,
    paddingTop: '10vh',
    paddingLeft: '10vw',
    paddingRight: '10vw',
};



export const tituloStyle = {
    textAlign: 'center',
};

