import React, { useState, useContext, useEffect } from "react";
import Logo from "../../assets/images/sensor_rojo.png";
import "./Login.scss";
import EmailIcon from "../../assets/images/iconmonstr-email-3.svg";
import KeyIcon from "../../assets/images/iconmonstr-key-3.svg";
import LoginTextfield from "./LoginTextfield";
import LoginButton from "./LoginButton.jsx";
import { Link } from "react-router-dom";
import { LoginFormController as Controller } from "../../Controllers/LoginFormController";
import { useAlert } from "react-alert";
import globalContext from "../../Context/GlobalContext/globalContext";
import Circulos from "../Circulos/Circulos.jsx";

export const handleChange = (e, setState) => {
    setState(e.target.value );
}

const Login = (props) => {

    const [correo, setCorreo] = useState("");
    const [password, setPassword] = useState("");

    const GlobalContext = useContext(globalContext);
    const { iniciarSesion, session, mensaje, usuario } = GlobalContext;
    const { handleSubmit } = new Controller();
    
    

    const alert = useAlert();

    useEffect(() => {
        if (session) {
            if (usuario === undefined) return;

            console.log(usuario.UsTipo)

            if (usuario.UsTipo === 8) {
                props.history.push("/usuario/residente");
                return;
            }
            else if(usuario.UsTipo === 6){
                props.history.push("/tickets/tecnicos");
                return;
            }
            props.history.push("/panel");
        }
        if (mensaje) alert.error(mensaje);
    }, [session, mensaje, usuario]);

    return (
        <div id="login-wrapper">
            <img className="logo-sensor slide-fwd-center" src={Logo} />
            <form
                onSubmit={(e) =>
                    handleSubmit(e, correo, password, iniciarSesion)
                }
                className="login-form"
            >
                <LoginTextfield
                    type="email"
                    placeholder="Correo"
                    name="Correo"
                    id="Correo"
                    label="Correo"
                    icon={EmailIcon}
                    onChange={(e) => handleChange(e, setCorreo)}
                />
                <LoginTextfield
                    type="password"
                    placeholder="Contraseña"
                    name="Contraseña"
                    id="Contraseña"
                    label="Contraseña"
                    icon={KeyIcon}
                    onChange={(e) => handleChange(e, setPassword)}
                />
                <span className="login__recover-password">
                    <Link to={"/password"}>Recuperar contraseña</Link>
                </span>
                <LoginButton label="Ingresar"/>
            </form>

            <Circulos/>
        </div>
    );
};

export default Login;
