import {withStyles} from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import React from "react";
import myTheme from "../../helpers/Theme";
import Grow from "@material-ui/core/Grow";



const StyledMenu = withStyles({
    paper: {
        backgroundColor: myTheme.palette.primary.main,
        marginLeft: '54px',
        zIndex: -1,
    },
})
(props => (
    <Menu
        elevation={0}
        TransitionComponent={Grow}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

export default StyledMenu