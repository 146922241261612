import {checkTipo} from "../helpers/Util";
import clienteAxios from "../../../config/axios";
import ModificarDialog from "../Components/Registrar/Clientes/UAdO/Components/ModificarDialog";
import React from "react";
import {renderIntoDocument} from "react-dom/test-utils";

export const onBlurHandler = async (e, tipoPrefix, setPersona, id, stateInicial,setModificando, setPoblacionCodigoPostal, clienteID, alert) => {
    e.preventDefault();
    if(id === '') return;
    let tipo = checkTipo(tipoPrefix);

    clienteAxios.get(`api/credenciales/${tipo}/${id}`)
        .then(await (({data}) => {
            const mergedData = {...stateInicial,...data}
            setPersona(mergedData);
            let cadena = mergedData[`${tipoPrefix}_PoblacionCodigoPostal`]

            setPoblacionCodigoPostal((prevState) => ({
                ...prevState,
                    ['poblacion']: cadena.substring(0, cadena.length - 6).trim(),
                    ['codigoPostal']: cadena.substring(cadena.length - 5, cadena.length)
            }))
            const info = {
                id: id,
                nombre: getNombre(data, tipoPrefix),
                apellidos: getApellidos(data, tipoPrefix)
            }
            renderIntoDocument(
                <ModificarDialog
                    informacion={info}
                    setPersona={setPersona}
                    stateInicial={stateInicial}
                    setActivo={setModificando}
                    clienteID={clienteID}
                    alert={alert}
                />)
        }))

}

export const modificarAction = (e,setOpen,setModificando) => {
    e.preventDefault();
    setOpen(false);
    setModificando(true);
}

export const agregarAction = async (e, clienteID, setOpen,id,setMensaje,setPersona,state) => {

    e.preventDefault();

    const data = {
        cli_ID: clienteID,
        per_ID: parseInt(id)
    }


    clienteAxios.post(`api/credenciales/clientespersonas`, data)
        .then(await(({data})=> {
            setMensaje('');
            setMensaje(data.mensaje);
            setOpen(false);
            setPersona(state)
    }))

}


const getNombre = (data, tipo) => {
    switch (tipo) {
        case 'emp':
            return data.emp_Nombre;
        case 'doc':
            return data.doc_Nombre;
        case 'est':
            return data.est_Nombre;
        default:
            return null
    }
}

const getApellidos = (data, tipo) => {
    switch (tipo) {
        case 'emp':
            return (data.emp_ApellidoPaterno + ' ' + data.emp_ApellidoMaterno);
        case 'doc':
            return (data.doc_ApellidoPaterno + ' ' + data.doc_ApellidoMaterno);
        case 'est':
            return (data.est_ApellidoPaterno + ' ' + data.est_ApellidoMaterno);
        default:
            return null
    }
}



