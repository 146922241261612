import React, {Fragment, useEffect, useState} from 'react';
import Slide from "@material-ui/core/Slide";
import Icon from "@material-ui/core/Icon";
import myTheme from "../../../helpers/Theme";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import CredencialesTabla from "./CredencialesTabla";
import {finalizarLote} from "../../../Controllers/AreaTrabajoController";
import {useAlert} from "react-alert";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DialogCodigos = ({row, states}) => {

    const {ID, Credenciales} = row;
    const {codigos, setCodigos, listos, setListos} = states;
    const moverState = {
        fila: row,
        setSiguiente: setListos,
        sigState: listos,
        stateActual: codigos,
        setStateActual: setCodigos
    }

    const alert = useAlert();
    const [open, setOpen] = useState(false);
    const [codigosInternos, setInternos] = useState({});
    const [mensaje, setMensaje] = useState('')

    useEffect(()=> {
        if(mensaje === '') return
        alert.error(mensaje)
    },[mensaje])

    const handleClickOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    return (
        <Fragment>
            <IconButton aria-label='Hecho' onClick={handleClickOpen}>
                <Icon className='fa fa-check-circle' style={{color: myTheme.palette.primary.main}}/>
            </IconButton>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby='dialog-codigos-internos'
                aria-describedby='dialog-codigos-internos-tabla'
            >
                <DialogTitle id='codigos-internos'>Códigos internos</DialogTitle>
                <DialogContent>
                    <CredencialesTabla row={row} state={codigosInternos} setState={setInternos}/>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={
                            () => {
                                finalizarLote(ID, codigosInternos, Credenciales, moverState, setMensaje);
                                handleClose();
                            }
                        }
                        color="primary">
                        Guardar
                    </Button>
                </DialogActions>

            </Dialog>
        </Fragment>
    );
};

export default DialogCodigos;