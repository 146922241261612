import { TextField, Paper, IconButton, Grid, Tooltip } from "@material-ui/core";
import React, { useState } from "react";
import { withStyles, ThemeProvider } from "@material-ui/styles";
import ClearIcon from "@material-ui/icons/Clear";
import AddIcon from "@material-ui/icons/Add";
import theme from "../Theme";
import "./RegistrarResidentes.scss";

export const ColorButton = withStyles((theme) => ({
    root: {
        color: "#C42E2E",
        backgroundColor: "transparent",
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
}))(IconButton);

const ListaAgregar = ({ concepto, lista, setState, setMensaje }) => {
    const handleDelete = (index) => {
        const newArray = [...lista];
        newArray.splice(index, 1);
        setState(newArray);
    };

    return (
        <div>
            {/* Header */}
            <p className="contador-residentes">{`${lista.length} ${concepto}`}</p>
            <ListaCajas lista={lista} handleDelete={handleDelete} />
            <SubmitCaja
                lista={lista}
                setState={setState}
                setMensaje={setMensaje}
            />
        </div>
    );
};

const ListaCajas = ({ lista, handleDelete }) => {
    return (
        <div>
            {lista.length === 0
                ? null
                : lista.map((item, index) => (
                      <Caja
                          item={item}
                          key={index}
                          id={index}
                          handleDelete={handleDelete}
                      />
                  ))}
        </div>
    );
};

const Caja = (props) => {
    const { item, handleDelete } = props;

    return (
        <Grid container justify="space-evenly">
            <Grid item xs={10}>
                <p className="nombres">{`${item.Nombre} ${item.Apellidos}`}</p>
            </Grid>
            <Grid item xs={1}>
                <Tooltip title="Eliminar">
                    <ColorButton onClick={() => handleDelete(props.id)}>
                        <ClearIcon />
                    </ColorButton>
                </Tooltip>
            </Grid>
        </Grid>
    );
};

const SubmitCaja = ({ setState, lista, setMensaje }) => {
    const [info, setInfo] = useState({ Nombre: "", Apellidos: "" });

    const handleAddBox = () => {
        setMensaje("");
        // Checar si la caja trae espacios en blanco
        if (info.Nombre === "" || info.Apellidos === "") {
            setMensaje({
                msg: "Uno de los espacios está en blanco",
                error: true,
            });
            return;
        }

        setState([...lista, info]);
        setInfo({ Nombre: "", Apellidos: "" });
    };

    const handleChange = (e) =>
        setInfo({ ...info, [e.target.name]: e.target.value });

    return (
        <Grid
            container
            justify="space-evenly"
            style={{ width: "100%" }}
            spacing={1}
        >
            <Grid item xs={5}>
                <TextField
                    label={"Nombre"}
                    name={"Nombre"}
                    value={info.Nombre}
                    fullWidth
                    onChange={(e) => handleChange(e)}
                />
            </Grid>
            <Grid item xs={5}>
                <TextField
                    label={"Apellidos"}
                    name={"Apellidos"}
                    value={info.Apellidos}
                    fullWidth
                    onChange={(e) => handleChange(e)}
                />
            </Grid>
            <Grid item xs={1}>
                <Tooltip title="Agregar">
                    <IconButton onClick={handleAddBox}>
                        <AddIcon />
                    </IconButton>
                </Tooltip>
            </Grid>
        </Grid>
    );
};

export default ListaAgregar;
