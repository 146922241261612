import React, {Fragment, useEffect, useState} from 'react';
import {TableContainer, TableRow} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import StyledTableCell from "../../GlobalesCredenciales/StyledTableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import StyledRow from "../../GlobalesCredenciales/StyledRow";
import StyledCheckbox from "../../GlobalesCredenciales/StyledCheckbox";

const ListosTabla = ({listos}) => {

    const Fila = ({row}) => {

        const handleChange = e => row.Checked = e.target.checked;

        return (
            <StyledRow key={row['ID']}>
                <StyledTableCell scope="row">{row.ID}</StyledTableCell>
                <StyledTableCell align="center">{row['Solicitud']}</StyledTableCell>
                <StyledTableCell align="center">{row['Cantidad']}</StyledTableCell>
                <StyledTableCell align="center">{row['Fecha']}</StyledTableCell>
                <StyledTableCell align="center" style={{padding: 0, margin: 0}}>
                    <StyledCheckbox name={row.ID} onChange={e => handleChange(e)} />
                </StyledTableCell>
            </StyledRow>
        )
    }

    return (
        <Paper style={{width: '100%'}}>
            <TableContainer style={{maxHeight: '70vh'}}>
                <Table stickyHeader aria-label="Lotes listos">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Folio</StyledTableCell>
                            <StyledTableCell align="center">Solicitud</StyledTableCell>
                            <StyledTableCell align="center">Cantidad</StyledTableCell>
                            <StyledTableCell align="center">Fecha</StyledTableCell>
                            <StyledTableCell align="center"> </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                       {listos.map((row,index)=> <Fila key={index} row={row}/>) }
                    </TableBody>
                </Table>

            </TableContainer>

        </Paper>
    );
};




export default ListosTabla;