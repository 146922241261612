import React, { useState, useContext, useEffect } from "react";
import Logo from "../../assets/images/sensor_rojo.png";
import "./Login.scss";
import KeyIcon from "../../assets/images/iconmonstr-key-3.svg";
import LoginTextfield from "./LoginTextfield";
import LoginButton from "./LoginButton.jsx";
import { Link } from "react-router-dom";
import { cambiarContraseña } from "../../Controllers/LoginFormController";
import { useAlert } from "react-alert";
import Circulos from "../Circulos/Circulos.jsx";
import { useHistory, useLocation } from 'react-router';
import {handleChange} from './Login'

const ResetPassword = () => {

    const search = useLocation().search;
    const history = useHistory();
    const alert = useAlert();

    const email = new URLSearchParams(search).get('email')
    const token = new URLSearchParams(search).get('token')
    // const [email, setEmail] = useState()
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [mensaje, setMensaje] = useState('')
    // const [token, setToken] = useState()

    useEffect(() => {
        if(mensaje === "") return;
        
        if(!mensaje.error){
            alert.info(mensaje.msg)
        }else{
            alert.error(mensaje.msg);
        }
            
    
        return () => {
            setMensaje("")
        }
    }, [mensaje])


    return (
        <div id='login-wrapper'>
            <img className="logo-sensor slide-fwd-center" src={Logo}/>
            <p className="texto-leyenda">
                Para completar el cambio de contraseña exitosamente, ingrese su nueva contraseña y confirme.
                Una vez completados estos pasos, tendrá acceso a los servicios de Sensor México con su cuenta.
            </p>
            <form onSubmit={(e)=> cambiarContraseña(e, password, password2, token, email, setMensaje, history)} className="login-form">
                <LoginTextfield
                    type="password"
                    placeholder="Contraseña"
                    name="Contraseña"
                    id="Contraseña"
                    label="Contraseña"
                    icon={KeyIcon}
                    onChange={(e) => handleChange(e, setPassword)}
                />
                <LoginTextfield
                    type="password"
                    placeholder="Confirmar contraseña"
                    name="Confirmar-Contraseña"
                    id="Confirmar-Contraseña"
                    label="Confirmar contraseña"
                    icon={KeyIcon}
                    onChange={(e) => handleChange(e, setPassword2)}
                />
                <LoginButton label="Cambiar contraseña" />
            </form>

            <Circulos/>
        </div>
    )
}

//http://localhost:3000/password/token?email=09mgr.15@gmail.com&token=123456789asdasdadsa

export default ResetPassword
