import React from 'react';
import {basicContainer} from "../../helpers/Styles";
import Grid from "@material-ui/core/Grid";
import TabLotes from "./TabLotes";

const Lotes = () => {
    return (
        <Grid
            container
            display='flex'
            justify='flex-start'
            alignItems='center'
            component='div'
            direction='column'
            style={basicContainer}
        >
            <Grid item>
                <h1 style={{textAlign: 'center', marginTop: '5vh'}}>Lotes</h1>
            </Grid>
            <Grid item style={{width: '100%'}}>
                <TabLotes/>
            </Grid>
        </Grid>
    );
};

export default Lotes;