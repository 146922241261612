import clienteAxios from "../config/axios";

export class LoginFormController {
    constructor() {
    }
    handleSubmit = (e,email,password,iniciarSesion) => {
        e.preventDefault();
        iniciarSesion({email,password});
    }
    handleChange = (e,cambiar) =>
    {
        cambiar(e.target.value)
    }
}


export const enviarCorreoRecuperar = async (e,correo, setMensaje) => {
     
    e.preventDefault();

    clienteAxios.post(`api/password/email`, null ,{params: {email: correo}})
    .then(({data})=> {
        setMensaje({msg: data.Mensaje, error: false})
    })
    .catch((error) => {
        setMensaje({msg: error.response.data.Mensaje, error: true})
    })


}


export const cambiarContraseña = async (e, password, password2, token, correo, setMensaje, history) => {

    e.preventDefault();

    const params = {
        email: correo,
        token: token,
        password: password,
        password_confirmation: password2
    }

    clienteAxios.post(`api/password/reset`, null, {params: params})
        .then(({data}) => {
            setMensaje({msg: data.Mensaje, error: false})
            history.push('/login')
        })
        .catch((error) => {
            setMensaje({msg: error.response.data.Mensaje, error: true})
        })

}
