import React, {Fragment, useState, useContext} from 'react';
import '../../../../assets/styles/Navbar/navs.scss'
import globalContext from '../../../../Context/GlobalContext/globalContext'
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import StyledMenu from "./StyledMenu";
import StyledMenuItem from "./StyledMenuItem";
import {ListItemIcon} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import {useHistory} from 'react-router-dom';
import ListItem from "@material-ui/core/ListItem";

const divStyle = {
    height: "100%",
    marginRight: '1vw'
};

const useStyles = makeStyles({
    button: {
        color: 'white',
    },
});


const UsuarioMenu = () => {

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const classes = useStyles();

    const context = useContext(globalContext);
    const history = useHistory();
    const {usuario, cerrarSesion} = context;

    function redireccionar() {
        history.push('/login');
    }

    return (
        <Box component="div" display="flex" alignItems="center" justifyContent="flex-end" style={divStyle}>

            <Button className={classes.button} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                {usuario === null ? '' : `${usuario.UsNombre} ${usuario.UsApPat}`}
            </Button>

            <StyledMenu
                id="userMenu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <StyledMenuItem>
                        <Button onClick={() => cerrarSesion(redireccionar)}>
                            <Icon
                                className="fa fa-sign-out-alt"
                                style={{color: '#666', marginRight: '5px'}}
                            />
                            Salir
                        </Button>
                </StyledMenuItem>
            </StyledMenu>
        </Box>
    );
};

export default UsuarioMenu
