import clienteAxios from "../../../config/axios";

export const registrarPrivada = async (e, privada, setMensaje, initialState, setPrivadas) => {

    e.preventDefault();
    setMensaje('');
    clienteAxios.post('api/clientes/privada',privada)
        .then(()=> {
            setMensaje({msg: "Se ha registrado la privada con éxito", error: false});
            setPrivadas(initialState);
        })
        .catch(()=> {
            setMensaje({msg: 'Error al registrar la privada', error: true})
        });

}

export const getPrivadas = async (setPrivadas) => {
    const response = await clienteAxios.get('api/monitoreo/privadas')
    setPrivadas(response.data)
}


export const getDatosPrivada = async (idPrivada, setPrivada) => {
    const response = await clienteAxios.get(`api/monitoreo/privada/${idPrivada}`);
    setPrivada(response.data);
}

export const getStatusCasa = async (idPrivada, numCasa,setResponse) => {
    const response = await clienteAxios.get(`api/casa/${idPrivada}/${numCasa}`);
    setResponse(response.data);
}


