import React, {useEffect, useState} from "react";
import {UsuariosEditController as Controller} from "../../Controllers/UsuariosEditController";
import {useAlert} from "react-alert";
import {
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Button
} from '@material-ui/core';


const margen  = {
    marginTop: '10vh'
};

const UsuariosEditForm = ({user,finalizarEdit}) =>
{
    const [usuario , editarUsuario] = useState({});
    const [tiposDeUsuario,agregarTipos] = useState([]);
    const [errores,agregarErrores] = useState({});
    const {
            obtenerTipos,
            handleChange,
            handleSubmit,
            obtenerUsuario,
            regresar
    } = new Controller(
                        usuario,
                        tiposDeUsuario,
                        errores,
                        editarUsuario,
                        agregarTipos,
                        agregarErrores,
                        finalizarEdit
                        );

    useEffect(()=>{
        obtenerTipos();
        obtenerUsuario(user);
    },[]);

    return(
        <form className='col-12' onSubmit={(e) => handleSubmit(e)}>
            <div className='row col-sm-10 col-md-12 justify-content-sm-end  justify-content-md-center'>
                <div className='col-4'>
                    <TextField
                        defaultValue=' '
                        type='text'
                        name='UsNombre'
                        label='Nombre'
                        onChange={(e)=> handleChange(e)}
                        value={usuario.UsNombre}
                        fullWidth
                        error={errores.UsNombre?true:false}
                        helperText={errores.UsNombre}
                    />
                </div>
                <div className='col-3'>
                    <TextField
                        defaultValue=' '
                        type='text'
                        name='UsApPat'
                        label='Apellido Paterno'
                        onChange={(e)=> handleChange(e)}
                        value={usuario.UsApPat}
                        fullWidth
                        error={errores.UsApPat?true:false}
                        helperText={errores.UsApPat}
                    />
                </div>
                <div className='col-3'>
                    <TextField
                        defaultValue=' '
                        type='text'
                        name='UsApMat'
                        label='Apellido Materno'
                        onChange={(e)=> handleChange(e)}
                        value={usuario.UsApMat}
                        fullWidth
                        error={errores.UsApMat?true:false}
                        helperText={errores.UsApMat}
                    />
                </div>
            </div>
            <div style={margen} className='row col-sm-10 col-md-12 justify-content-sm-end  justify-content-md-center'>
                <div className='col-4'>
                    <TextField
                        defaultValue='@'
                        type='email'
                        name='email'
                        label='Correo Electrónico'
                        onChange={(e)=> handleChange(e)}
                        value={usuario.email}
                        fullWidth
                        error={errores.email?true:false}
                        helperText={errores.email}
                    />
                </div>
                <div className='col-3'>
                    <FormControl
                        fullWidth
                        error={errores.UsTipo?true:false}
                        helperText={errores.UsTipo}
                    >
                        <InputLabel>Tipo de usuario</InputLabel>
                        <Select
                            name='UsTipo'
                            onChange={(e)=> handleChange(e)}
                            value={usuario.UsTipo+""}
                        >
                            <MenuItem>Seleccione</MenuItem>
                            {tiposDeUsuario.map(tipo=>
                                <MenuItem key={tipo.TuID} value={tipo.TuID+""}>{tipo.UsTipo}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </div>
                <div className='col-3'>
                    <TextField
                        defaultValue='2000-01-01'
                        type='date'
                        name='UsFechaNac'
                        label='Fecha de nacimiento'
                        onChange={(e)=> handleChange(e)}
                        value={usuario.UsFechaNac}
                        fullWidth
                        error={errores.UsFechaNac?true:false}
                        helperText={errores.UsFechaNac}
                    />
                </div>
            </div>
            <div style={margen} className={'row col-sm-8  col-md-12 justify-content-sm-end  justify-content-md-center mt-5'}>
                <div className='col-4'>
                    <TextField
                        type={'password'}
                        name={'password'}
                        label={'Nueva contraseña'}
                        value={usuario.password}
                        onChange={(e)=> handleChange(e)}
                        fullWidth
                        error={errores.password?true:false}
                        helperText={errores.password}
                    />
                </div>
                <div className='col-3'>
                    <TextField
                        type={'password'}
                        name={'confirmar'}
                        label={'Confirmar Contraseña'}
                        value={usuario.confirmar}
                        onChange={(e)=> handleChange(e)}
                        fullWidth
                        error={errores.confirmar?true:false}
                        helperText={errores.confirmar}
                    />
                </div>
                <div className='col-3'>
                    <TextField
                        type={'password'}
                        name={'passwordActual'}
                        label={'Contraseña actual'}
                        value={usuario.passwordActual}
                        onChange={(e)=> handleChange(e)}
                        fullWidth
                        error={errores.passwordActual?true:false}
                        helperText={errores.passwordActual}
                    />
                </div>
            </div>
            <div style={margen} className='row justify-content-center col-12' >
                <Button
                    variant="contained"
                    color='secondary'
                    className='col-10 col-md-3'
                    type='submit'
                    fullWidth
                    style={{marginRight : '1vh'}}
                >Actualizar</Button>
                <Button
                    variant="contained"
                    color='secondary'
                    className='col-10 col-md-3'
                    type='button'
                    onClick={(e)=>regresar(e)}
                    fullWidth
                >Regresar</Button>
            </div>
        </form>
    );
}

export default UsuariosEditForm;
