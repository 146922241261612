import React, {Fragment, useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import '../../../../assets/styles/InputFile.scss'
import {makeStyles} from "@material-ui/styles";
import myTheme from '../../helpers/Theme'

const useStyles = makeStyles({
    caja: {
        borderRadius: '10px',
        border: '1px solid ',
        borderColor: myTheme.palette.secondary.main,
    },
    texto: {
        paddingRight: '15px',
        paddingLeft: '15px',
    },
    error: {
        color: '#f44336',
        margin: 0,
        fontSize: '0.75rem',
        marginTop: '3px',
        textAlign: 'left',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        lineHeight: 1.66,
        letterSpacing: '0.033333em'
    },
})

const InputFile = ({id, name, icono, accept, objeto, setObjeto, error, helperText}) => {

    const classes = useStyles();
    const [archivo, setArchivo] = useState(null);

    useEffect(()=> {
        setArchivo(objeto[name]);
    }, [objeto[name]])

    const fileSelectedHandler = (e, objeto, setObjeto) => {
        const file = e.target.files[0];

        const data = new FormData();
        data.append(e.target.name, file);
        setObjeto({
            ...objeto,
            [e.target.name]: data.getAll(e.target.name)
        });
    }

    const stringFile = () => {
        if(archivo === undefined){
            setArchivo(null);
            return;
        }

        if(typeof archivo === 'string')
            return archivo;
        else
            return archivo[0].name;
    }

    return (
        <Fragment>
            <Grid container direction={'row'} alignItems={'center'} spacing={1}>
                {
                    icono === undefined ? null :
                        <Grid item>
                            <Icon className={`fa fa-${icono}`}/>
                        </Grid>
                }
                <Grid item xs>
                    <Grid container direction={'row'} alignItems={'center'} className={classes.caja}>
                        <Grid item xs m={0} p={0}>
                            <p className={classes.texto}>
                                {archivo !== null ? stringFile(): 'No hay archivo seleccionado'}
                            </p>
                        </Grid>
                        <Grid item m={0} p={0}>
                            <input
                                type='file'
                                id={id}
                                accept={accept}
                                name={name}
                                onChange={e => fileSelectedHandler(e, objeto, setObjeto, setArchivo, accept)}
                            />
                            <label htmlFor={id} className={'botonSelect'}><span>Seleccionar</span></label>
                        </Grid>

                    </Grid>
                    {error ?
                        <p className={classes.error}>{helperText}</p>
                        :
                        null
                    }
                </Grid>
            </Grid>

        </Fragment>

    );
};




export default InputFile;