import React from 'react';
import StyledRow from "../GlobalesCredenciales/StyledRow";
import StyledTableCell from "../GlobalesCredenciales/StyledTableCell";
import Icon from "@material-ui/core/Icon";
import myTheme from "../../helpers/Theme";
import IconButton from "@material-ui/core/IconButton";

const Fila = ({row, onClick}) => {
    return (
        <StyledRow key={row['ID']}>
            <StyledTableCell scope="row">{row.ID}</StyledTableCell>
            <StyledTableCell align="center">{row['Solicitud']}</StyledTableCell>
            <StyledTableCell align="center">{row['Cantidad']}</StyledTableCell>
            <StyledTableCell align="center">{row['Fecha']}</StyledTableCell>
            <StyledTableCell align='center'>
                {row['Especial'] === 1 ?
                    <Icon className='fa fa-star' style={{color: myTheme.palette.secondary.dark}}/>
                    :
                    null
                }
            </StyledTableCell>
            {
                onClick ?
                    <StyledTableCell align="center" style={{padding: 0, margin: 0}}>
                        <IconButton aria-label='Hecho' onClick={onClick}>
                            <Icon className='fa fa-check-circle' style={{color: myTheme.palette.primary.main}}/>
                        </IconButton>
                    </StyledTableCell>
                    :
                    null
            }

        </StyledRow>
    );
};

export default Fila;