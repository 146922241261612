import React, {Fragment, useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {TextField} from "@material-ui/core";
import {handleChange} from "../../../Credenciales/Components/GlobalesCredenciales/GlobalTextField";
import {DatePicker} from "@material-ui/pickers";
import PasswordField from "./PasswordField";
import {fechaString} from "../../../Credenciales/helpers/Util";
import { getStatusCasa } from '../../Controllers/PrivadasController';

const CamposTexto = ({residente, setResidente, errores, selectedDate, setDate, idPrivada, setResponse}) => {

    const handleDateChange = (date) => setDate(date);

    useEffect(() => {
        if (selectedDate === null)
            return;
        setResidente({...residente, UsFechaNac: fechaString(selectedDate)})
    }, [selectedDate]);

    return (
        <Fragment>
            <Grid item xs={12}>
                <TextField
                    label='Nombre'
                    id={'UsNombre'}
                    name={'UsNombre'}
                    value={residente.UsNombre}
                    error={!!errores.UsNombre}
                    helperText={errores.UsNombre}
                    onChange={e => handleChange(e, residente, setResidente)}
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    label='Apellido paterno'
                    id={'UsApPat'}
                    name={'UsApPat'}
                    value={residente.UsApPat}
                    error={!!errores.UsApPat}
                    helperText={errores.UsApPat}
                    onChange={e => handleChange(e, residente, setResidente)}
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    label='Apellido materno'
                    id={'UsApMat'}
                    name={'UsApMat'}
                    value={residente.UsApMat}
                    error={!!errores.UsApMat}
                    helperText={errores.UsApMat}
                    onChange={e => handleChange(e, residente, setResidente)}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={8}>
                <TextField
                    label='Correo'
                    id={'email'}
                    name={'email'}
                    value={residente.email}
                    error={!!errores.email}
                    helperText={errores.email}
                    type='email'
                    onChange={e => handleChange(e, residente, setResidente)}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <PasswordField
                    id={'password'}
                    label='Contraseña'
                    value={residente.password}
                    name={'password'}
                    error={!!errores.password}
                    helperText={errores.password}
                    onChange={e => handleChange(e, residente, setResidente)}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField
                    label='Teléfono'
                    id={'ua_telefono'}
                    name={'ua_telefono'}
                    value={residente.ua_telefono}
                    error={!!errores.ua_telefono}
                    helperText={errores.ua_telefono}
                    type='number'
                    onChange={e => handleChange(e, residente, setResidente)}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField
                    label='Número de casa'
                    id={'numCasa'}
                    name={'numCasa'}
                    value={residente.numCasa}
                    error={!!errores.numCasa}
                    helperText={errores.numCasa}
                    type='number'
                    onChange={e => handleChange(e, residente, setResidente)}
                    onBlur= {e => getStatusCasa(idPrivada, residente.numCasa,setResponse)}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <DatePicker
                    disableFuture
                    openTo='year'
                    format='dd/MM/yyyy'
                    label='Fecha de nacimiento'
                    views={['year', 'month', 'date']}
                    value={selectedDate}
                    onChange={date => handleDateChange(date)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label='Comentarios'
                    id={'comentarios'}
                    name={'comentarios'}
                    value={residente.comentarios}
                    error={!!errores.comentarios}
                    multiline
                    helperText={errores.comentarios}
                    onChange={e => handleChange(e, residente, setResidente)}
                    fullWidth
                />
            </Grid>
       </Fragment>
    );
};

export default CamposTexto;
