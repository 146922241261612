import React from "react";
import  {NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import {Link} from "react-router-dom";

const SideBarItem = ({nombre,icono,url}) =>
{
    return (
        <NavItem eventKey={nombre?nombre:''}>

            <NavIcon>
                <Link to={url} style={{textDecoration : 'none'}}><i className={icono} style={{fontSize: '1.75em'}} /></Link>

            </NavIcon>
            {nombre?
                <NavText style={{ fontSize : '1rem'}}>
                    <Link to={url} style={{textDecoration : 'none'}}>{nombre}</Link>
                </NavText>
            :''}
        </NavItem>
    );
};

export default SideBarItem;