import React from 'react';
import {FormControlLabel, RadioGroup} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import {handleChange} from "../../GlobalesCredenciales/GlobalTextField";
import PropTypes from 'prop-types'

const RadioTipos = ({state, setState, name}) => {


    return (
        <Grid item xs={12} >
            <RadioGroup
                aria-label='tipo'
                name={name}
                value={state[name]}
                onChange={e => handleChange(e,state,setState)}
            >
                <Grid container
                      direction="row"
                      justify="space-evenly"
                      alignItems='center'
                >
                    <Grid item>
                        <FormControlLabel
                            value='Estudiantes'
                            control={<Radio/>}
                            label='Estudiantes'/>
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            value='Docentes'
                            control={<Radio />}
                            label='Docentes'/>
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            value='Empleados'
                            control={<Radio />}
                            label='Empleados'/>
                    </Grid>
                </Grid>
            </RadioGroup>
        </Grid>
    );
};

RadioTipos.propTypes = {
    state: PropTypes.object.isRequired,
    setState: PropTypes.any.isRequired,
    name: PropTypes.string.isRequired,
}

export default RadioTipos;