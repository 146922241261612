import clienteAxios from "../../../config/axios";

export const getRelaysPrivada = async (privada, setRelays) => {
  const resultado = await clienteAxios.get(
    `api/clientes/relaysxPrivada/${privada}`
  );
  setRelays(resultado.data);
};

export const getResidentesPorPrivada = async (
  setResidentesPrivada,
  privada
) => {
  const resultado = await clienteAxios.get(
    `api/clientes/residentesPrivada/${privada}`
  );
  setResidentesPrivada(resultado.data);
};

export const obtenerDatos = async (id, states) => {
  const {
    setPrivada,
    isLoading,
    setIdPrivada,
    setResidente,
    setListaResidentes,
    setListaVisitantes,
    setAlerta,
  } = states;

  const response = await clienteAxios.get(`api/clientes/accesoresidente`);
  const { data } = response;

  setPrivada(data["privada"]);
  setIdPrivada(data["Id"]);

  clienteAxios
    .get(`api/clientes/usuarioacceso/${id}`)
    .then(
      ({ data }) => {
        // console.log(data);
        setResidente(data);
        setListaResidentes(data["UsResidentes"]);
        setListaVisitantes(data["UsVisitas"]);
        setAlerta("");
      })
    .catch(
      (error) => {
        // console.log(error.response.data);
        setAlerta(
          "Su usuario aún no ha sido verificado por el presidente de su privada, vuelva a intentarlo más tarde"
        );
      });

  isLoading(false);
};



export const modificarResidente = async (
  id,
  residente,
  setMensaje,
  listaVisitantes,
  listaResidentes
) => {
  let datos = residente;

  datos = {
    ...datos,
    listaVisitas: listaVisitantes,
    listaResidentes: listaResidentes,
  };

  setMensaje("");

  try {
    clienteAxios.put(`api/clientes/usuarioacceso/modificar/${id}`, datos).then();
    setMensaje({ msg: "Se modificó con éxito", error: false });
  } catch (e) {
    setMensaje({ msg: "Hubo un error al modificar", error: true });
  }
};
