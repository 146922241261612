import React, { Fragment, useEffect, useRef, useState } from "react";
import Paper from "@material-ui/core/Paper";
import { TableContainer, TableRow } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import IconButton from "@material-ui/core/IconButton";
import { verificarResidente } from "../../Controllers/ResidentesController";
import CheckIcon from "@material-ui/icons/Check";
import green from "@material-ui/core/colors/green";
import Tooltip from "@material-ui/core/Tooltip";

const TablaVerificar = ({ setResidentes, setMensaje, infoTabla }) => {
    const Fila = ({ row }) => {
        return (
            <TableRow hover>
                <TableCell scope="center">{row.NumCasa}</TableCell>
                <TableCell align="center">{row.UsNombre}</TableCell>
                <TableCell align="center">{row.UsApPat}</TableCell>
                <TableCell align="center">{row.UsApMat}</TableCell>
                <TableCell align="center">{row.UsCorreo}</TableCell>
                <TableCell align="center">{row.UsTelefono}</TableCell>
                <TableCell align="center">{row.UsPrivada}</TableCell>
                <TableCell align="center">{row.EsRentador}</TableCell>
                <TableCell align="center">
                    {row["UsVerificado"] !== "Sin verificar" ? (
                        <Tooltip title="Residente verificado">
                            <VerifiedUserIcon style={{ color: green[500] }} />
                        </Tooltip>
                    ) : (
                        <Tooltip title="Verificar residente">
                            <IconButton
                                aria-label="Verificar residente"
                                onClick={() =>
                                    verificarResidente(
                                        row["Us_ID"],
                                        setResidentes,
                                        setMensaje
                                    )
                                }
                            >
                                <CheckIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </TableCell>
            </TableRow>
        );
    };

    return (
        <Fragment>
            {infoTabla.length === 0 ? (
                <h3>No hay datos disponibles para mostrar</h3>
            ) : (
                <Paper style={{ width: "100%" }}>
                    <TableContainer style={{ maxHeight: "70vh" }}>
                        <Table stickyHeader aria-label="Verificar residentes">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Num. Casa</TableCell>
                                    <TableCell align="center">Nombre</TableCell>
                                    <TableCell align="center">Apellido paterno</TableCell>
                                    <TableCell align="center">Apellido materno</TableCell>
                                    <TableCell align="center">Correo</TableCell>
                                    <TableCell align="center">Teléfono</TableCell>
                                    <TableCell align="center">Privada</TableCell>
                                    <TableCell align="center">Rentador</TableCell>
                                    <TableCell align="center">Verificado</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {infoTabla.map((row, index) => (
                                    <Fila key={index} row={row} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            )}
        </Fragment>
    );
};

export default TablaVerificar;
