import React,{ useContext,useEffect} from 'react';
import globalContext from "../../Context/GlobalContext/globalContext";
import {useAlert} from "react-alert";
import {MenuSistemasController as Controller} from "../../Controllers/MenuSistemasController";
import ClientesSVG from '../../assets/svg/clientes.svg'
import CredencialesSVG from '../../assets/svg/credenciales.svg'
import UsuariosSVG from '../../assets/svg/usuarios.svg'
import SensorLogo from '../../assets/images/sensor_rojo.png'
import './MenuSistemas.scss'
import { Grid } from '@material-ui/core';
import Carta from './Carta'

const MenuSistemas = (props) => {

    const context = useContext(globalContext);
    const {session,permiso,sistema,checarPermiso,usuario, sistemas} = context;
    const alert = useAlert();
    const {handleClick} = new Controller();

    const leyendaUsuarios = 'El sistema de usuarios permite registrar, modificar y otorgar permisos a los usuarios de los sistemas de Sensor México'
    const leyendaCredenciales = 'El sistema de credenciales es una herramienta para llevar control, administración y registro de la impresión de credenciales'
    const leyendaClientes = 'El sistema de clientes es donde está la administración de los residentes, presidentes y privadas. Con herramientos para dar un mejor serivico a los clientes'

    useEffect(()=>
    {
        if(permiso){
            props.history.push('/'+sistema);return;
        }
        if(session){
            alert.success('Sesión iniciada con éxito!');return;
        }
        props.history.push('/');
    },[session,permiso]);



    return (
        <div className="wrapper-menu-sistemas">
            <img alt="Sensor-logo" src={SensorLogo} className="logo-menu" />

            <Grid
                className="menu-sistemas"
                container
                alignItems="flex-start"
                justify="flex-start"
            >
                <Carta
                    sistema='USUARIOS'
                    svg={UsuariosSVG}
                    texto={leyendaUsuarios}
                    handleClick={() => handleClick(checarPermiso,'usuarios', usuario['Us_ID'])}
                />
                <Carta
                    sistema='CREDENCIALES'
                    svg={CredencialesSVG}
                    texto={leyendaCredenciales}
                    handleClick={() => handleClick(checarPermiso,'credenciales', usuario['Us_ID'])}
                />
                <Carta
                    sistema='CLIENTES'
                    svg={ClientesSVG}
                    texto={leyendaClientes}
                    handleClick={() => handleClick(checarPermiso,'clientes', usuario['Us_ID'])}
                />
            </Grid>
        </div>
    );

};





export default MenuSistemas;