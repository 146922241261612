import React, {Fragment, useEffect, useContext} from "react";
import globalContext from "../../Context/GlobalContext/globalContext";
import {useAlert} from "react-alert";
import SideBar from "./SideBar/SideBar";
import {Switch, Route} from 'react-router-dom';
import UsuariosCreate from "./UsuariosCreate";
import UsuariosPermisos from "./UsuariosPermisos";
import UsuariosVer from "./UsuariosVer";

const Usuarios = (props) => {

    const context = useContext(globalContext);
    const {permiso, usuario, regresarPanel} = context;
    const alert = useAlert();


    useEffect(() => {
        document.body.style.backgroundColor = 'whitesmoke';
        if (permiso) {
            alert.success('Bienvenido ' + usuario.UsNombre + '!');
            return;
        }
        props.history.push('/panel');
    }, [permiso]);

    return (
        <Fragment>
            <SideBar
                regresarPanel={regresarPanel}
            />
            <main style={{marginLeft: '1%', marginTop: '2%'}}>
                <Switch>
                    <Route exact path="/usuarios/alta" component={UsuariosCreate}/>
                    <Route exact path="/usuarios/permisos" component={UsuariosPermisos}/>
                    <Route exact path="/usuarios" component={UsuariosVer}/>
                </Switch>
            </main>
        </Fragment>
    );
};

export default Usuarios;