import React from "react";
import "./LoginButton.scss";

const LoginButton = ({label}) => {

    // var animateButton = (e) => {

        // e.preventDefault();
        //reset animation
      //   e.persist();
      //   e.target.classList.remove('animate');
      //   e.target.classList.add('animate');
        
      //   setTimeout(function(){
      //     e.target.classList.remove('animate');
      //   },700);
      // };
      
      // var bubblyButtons = document.getElementsByClassName("bubbly-button");
      
      // for (var i = 0; i < bubblyButtons.length; i++) {
      //   bubblyButtons[i].addEventListener('click', animateButton, false);
      // }

 

    return (
        <button  className="bubbly-button" type="submit">
            {label}
        </button>
    );
};

export default LoginButton;
