import { createMuiTheme } from "@material-ui/core";

export const Theme = {
    primary: '#c42e2e',
    secondary: '#DDDDDD',
    ligth: '#EEEEEE'
}

export const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#C42E2E",
            light: "#CE6D6D",
            dark: "#912121",
            contrastText: "#FFF",
        },
    },
});

export default Theme